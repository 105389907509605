import React, { useCallback, useEffect, useRef, useState } from "react"
import { RowModule } from "../../../../../../../component/atoms/RowModule/RowModule.jsx"
import {
  Card,
  CardBody,
  CardHeader
} from "../../../../../../../_metronic/_partials/controls/index.js"
import TableView, {
  formatterRowComponent,
  initialFilterTable
} from "../../../../../../../component/atoms/Tabel/index.jsx"
import { sortCaret, toAbsoluteUrl } from "../../../../../../../_metronic/_helpers/index.js"
import { Button } from "react-bootstrap"
import { loadUserLogin } from "../../../../../../service/userManagementAction.js"
import { useDispatch, useSelector } from "react-redux"
import { useSubheader } from "../../../../../../../_metronic/layout/index.js"
import {
  EmptyCard,
  FilterCard,
  SelectInput,
  TableSkeleton
} from "../../../../../../../component/index.jsx"
import {
  getCategoryItemList,
  getPlantForm,
  getPlantType,
  syncErpPesticide
} from "../../../../../../../redux/actions/MasterDataAction.jsx"
import { MASTER_DATA } from "../../../../../../../constants/InitTypeConstants.js"
import { Popup } from "../../../../../../../component/atoms/Popup/index.jsx"
import SVG from "react-inlinesvg"
import { ILUSTRATION_CONSTANS } from "../../../../../../../constants/ImageConstants.js"
import { useTranslation } from "react-i18next"
import { PRIVILEGE_ENUM } from "../../../../../../../constants/PrivilegeConstants.js"
import { RoutesConstants } from "../../../../../../../constants/RoutesConstants.js"

export const DetailPesticide = ({
  match: {
    params: { subType }
  }
}) => {
  const { t } = useTranslation()
  const privileges = loadUserLogin().privilege
  const subHeader = useSubheader()
  const { General, MasterData } = useSelector((state) => state)
  const dispatch = useDispatch()
  const [search, setSearch] = useState({
    string_filter: ""
  })

  const [initialValues, setInitialValues] = useState({
    item_shape: "",
    plant_type_id: ""
  })

  const formRef = useRef()
  const [statusValue] = useState([])
  const [showFilter, setShowFilter] = useState(false)
  const isReadOnly = false
  const [type, setType] = useState("")
  const [stateType, setStateType] = useState("")
  const [stateShape, setStateShape] = useState("")
  const [filterOn, setFilterOn] = useState(false)
  const [shape, setShape] = useState("")
  const [showModalSuccess, setShowModalSuccess] = useState(false)

  const changePage = useCallback(
    (param) => {
      let newParam

      if (param.sortField) {
        newParam = {
          ...initialValues,
          ...search,
          ...param,
          sort: param.sortField + "," + param.sortOrder
        }
      } else {
        newParam = {
          ...initialValues,
          ...search,
          ...param
        }
      }

      dispatch(getCategoryItemList(subType.toUpperCase(), { pageReq: newParam }))
    },
    [dispatch, search, statusValue, initialValues]
  )

  useEffect(() => {
    if (General.isFinishUpdateOrDelete) {
      if (General.actionInitType === MASTER_DATA.SYNC_ERP_PESTICIDE) {
        setShowModalSuccess(true)
      }
    }
  }, [General])

  useEffect(() => {
    dispatch(
      getCategoryItemList(subType.toUpperCase(), {
        pageReq: initialFilterTable
      })
    )
    dispatch(getPlantForm())
    dispatch(getPlantType())
  }, [])

  useEffect(() => {
    if (MasterData.plantType) {
      setStateType(
        MasterData.plantType.map((value) => ({
          label: value.name,
          value: value.id
        }))
      )
    }
  }, [MasterData.plantType])

  useEffect(() => {
    if (MasterData.plantForm) {
      setStateShape(
        MasterData.plantForm.map((value) => ({
          label: value.enum_value,
          value: value.enum_name
        }))
      )
    }
  }, [MasterData.plantForm])

  useEffect(() => {
    subHeader.setBreadcrumbs([
      {
        pathname: RoutesConstants.MASTER_DATA.CATEGORY_ITEM.PAGE(),
        title: t("sub_header.category_item")
      },
      {
        title: t("sub_header.pesticide")
      }
    ])
    if (privileges.indexOf(PRIVILEGE_ENUM.MODIFIED_CATEGORY_ITEM) > -1) {
      subHeader.setButton(
        <Button variant="primary" onClick={onClickSyncErp}>
          Sync with ERP
        </Button>
      )
    } else {
      subHeader.setButton(null)
    }
    subHeader.setTitle(t("sub_header.pesticide"))
  }, [])

  function onClickSyncErp() {
    dispatch(syncErpPesticide())
  }

  const uiDate = (e) => {
    return (
      <>
        <div className="mb-1">{e?.submitted_date ?? "-"}</div>
        <div>{e?.submitted_time ?? "-"}</div>
      </>
    )
  }
  const uiName = (e) => {
    return <>{e.name ?? "-"}</>
  }
  const uiUnit = (e) => {
    return <>{e.unit_name ?? "-"}</>
  }
  const uiShape = (e) => {
    return <>{e.shape_name ?? "-"}</>
  }
  const uiType = (e) => {
    return <>{e.plant_type_name ?? "-"}</>
  }

  const columns = [
    {
      dataField: "name",
      text: t("table_header.variant_name").toUpperCase(),
      formatter: formatterRowComponent,
      sortCaret: sortCaret,
      formatExtraData: {
        externalStyle: uiName
      }
    },
    {
      dataField: "plantType",
      text: t("table_header.plant_type").toUpperCase(),
      formatter: formatterRowComponent,
      formatExtraData: {
        externalStyle: uiType
      },
      sortCaret: sortCaret,
      sort: true,
      onSort: (field, order) => {
        let param = {
          ...initialFilterTable,
          sort: field + "," + order
        }
        dispatch(getCategoryItemList(subType.toUpperCase(), { pageReq: param }))
      }
    },
    {
      dataField: "shape_name",
      text: t("table_header.pesticide_form").toUpperCase(),
      formatter: formatterRowComponent,
      formatExtraData: {
        externalStyle: uiShape
      }
    },
    {
      dataField: "unit_name",
      text: "UNIT",
      formatter: formatterRowComponent,
      formatExtraData: {
        externalStyle: uiUnit
      }
    },
    {
      dataField: "createdDate",
      text: t("table_header.submitted_date"),
      formatter: formatterRowComponent,
      formatExtraData: {
        externalStyle: uiDate
      },
      sortCaret: sortCaret,
      sort: true,
      onSort: (field, order) => {
        let param = {
          ...initialFilterTable,
          sort: field + "," + order
        }
        dispatch(getCategoryItemList(subType.toUpperCase(), { pageReq: param }))
      },
      headerStyle: () => {
        return { width: "15%" }
      }
    }
  ]

  const onSubmitForm = (values) => {
    let param = {
      ...initialFilterTable,
      string_filter: values.string_filter,
      item_shape: shape,
      plant_type_id: type
    }

    setSearch({
      string_filter: values.string_filter
    })

    dispatch(getCategoryItemList(subType.toUpperCase(), { pageReq: param }))
  }

  const trigerReset = () => {
    setSearch({
      string_filter: ""
    })
    let param = {
      ...initialFilterTable,
      string_filter: "",
      item_shape: shape,
      plant_type_id: type
    }
    dispatch(getCategoryItemList(subType.toUpperCase(), { pageReq: param }))
  }

  const onChangePlantType = (e) => {
    setType(e)
  }
  const onChangePlantShape = (e) => {
    setShape(e)
  }

  const filterHandleSubmit = (e) => {
    const { values, submited } = e

    if (submited === true) {
      let param = {
        ...initialFilterTable,
        string_filter: search.string_filter,
        item_shape: values.item_shape,
        plant_type_id: values.plant_type_id
      }

      if (param) {
        setInitialValues(param)
        dispatch(getCategoryItemList(subType.toUpperCase(), { pageReq: param }))
        setShowFilter(false)
        setFilterOn(true)
      }
    }
  }

  const filterOnReset = () => {
    setType("")
    setShape("")
    setShowFilter(false)
    setFilterOn(false)
    setInitialValues({
      item_shape: "",
      plant_type_id: ""
    })
    let param = {
      ...initialFilterTable,
      string_filter: search.string_filter,
      item_shape: "",
      plant_type_id: ""
    }

    dispatch(getCategoryItemList(subType.toUpperCase(), { pageReq: param }))
  }

  const handleOpenCloseFilter = (value) => {
    setShowFilter(value)
  }

  const filterComponent = (
    <>
      <Card className="card-custom card-shadowless">
        <CardHeader title="Mitra" className="px-0" />
        <CardBody className="px-0">
          <RowModule>
            <SelectInput
              name="plant_type_id"
              onChange={onChangePlantType}
              editable={!isReadOnly}
              label={t("label.plant_type")}
              placeholder={t("placeholder.select_plant_type")}
              options={stateType}
              withoutFeedback={true}
              clearNow={true}
              value={type}
            />
          </RowModule>
        </CardBody>
      </Card>
      <Card className="card-custom card-shadowless">
        <CardHeader title="Mitra" className="px-0" />
        <CardBody className="px-0">
          <RowModule>
            <SelectInput
              name="item_shape"
              onChange={onChangePlantShape}
              editable={!isReadOnly}
              label={t("label.pesticide_form")}
              placeholder={t("placeholder.select_pesticide_form")}
              options={stateShape}
              withoutFeedback={true}
              clearNow={true}
              value={shape}
            />
          </RowModule>
        </CardBody>
      </Card>
    </>
  )

  return (
    <>
      <Popup
        show={showModalSuccess}
        centered={true}
        body={
          <div className={"d-flex flex-column align-items-center"}>
            <SVG src={toAbsoluteUrl(ILUSTRATION_CONSTANS.SUCCESS)} />
            <span className={"mt-4"}>{t("messages.response.sync_pesticide_successfully")}</span>
          </div>
        }
        onClick={() => setShowModalSuccess(false)}
      />
      <FilterCard
        placeholder={t("placeholder.insert_variant_name")}
        onSubmitForm={onSubmitForm}
        initialValues={search}
        name={"string_filter"}
        innerRef={formRef}
        trigerReset={trigerReset}
        filterInnerRef={formRef}
        filterHandleSubmit={filterHandleSubmit}
        filterFormInitialValues={initialValues}
        filterComponent={filterComponent}
        filterOnReset={filterOnReset}
        showFilter={showFilter}
        handleOpenCloseFilter={handleOpenCloseFilter}
        filterOn={filterOn}
      />

      <Card>
        <RowModule>
          <Card className="card-shadowless">
            <CardBody>
              {General.loading ? (
                <TableSkeleton column={6} row={10} />
              ) : MasterData?.pageData?.length === 0 ? (
                <EmptyCard
                  body={
                    search?.string_filter?.length > 0
                      ? t("messages.response.data_not_found")
                      : t("messages.response.empty_state")
                  }
                />
              ) : (
                ""
              )}
              <TableView
                callbackAfterPageChange={changePage}
                columnProperties={columns}
                dataTable={MasterData.pageData}
                currentPage={MasterData.page.currentPage}
                currentElement={MasterData.page.currentElement}
                totalCount={MasterData.page.totalCount}
                loadingGetData={General.loading}
                rowClasses={() => {
                  return "cursor-default"
                }}
              />
            </CardBody>
          </Card>
        </RowModule>
      </Card>
    </>
  )
}