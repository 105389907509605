import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import {
  resetUrlResponse,
  uploadAction
} from "../../../../../../redux/actions/MasterDataAction.jsx"
import {
  Card,
  CardBody,
  CardHeaderTitle
} from "../../../../../../_metronic/_partials/controls/index.js"
import { RowModule } from "../../../../../../component/atoms/RowModule/RowModule.jsx"
import { TextInput, UploadComponent } from "../../../../../../component/index.jsx"
import { UploadIcon } from "../../../../../../component/atoms/Icons/SVG/upload.jsx"
import { useTranslation } from "react-i18next"
import { FARMER_DOCUMENT } from "../../../../../../constants/StringConstant.js"

export const KtpSwafotoFarmer = ({ isReadOnly }) => {
  const { MasterData } = useSelector((state) => state)
  const dispatch = useDispatch()
  const {t} = useTranslation()

  function onChangeFileKtp(e) {
    dispatch(uploadAction({ data: e, type: FARMER_DOCUMENT.KTP }))
  }

  function onChangeFileSwafoto(e) {
    dispatch(uploadAction({ data: e, type: FARMER_DOCUMENT.SELFIE }))
  }

  const [urlKtp, setUrlKtp] = useState("")
  const [swafoto, setSwafoto] = useState("")

  useEffect(() => {
    if (MasterData.urlResponseUpload) {
      if (MasterData.uploadType === FARMER_DOCUMENT.KTP) {
        setUrlKtp(MasterData.urlResponseUpload)
      } else if (MasterData.uploadType === FARMER_DOCUMENT.SELFIE) {
        setSwafoto(MasterData.urlResponseUpload)
      }
    }
    return () => {
      dispatch(resetUrlResponse())
    }
  }, [MasterData.urlResponseUpload, MasterData.uploadType])

  const placeholder = <UploadIcon />

  return (
    <>
      <Card className="card-custom card-shadowless">
        <CardBody>
          <RowModule>
            <CardHeaderTitle className={"my-12"}>
              {t("card_title.ktp_selfie_farmer").toUpperCase()}
            </CardHeaderTitle>
          </RowModule>

          <RowModule>
            <TextInput
              editable={!isReadOnly}
              label={t("label.ktp_number")}
              required
              type="number"
              name="nomorKtp"
              placeholder={t("placeholder.input_ktp_number")}
            />
          </RowModule>
          <RowModule>
            <div className={"w-100 position-relative"}>
              <UploadComponent
                required
                urlParsing={urlKtp}
                name="fotoKtp"
                label={t("label.ktp_picture")}
                editable={!isReadOnly}
                onChange={onChangeFileKtp}
                placeholder={placeholder}
              />
            </div>
          </RowModule>
          <RowModule>
            <div className={"w-100 position-relative"}>
              <UploadComponent
                urlParsing={swafoto}
                required
                name="swafoto"
                label={t("label.selfie_with_ktp")}
                editable={!isReadOnly}
                onChange={onChangeFileSwafoto}
                placeholder={placeholder}
              />
            </div>
          </RowModule>
        </CardBody>
      </Card>
    </>
  )
}