import { CreateEditFarmer } from "../app/modules/farmer/form/create-edit/CreateEditFarmer.jsx"
import { FarmerDetail } from "../app/modules/farmer/detail/FarmerDetail.jsx"
import { CreateEditMitra } from "../app/modules/mitra/form/CreateEditMitra.jsx"
import { DetailMitra } from "../app/modules/mitra/detail/Detail.jsx"
import { FieldOfficerFormWrapper } from "../app/modules/field-officer/form/Form.jsx"
import { ListFieldOfficer } from "../app/modules/field-officer/fo/ListFieldOfficer.jsx"
import { DetailWrapper } from "../app/modules/field-officer/detail/Detail.jsx"
import { AssignMitraWrapper } from "../app/modules/field-officer/assign-mitra/AssignMitra.jsx"
import { AreaMap } from "../app/modules/area/land-location-map/AreaMap.jsx"
import { ListAreaDetail } from "../app/modules/area/detail/AreaDetail.jsx"
import { ListAreaLead } from "../app/modules/area/lead-land/AreaListLead.jsx"
import { AreaHistoryActivity } from "../app/modules/area/detail/detail-history-activity/AreaHistoryActivity.jsx"
import { SeedlingDetail } from "../app/modules/area/detail/detail-activity/SeedlingDetail.jsx"
import { TillageDetail } from "../app/modules/area/detail/detail-activity/TillageDetail.jsx"
import { PlantingDetail } from "../app/modules/area/detail/detail-activity/PlantingDetail.jsx"
import { RoleList } from "../app/modules/setting/role/list/RoleList.jsx"
import { EditRole } from "../app/modules/setting/role/form/EditRole.jsx"
import { ListAccount } from "../app/modules/setting/account/list/AccountList.jsx"
import { ListAreaPending } from "../app/modules/area/pending/AreaPending.jsx"
import { ListAreaReject } from "../app/modules/area/reject/AreaListReject.jsx"
import { ListAreaDetailLead } from "../app/modules/area/lead-land/detail/AreaListLeadDetail.jsx"
import { ListVideo } from "../app/modules/setting/video/list/VideoList.jsx"
import { CreateEditVideo } from "../app/modules/setting/video/form/CreateEditVideo.jsx"
import { ListVideoDetail } from "../app/modules/setting/video/detail/Detail.jsx"
import { ListAreaUnassigned } from "../app/modules/area/unassign/AreaListUnassigned.jsx"
import { ListAreaDetailUnassign } from "../app/modules/area/unassign/detail/AreaListUnassignedDetail.jsx"
import { CreateAccount } from "../app/modules/setting/account/form/create-user/AccountNew.jsx"
import { AccountDetail } from "../app/modules/setting/account/detail/AccountDetail.jsx"
import { FertilizationDetail } from "../app/modules/area/detail/detail-activity/FertilizationDetail"
import { SprayingDetail } from "../app/modules/area/detail/detail-activity/SprayingDetail"
import { HarvestEstimationDetail } from "../app/modules/area/detail/detail-activity/HarvestEstimationDetail"
import { HarvestDetail } from "../app/modules/area/detail/detail-activity/HarvestDetail"
import { CategoryItemList } from "../app/modules/master-data/categori-item/list/CategoryItemList.jsx"
import { DetailSeed } from "../app/modules/master-data/categori-item/detail/seed/list/DetailSeed.jsx"
import { DetailMachine } from "../app/modules/master-data/categori-item/detail/machine/list/DetailMachine.jsx"
import { DetailPesticide } from "../app/modules/master-data/categori-item/detail/pesticide/list/DetailPesticide.jsx"
import { CreateSeed } from "../app/modules/master-data/categori-item/detail/seed/form/CreateSeed.jsx"
import { CreatePesticide } from "../app/modules/master-data/categori-item/detail/pesticide/form/CreatePesticide.jsx"
import { ManageReasonList } from "../app/modules/master-data/manage-reason/list/ManageReasonList.jsx"
import { ManageReasonDetail } from "../app/modules/master-data/manage-reason/detail/ManageReasonDetail.jsx"
import { PackageList } from "../app/modules/master-data/package/list/PackageList.jsx"
import { PackageDetail } from "../app/modules/master-data/package/detail/PackageDetail.jsx"
import { CreatePackage } from "../app/modules/master-data/package/form/create-package/CreatePackage.jsx"
import { DetailFertilizer } from "../app/modules/master-data/categori-item/detail/fertilizer/list/DetailFertilizer.jsx"
import { CreatePupuk } from "../app/modules/master-data/categori-item/detail/fertilizer/form/CreatePupuk.jsx"
import BroadcastList from "../app/modules/setting/broadcast-message/list/BroadcastList.jsx"
import BroadcastCreate from "../app/modules/setting/broadcast-message/form/BroadcastCreate.jsx"
import BroadcastDetail from "../app/modules/setting/broadcast-message/detail/BroadcastDetail.jsx"
import { FoAgro } from "../app/modules/field-officer/fo-agronomist/FoAgro"
import { CreateEditTeamDrone } from "../app/modules/field-officer/fo-agronomist/TabsFoAgro/CreateEditTeamDrone"
import { DetailDroneTeam } from "../app/modules/field-officer/fo-agronomist/Detail/DetailDroneTeam"
import { ICON_CONSTANS } from "../constants/ImageConstants"
import { ListFoHarvest } from "../app/modules/field-officer/fo-harvest/ListFoHarvest"
import { RoutesConstants } from "../constants/RoutesConstants"
import { ListFoCoordinator } from "../app/modules/field-officer/fo-coordinator/ListFoCoordinator"
import { FIELD_OFFICER_ROLE_ENUM, ROUTING_STATUS } from "../constants/StringConstant"
import { AreaListTeregistrasi } from "../app/modules/area/teregistrasi/AreaListTeregistrasi"
import { HarvestFailureDetail } from "../app/modules/area/detail/detail-activity/HarvestFailureDetail.jsx"
import { CreateFailedHarvest } from "../app/modules/area/form-failed-harvest/CreateFailedHarvest"
import { ListAreaActivityKur } from "../app/modules/area/activity-kur-report/ListAreaActivityKur"
import { PreviewBulkErp } from "../app/modules/area/activity-report/tab/form-bulk-erp/PreviewBulkErp"
import { ListProfile } from "../app/modules/mac/profile/list/ListProfile.jsx"
import { ProfileDetail } from "../app/modules/mac/profile/detail/ProfileDetail.jsx"
import { ListUser } from "../app/modules/mac/user/list/ListUser.jsx"
import { UserDetail } from "../app/modules/mac/user/detail/UserDetail.jsx"
import { MacActivityReportList } from "../app/modules/mac/activity-report/list/MacActivityReportList.jsx"
import { MacSprayingPage } from "../app/modules/mac/spraying/list/MacSprayingPage.jsx"
import { MacSprayingDetail } from "../app/modules/mac/spraying/detail/MacSprayingDetail.jsx"
import { DetailActivityReportMac } from "../app/modules/mac/activity-report/detail/DetailActivityReportMac.jsx"
import { DetailPlantType } from "../app/modules/master-data/categori-item/detail/plant-type/list/DetailPlantType.jsx"
import { MacSprayingApproval } from "../app/modules/mac/spraying/form/spraying-approval/MacSprayingApproval.jsx"
import { ListPascaTanam } from "../app/modules/scheduling/after-planting/list/ListPascaTanam.jsx"
import { MasterDataRegionalPage } from "../app/modules/master-data/regional/list/MasterDataRegionalPage.jsx"
import { PrePlantingSprayingPage } from "../app/modules/scheduling/pre-planting/list/PrePlantingSprayingPage.jsx"
import { DetailPascaTanam } from "../app/modules/scheduling/after-planting/detail/DetailPascaTanam.jsx"
import { ApprovalPascaTanam } from "../app/modules/scheduling/after-planting/form/approval/ApprovalPascaTanam.jsx"
import { ApprovalBulkPascaTanam } from "../app/modules/scheduling/after-planting/form/approval-bulk/ApprovalBulkPascaTanam.jsx"
import { DetailPrePlantingPage } from "../app/modules/scheduling/pre-planting/detail/DetailPrePlantingPage.jsx"
import { ApprovalPrePlatingPage } from "../app/modules/scheduling/pre-planting/form/approval/ApprovalPrePlatingPage.jsx"
import { PrePlantingBulkApprove } from "../app/modules/scheduling/pre-planting/form/approval-bulk/PrePlantingBulkApprove.jsx"
import { ListMitra } from "../app/modules/mitra/list/ListMitra.jsx"
import { ListFarmer } from "../app/modules/farmer/list/ListFarmer.jsx"
import { ListMitraKur } from "../app/modules/mitra/list-ca/ListMitraKur.jsx"
import { ListFarmerKur } from "../app/modules/farmer/list-kur/ListFarmerKur.jsx"
import { DetailRegionalPage } from "../app/modules/master-data/regional/detail/DetailRegionalPage.jsx"
import { CreateRegionalPage } from "../app/modules/master-data/regional/form/create-regional/CreateRegionalPage.jsx"
import { CreateSubRegionalPage } from "../app/modules/master-data/regional/form/create-sub-regional/CreateSubRegionalPage.jsx"
import { CreateUpdateTreatment } from "../app/modules/master-data/package/form/create-treatment/CreateUpdateTreatment.jsx"
import { ListFoQaPage } from "../app/modules/field-officer/fo-qa/ListFoQaPage.jsx"
import { CreateSubmissionPage } from "../app/modules/submission/certification-block/create-submission/CreateSubmissionPage.jsx"
import { ListCertificationBlock } from "../app/modules/submission/certification-block/list/ListCertificationBlock.jsx"
import { DetailCertificationPending } from "../app/modules/submission/certification-block/detail/DetailCertificationPending.jsx"
import { DetailCertificationBlock } from "../app/modules/submission/certification-block/detail/DetailCertificationBlock.jsx"
import {
  PRIVILEGE_ENUM,
  PRIVILEGE_PARENT_ENUM,
  SUB_MENU_ENUM
} from "../constants/PrivilegeConstants.js"
import { ListActivityReportV2 } from "../app/modules/area/activity-report/v2-activity-report/ListActivityReportV2.jsx"
import { ListReadyPlantingSeasonConfirmation } from "../app/modules/area/ready-planting-season-confirmation/ListReadyPlantingSeasonConfirmation.jsx"
import { SeedingBaPage } from "../app/modules/report/seeding-ba/SeedingBaPage.jsx"
import { CreateReportBaPage } from "../app/modules/report/ba/create-ba/CreateReportBaPage.jsx"
import { LandClearingDetail } from "../app/modules/area/detail/detail-activity/LandClearingDetail.jsx"

export const PrivilegeCollection = [
  //    mitra
  {
    enum: [
      PRIVILEGE_ENUM.DETAIL_MITRA_REGISTERED,
      PRIVILEGE_ENUM.DETAIL_MITRA_PENDING,
      PRIVILEGE_ENUM.DETAIL_MITRA_REJECTED
    ],
    parent: PRIVILEGE_PARENT_ENUM.MITRA,
    url: RoutesConstants.MITRA.DETAIL_MITRA(":status", ":id"),
    component: DetailMitra
  },
  {
    enum: [PRIVILEGE_ENUM.UPDATE_MITRA],
    parent: PRIVILEGE_PARENT_ENUM.MITRA,
    url: "/mitras/action/:status/edit/:id",
    component: CreateEditMitra
  },
  {
    enum: [PRIVILEGE_ENUM.LIST_MITRA_REGISTERED_TAB_ALL],
    parent: PRIVILEGE_PARENT_ENUM.MITRA,
    url: RoutesConstants.MITRA.LIST_MITRA(":tab"),
    component: ListMitra,
    iconMenu: ICON_CONSTANS.IC_MITRA_SMALL,
    navigateLink: RoutesConstants.MITRA.LIST_MITRA(ROUTING_STATUS.STATUS_ENUM.PENDING),
    indexMenu: 0,
    menuTitle: "sub_header.mitra_list"
  },
  {
    enum: [PRIVILEGE_ENUM.LIST_MITRA_REGISTERED_TAB_ALL],
    parent: PRIVILEGE_PARENT_ENUM.MITRA,
    url: RoutesConstants.MITRA.LIST_MITRA_KUR(),
    component: ListMitraKur,
    iconMenu: ICON_CONSTANS.IC_MITRA_SMALL,
    navigateLink: RoutesConstants.MITRA.LIST_MITRA_KUR(),
    indexMenu: 0,
    menuTitle: "label.list_mitra_ca"
  },
  //    FO
  {
    enum: [PRIVILEGE_ENUM.LIST_FIELD_OFFICER_AGRONOMIST_TAB_TEAM_DRONE],
    parent: PRIVILEGE_PARENT_ENUM.FO,
    url: RoutesConstants.FIELD_OFFICER.DETAIL_FO_AGRONOMIST_TEAM(":id"),
    component: DetailDroneTeam
  },
  {
    enum: [PRIVILEGE_ENUM.EDIT_FIELD_OFFICER],
    parent: PRIVILEGE_PARENT_ENUM.FO,
    url: RoutesConstants.FIELD_OFFICER.EDIT_FO(":id"),
    component: FieldOfficerFormWrapper
  },
  {
    enum: [PRIVILEGE_ENUM.EDIT_AGRONOMIST_TEAM],
    parent: PRIVILEGE_PARENT_ENUM.FO,
    url: RoutesConstants.FIELD_OFFICER.EDIT_TEAM_DRONE(":action", ":id"),
    component: CreateEditTeamDrone
  },
  {
    enum: [PRIVILEGE_ENUM.CREATE_AGRONOMIST_TEAM],
    parent: PRIVILEGE_PARENT_ENUM.FO,
    url: RoutesConstants.FIELD_OFFICER.CREATE_TEAM(":action"),
    component: CreateEditTeamDrone
  },
  {
    enum: [PRIVILEGE_ENUM.DETAIL_FIELD_OFFICER_COORDINATOR],
    parent: PRIVILEGE_PARENT_ENUM.FO,
    url: RoutesConstants.FIELD_OFFICER.DETAIL_FO(":role", ":id"),
    component: DetailWrapper
  },
  {
    enum: [PRIVILEGE_ENUM.LIST_FIELD_OFFICER_COORDINATOR],
    parent: PRIVILEGE_PARENT_ENUM.FO,
    url: RoutesConstants.FIELD_OFFICER.LIST_FO(FIELD_OFFICER_ROLE_ENUM.FIELD_OFFICER_COORDINATOR),
    component: ListFoCoordinator,
    iconMenu: ICON_CONSTANS.IC_FO,
    navigateLink: RoutesConstants.FIELD_OFFICER.LIST_FO(
      FIELD_OFFICER_ROLE_ENUM.FIELD_OFFICER_COORDINATOR
    ),
    indexMenu: 0,
    menuTitle: "FO Coordinator"
  },
  {
    enum: [PRIVILEGE_ENUM.DETAIL_FIELD_OFFICER_AGRONOMIST],
    parent: PRIVILEGE_PARENT_ENUM.FO,
    url: RoutesConstants.FIELD_OFFICER.DETAIL_FO_AGRONOMIS(":role", ":id"),
    component: DetailWrapper
  },
  {
    enum: [PRIVILEGE_ENUM.LIST_FIELD_OFFICER_AGRONOMIST],
    parent: PRIVILEGE_PARENT_ENUM.FO,
    url: RoutesConstants.FIELD_OFFICER.LIST_FO_AGRONOMIS(":tab"),
    component: FoAgro,
    iconMenu: ICON_CONSTANS.SIDE_MENU.FO,
    navigateLink: RoutesConstants.FIELD_OFFICER.LIST_FO_AGRONOMIS("list-fo"),
    indexMenu: 1,
    menuTitle: "FO Agronomist"
  },
  {
    enum: [PRIVILEGE_ENUM.DETAIL_FIELD_OFFICER],
    parent: PRIVILEGE_PARENT_ENUM.FO,
    url: RoutesConstants.FIELD_OFFICER.DETAIL_FO(":role", ":id"),
    component: DetailWrapper
  },
  {
    enum: [PRIVILEGE_ENUM.DETAIL_FIELD_OFFICER_HARVEST],
    parent: PRIVILEGE_PARENT_ENUM.FO,
    url: RoutesConstants.FIELD_OFFICER.DETAIL_FO(":role", ":id"),
    component: DetailWrapper
  },
  {
    enum: [PRIVILEGE_ENUM.LIST_FIELD_OFFICER_HARVEST],
    parent: PRIVILEGE_PARENT_ENUM.FO,
    url: RoutesConstants.FIELD_OFFICER.LIST_FO(FIELD_OFFICER_ROLE_ENUM.FIELD_OFFICER_HARVEST),
    component: ListFoHarvest,
    iconMenu: ICON_CONSTANS.SIDE_MENU.FO,
    navigateLink: RoutesConstants.FIELD_OFFICER.LIST_FO(
      FIELD_OFFICER_ROLE_ENUM.FIELD_OFFICER_HARVEST
    ),
    indexMenu: 2,
    menuTitle: "sub_header.fo_harvest"
  },
  {
    enum: [PRIVILEGE_ENUM.EDIT_FIELD_OFFICER],
    parent: PRIVILEGE_PARENT_ENUM.FO,
    url: RoutesConstants.FIELD_OFFICER.EDIT_FO_QA(
      FIELD_OFFICER_ROLE_ENUM.FIELD_OFFICER_QA,
      ":tab",
      ":id"
    ),
    component: FieldOfficerFormWrapper
  },
  {
    enum: [PRIVILEGE_ENUM.DETAIL_FIELD_OFFICER_QUALITY_ASSURANCE],
    parent: PRIVILEGE_PARENT_ENUM.FO,
    url: RoutesConstants.FIELD_OFFICER.DETAIL_FO_QA(":role", ":tab", ":id"),
    component: DetailWrapper
  },
  {
    enum: [PRIVILEGE_ENUM.LIST_FIELD_OFFICER_QUALITY_ASSURANCE],
    parent: PRIVILEGE_PARENT_ENUM.FO,
    url: RoutesConstants.FIELD_OFFICER.LIST_FO_QA(":tab"),
    component: ListFoQaPage,
    iconMenu: ICON_CONSTANS.SIDE_MENU.FO,
    navigateLink: RoutesConstants.FIELD_OFFICER.LIST_FO_QA(ROUTING_STATUS.STATUS_STRING.ACTIVE),
    indexMenu: 3,
    menuTitle: "sub_header.fo_qa"
  },
  {
    enum: [PRIVILEGE_ENUM.LIST_FIELD_OFFICER],
    parent: PRIVILEGE_PARENT_ENUM.FO,
    url: RoutesConstants.FIELD_OFFICER.LIST_FO(FIELD_OFFICER_ROLE_ENUM.FIELD_OFFICER),
    component: ListFieldOfficer,
    iconMenu: ICON_CONSTANS.SIDE_MENU.FO,
    navigateLink: RoutesConstants.FIELD_OFFICER.LIST_FO(FIELD_OFFICER_ROLE_ENUM.FIELD_OFFICER),
    indexMenu: 4,
    menuTitle: "sub_header.fo"
  },
  {
    enum: [PRIVILEGE_ENUM.ASSIGN_FIELD_OFFICER_TO_MITRA],
    parent: PRIVILEGE_PARENT_ENUM.FO,
    url: RoutesConstants.FIELD_OFFICER.ASSIGN_MITRA(),
    component: AssignMitraWrapper,
    iconMenu: ICON_CONSTANS.SIDE_MENU.MITRA,
    navigateLink: RoutesConstants.FIELD_OFFICER.ASSIGN_MITRA(),
    indexMenu: 5,
    menuTitle: "Assign mitra"
  },

  {
    enum: [PRIVILEGE_ENUM.AREA_DETAIL_REGISTERED],
    parent: PRIVILEGE_PARENT_ENUM.AREA,
    url: RoutesConstants.AREA.DETAIL_AREA_PENDING_UNASSIGN(":status", ":id"),
    component: ListAreaDetailUnassign
  },
  {
    enum: [PRIVILEGE_ENUM.DETAIL_PLANTING_SEASON],
    parent: PRIVILEGE_PARENT_ENUM.AREA,
    url: RoutesConstants.AREA.DETAIL_HISTORY_ACTIVITY(":status", ":area_id", ":id"),
    component: AreaHistoryActivity
  },
  {
    enum: [PRIVILEGE_ENUM.DETAIL_ACTIVITY_SEEDLING],
    parent: PRIVILEGE_PARENT_ENUM.AREA,
    url: RoutesConstants.AREA.DETAIL_ACTIVITY_SEEDLING(":status", ":id", ":activity_id"),
    component: SeedlingDetail
  },
  {
    enum: [PRIVILEGE_ENUM.DETAIL_ACTIVITY_TILLAGE],
    parent: PRIVILEGE_PARENT_ENUM.AREA,
    url: RoutesConstants.AREA.DETAIL_ACTIVITY_TILLAGE(":status", ":id", ":activity_id"),
    component: TillageDetail
  },
  {
    enum: [PRIVILEGE_ENUM.DETAIL_ACTIVITY_PLANTING],
    parent: PRIVILEGE_PARENT_ENUM.AREA,
    url: RoutesConstants.AREA.DETAIL_ACTIVITY_PLANTING(":status", ":id", ":activity_id"),
    component: PlantingDetail
  },
  {
    enum: [PRIVILEGE_ENUM.DETAIL_AREA_LEAD],
    parent: PRIVILEGE_PARENT_ENUM.AREA,
    url: RoutesConstants.AREA.DETAIL_AREA_LEAD(":id"),
    component: ListAreaDetailLead
  },
  {
    enum: [PRIVILEGE_ENUM.AREA_DETAIL_REGISTERED],
    parent: PRIVILEGE_PARENT_ENUM.AREA,
    url: RoutesConstants.AREA.DETAIL_AREA(":status", ":id"),
    component: ListAreaDetail
  },
  {
    enum: [PRIVILEGE_ENUM.DETAIL_AREA_UNASSIGNED],
    parent: PRIVILEGE_PARENT_ENUM.AREA,
    url: "/lahan/list-unassign/:status/:id",
    component: ListAreaDetailUnassign
  },

  {
    enum: [PRIVILEGE_ENUM.DETAIL_ACTIVITY_FERTILIZATION],
    parent: PRIVILEGE_PARENT_ENUM.AREA,
    url: "/lahan/:status/:id/fertilization/:activity_id",
    component: FertilizationDetail
  },
  {
    enum: [PRIVILEGE_ENUM.DETAIL_ACTIVITY_SPRAYING],
    parent: PRIVILEGE_PARENT_ENUM.AREA,
    url: "/lahan/:status/:id/spraying/:activity_id",
    component: SprayingDetail
  },
  {
    enum: [PRIVILEGE_ENUM.DETAIL_ACTIVITY_SPRAYING],
    parent: PRIVILEGE_PARENT_ENUM.AREA,
    url: RoutesConstants.AREA.DETAIL_ACTIVITY_LAND_CLEARING(":status", ":id", ":activityId"),
    component: LandClearingDetail
  },
  {
    enum: [PRIVILEGE_ENUM.DETAIL_ACTIVITY_SPRAYING_PREPLANTING],
    parent: PRIVILEGE_PARENT_ENUM.AREA,
    url: "/lahan/:status/:id/spraying_preplanting/:activity_id",
    component: SprayingDetail
  },
  {
    enum: [PRIVILEGE_ENUM.DETAIL_ACTIVITY_HARVEST_PREDICTION],
    parent: PRIVILEGE_PARENT_ENUM.AREA,
    url: "/lahan/:status/:id/harvest_prediction/:activity_id",
    component: HarvestEstimationDetail
  },
  {
    enum: [PRIVILEGE_ENUM.DETAIL_ACTIVITY_HARVEST],
    parent: PRIVILEGE_PARENT_ENUM.AREA,
    url: "/lahan/:status/:id/harvest/:activity_id",
    component: HarvestDetail
  },
  {
    enum: [PRIVILEGE_ENUM.DETAIL_ACTIVITY_HARVEST],
    parent: PRIVILEGE_PARENT_ENUM.AREA,
    url: "/lahan/:status/:id/harvest_failure/:activity_id",
    component: HarvestFailureDetail
  },
  {
    enum: [PRIVILEGE_ENUM.DETAIL_ACTIVITY_HARVEST],
    parent: PRIVILEGE_PARENT_ENUM.AREA,
    url: RoutesConstants.AREA.FORM_FAILED_HARVEST(":id"),
    component: CreateFailedHarvest
  },
  {
    enum: [PRIVILEGE_ENUM.LIST_AREA_ACTIVITY],
    parent: PRIVILEGE_PARENT_ENUM.AREA,
    url: RoutesConstants.AREA.PREVIEW_BULK_ERP(),
    component: PreviewBulkErp
  },
  {
    enum: [PRIVILEGE_ENUM.MAP_PREVIEW],
    parent: PRIVILEGE_PARENT_ENUM.AREA,
    url: "/lahan/list/map",
    component: AreaMap,
    iconMenu: ICON_CONSTANS.IC_LOCATION_RED,
    navigateLink: "/lahan/list/map",
    indexMenu: 0,
    menuTitle: "menu_aside.area_location"
  },
  {
    enum: [PRIVILEGE_ENUM.LIST_AREA_REGISTERED],
    parent: PRIVILEGE_PARENT_ENUM.AREA,
    url: RoutesConstants.AREA.LIST_AREA_TEREGISTRASI(":tab"),
    component: AreaListTeregistrasi,
    iconMenu: ICON_CONSTANS.IC_BULLET_TICK,
    navigateLink: RoutesConstants.AREA.LIST_AREA_TEREGISTRASI("all"),
    indexMenu: 1,
    menuTitle: "menu_aside.area_terverifikasi"
  },
  {
    enum: [PRIVILEGE_ENUM.LIST_AREA_PENDING],
    parent: PRIVILEGE_PARENT_ENUM.AREA,
    url: "/lahan/list/pending",
    component: ListAreaPending,
    iconMenu: ICON_CONSTANS.IC_ORDER_PENDING,
    navigateLink: "/lahan/list/pending",
    indexMenu: 2,
    menuTitle: "menu_aside.area_pending"
  },
  {
    enum: [PRIVILEGE_ENUM.LIST_AREA_PENDING],
    parent: PRIVILEGE_PARENT_ENUM.AREA,
    url: "/lahan/list/reject",
    component: ListAreaReject,
    iconMenu: ICON_CONSTANS.IC_BULLET_CLOSE_RED,
    navigateLink: "/lahan/list/reject",
    indexMenu: 3,
    menuTitle: "menu_aside.area_reject"
  },
  {
    enum: [PRIVILEGE_ENUM.LIST_AREA_UNASSIGNED],
    parent: PRIVILEGE_PARENT_ENUM.AREA,
    url: "/lahan/list-unassign/unassign",
    component: ListAreaUnassigned,
    iconMenu: ICON_CONSTANS.IC_FIELD_UNASSIGN,
    navigateLink: "/lahan/list-unassign/unassign",
    indexMenu: 4,
    menuTitle: "menu_aside.area_unassigned"
  },
  {
    enum: [PRIVILEGE_ENUM.LIST_AREA_LEAD],
    parent: PRIVILEGE_PARENT_ENUM.AREA,
    url: "/lahan/list/lead",
    component: ListAreaLead,
    iconMenu: ICON_CONSTANS.SIDE_MENU.MAP_LEAD,
    navigateLink: "/lahan/list/lead",
    indexMenu: 5,
    menuTitle: "menu_aside.area_lead"
  },
  {
    enum: [PRIVILEGE_ENUM.LIST_AREA_ACTIVITY],
    parent: PRIVILEGE_PARENT_ENUM.AREA,
    url: RoutesConstants.AREA.LIST_AREA_READY_PLANTING_SEASON_CONFIRMATION(),
    component: ListReadyPlantingSeasonConfirmation,
    iconMenu: ICON_CONSTANS.IC_HARVEST,
    navigateLink: RoutesConstants.AREA.LIST_AREA_READY_PLANTING_SEASON_CONFIRMATION(),
    indexMenu: 8,
    menuTitle: "menu_aside.ready_planting_season_confirmation"
  },
  // farmer
  {
    enum: [PRIVILEGE_ENUM.UPDATE_FARMER],
    parent: PRIVILEGE_PARENT_ENUM.FARMER,
    url: "/farmer/action/:status/:actionDetail/:id",
    component: CreateEditFarmer
  },
  {
    enum: [
      PRIVILEGE_ENUM.DETAIL_FARMER_REJECTED,
      PRIVILEGE_ENUM.DETAIL_FARMER_PENDING,
      PRIVILEGE_ENUM.DETAIL_FARMER_REGISTERED
    ],
    parent: PRIVILEGE_PARENT_ENUM.FARMER,
    url: RoutesConstants.FARMER.DETAIL_FARMER(":status", ":id"),
    component: FarmerDetail
  },
  {
    enum: [PRIVILEGE_ENUM.LIST_FARMER_REGISTERED],
    parent: PRIVILEGE_PARENT_ENUM.FARMER,
    url: RoutesConstants.FARMER.LIST_FARMER(":tab"),
    component: ListFarmer,
    iconMenu: ICON_CONSTANS.IC_FARMER,
    navigateLink: RoutesConstants.FARMER.LIST_FARMER(ROUTING_STATUS.STATUS_ENUM.PENDING),
    indexMenu: 0,
    menuTitle: "label.farmer_list"
  },
  {
    enum: [PRIVILEGE_ENUM.LIST_FARMER_REGISTERED],
    parent: PRIVILEGE_PARENT_ENUM.FARMER,
    url: RoutesConstants.FARMER.LIST_FARMER_CA(),
    component: ListFarmerKur,
    iconMenu: ICON_CONSTANS.IC_FARMER,
    navigateLink: RoutesConstants.FARMER.LIST_FARMER_CA(),
    indexMenu: 0,
    menuTitle: "sub_header.farmer_list_ca"
  },

  {
    enum: [PRIVILEGE_ENUM.EDIT_ROLE_PRIVILEGE],
    parent: PRIVILEGE_PARENT_ENUM.SETTING,
    url: "/role/action/:type/:role/:id",
    component: EditRole
  },
  {
    enum: [PRIVILEGE_ENUM.UPDATE_VIDEO],
    parent: PRIVILEGE_PARENT_ENUM.SETTING,
    url: "/video/action/:actionDetail/:id",
    component: CreateEditVideo
  },
  {
    enum: [PRIVILEGE_ENUM.INSERT_VIDEO],
    parent: PRIVILEGE_PARENT_ENUM.SETTING,
    url: "/video/action/:actionDetail",
    component: CreateEditVideo
  },
  {
    enum: [PRIVILEGE_ENUM.DETAIL_VIDEO],
    parent: PRIVILEGE_PARENT_ENUM.SETTING,
    url: "/video/detail/:id",
    component: ListVideoDetail
  },
  {
    enum: [PRIVILEGE_ENUM.LIST_ROLE],
    parent: PRIVILEGE_PARENT_ENUM.SETTING,
    url: "/role/list/:type",
    component: RoleList
  },
  {
    enum: [PRIVILEGE_ENUM.LIST_ROLE],
    parent: PRIVILEGE_PARENT_ENUM.SETTING,
    url: "/role/list",
    component: RoleList,
    iconMenu: ICON_CONSTANS.IC_ROLE_ACCESS,
    navigateLink: "/role/list",
    indexMenu: 0,
    menuTitle: "Role List"
  },
  {
    enum: [PRIVILEGE_ENUM.DETAIL_USER_CMS],
    parent: PRIVILEGE_PARENT_ENUM.SETTING,
    url: "/account/list/detail/:id",
    component: AccountDetail
  },
  {
    enum: [PRIVILEGE_ENUM.EDIT_USER_CMS],
    parent: PRIVILEGE_PARENT_ENUM.SETTING,
    url: "/account/action/:action/:id",
    component: CreateAccount
  },
  {
    enum: [PRIVILEGE_ENUM.LIST_USER_CMS],
    parent: PRIVILEGE_PARENT_ENUM.SETTING,
    url: "/account/list",
    component: ListAccount,
    iconMenu: ICON_CONSTANS.SUB_MENU_ADD.ADD_USER_CMS,
    navigateLink: "/account/list",
    indexMenu: 1,
    menuTitle: "User CMS"
  },
  {
    enum: [PRIVILEGE_ENUM.LIST_VIDEO],
    parent: PRIVILEGE_PARENT_ENUM.SETTING,
    url: "/video/list",
    component: ListVideo,
    iconMenu: ICON_CONSTANS.IC_PLAY,
    navigateLink: "/video/list",
    indexMenu: 2,
    menuTitle: "sub_header.manage_video_tutorial"
  },

  // broadcast
  {
    enum: [PRIVILEGE_ENUM.BROADCAST_MESSAGE_DETAIL],
    parent: PRIVILEGE_PARENT_ENUM.SETTING,
    url: "/broadcast/detail/:id",
    component: BroadcastDetail
  },
  {
    enum: [PRIVILEGE_ENUM.BROADCAST_MESSAGE_CREATE],
    parent: PRIVILEGE_PARENT_ENUM.SETTING,
    url: "/broadcast/new",
    component: BroadcastCreate
  },

  {
    enum: [PRIVILEGE_ENUM.BROADCAST_MESSAGE],
    parent: PRIVILEGE_PARENT_ENUM.SETTING,
    url: "/broadcast/list",
    component: BroadcastList,
    iconMenu: ICON_CONSTANS.IC_MAIL,
    navigateLink: "/broadcast/list",
    indexMenu: 3,
    menuTitle: "Broadcast Message"
  },
  // master
  //package
  {
    enum: [PRIVILEGE_ENUM.MODIFIED_PACKAGE],
    parent: PRIVILEGE_PARENT_ENUM.MASTER,
    url: "/master/package/new",
    component: CreatePackage
  },
  {
    enum: [PRIVILEGE_ENUM.PACKAGE_DETAIL],
    parent: PRIVILEGE_PARENT_ENUM.MASTER,
    url: RoutesConstants.MASTER_DATA.PACKAGE.DETAIL(":id"),
    component: PackageDetail
  },
  {
    enum: [PRIVILEGE_ENUM.MODIFIED_PACKAGE],
    parent: PRIVILEGE_PARENT_ENUM.MASTER,
    url: "/master/package/list/edit/:id",
    component: CreatePackage
  },
  {
    enum: [PRIVILEGE_ENUM.PACKAGE_LIST],
    parent: PRIVILEGE_PARENT_ENUM.MASTER,
    url: RoutesConstants.MASTER_DATA.REGIONAL.PAGE(),
    component: MasterDataRegionalPage,
    iconMenu: ICON_CONSTANS.IC_REGIONAL,
    navigateLink: RoutesConstants.MASTER_DATA.REGIONAL.PAGE(),
    indexMenu: 0,
    menuTitle: "menu_aside.regional"
  },
  {
    enum: [PRIVILEGE_ENUM.PACKAGE_LIST],
    parent: PRIVILEGE_PARENT_ENUM.MASTER,
    url: RoutesConstants.MASTER_DATA.PACKAGE.PAGE(),
    component: PackageList,
    iconMenu: ICON_CONSTANS.IC_PACKAGE,
    navigateLink: RoutesConstants.MASTER_DATA.PACKAGE.PAGE(),
    indexMenu: 1,
    menuTitle: "sub_header.spraying_package"
  },
  //  create-treatment
  {
    enum: [PRIVILEGE_ENUM.MODIFIED_TREATMENT],
    parent: PRIVILEGE_PARENT_ENUM.MASTER,
    url: RoutesConstants.MASTER_DATA.TREATMENT.EDIT(
      ":package_id",
      ":plant_type_id",
      ":sequence",
      ":treatment_id"
    ),
    component: CreateUpdateTreatment
  },
  {
    enum: [PRIVILEGE_ENUM.MODIFIED_TREATMENT],
    parent: PRIVILEGE_PARENT_ENUM.MASTER,
    url: RoutesConstants.MASTER_DATA.TREATMENT.NEW(":package_id", ":plant_type_id", ":sequence"),
    component: CreateUpdateTreatment
  },

  {
    enum: [PRIVILEGE_ENUM.MODIFIED_CATEGORY_ITEM],
    parent: PRIVILEGE_PARENT_ENUM.MASTER,
    url: RoutesConstants.MASTER_DATA.REGIONAL.ADD_REGIONAL(),
    component: CreateRegionalPage
  },

  {
    enum: [PRIVILEGE_ENUM.MODIFIED_CATEGORY_ITEM],
    parent: PRIVILEGE_PARENT_ENUM.MASTER,
    url: RoutesConstants.MASTER_DATA.REGIONAL.CREATE_SUB_REGIONAL(":regionalId"),
    component: CreateSubRegionalPage
  },
  {
    enum: [PRIVILEGE_ENUM.MODIFIED_CATEGORY_ITEM],
    parent: PRIVILEGE_PARENT_ENUM.MASTER,
    url: RoutesConstants.MASTER_DATA.REGIONAL.EDIT_SUB_REGIONAL(":subRegionalId", ":regionalId"),
    component: CreateSubRegionalPage
  },

  {
    enum: [PRIVILEGE_ENUM.MODIFIED_CATEGORY_ITEM],
    parent: PRIVILEGE_PARENT_ENUM.MASTER,
    url: RoutesConstants.MASTER_DATA.REGIONAL.EDIT_REGIONAL(":id"),
    component: CreateRegionalPage
  },
  {
    enum: [PRIVILEGE_ENUM.MODIFIED_CATEGORY_ITEM],
    parent: PRIVILEGE_PARENT_ENUM.MASTER,
    url: RoutesConstants.MASTER_DATA.REGIONAL.DETAIL_REGIONAL(":id"),
    component: DetailRegionalPage
  },
  //  benih
  {
    enum: [PRIVILEGE_ENUM.MODIFIED_CATEGORY_ITEM],
    parent: PRIVILEGE_PARENT_ENUM.MASTER,
    url: "/master/category-item/seed/new/:subType",
    component: CreateSeed
  },
  {
    enum: [PRIVILEGE_ENUM.MODIFIED_CATEGORY_ITEM],
    parent: PRIVILEGE_PARENT_ENUM.MASTER,
    url: "/master/category-item/fertilizer/new",
    component: CreatePupuk
  },
  {
    enum: [PRIVILEGE_ENUM.CATEGORY_ITEM_DETAIL],
    parent: PRIVILEGE_PARENT_ENUM.MASTER,
    url: "/master/category-item/detail/seed/:subType",
    component: DetailSeed
  },
  {
    enum: [PRIVILEGE_ENUM.CATEGORY_ITEM_DETAIL],
    parent: PRIVILEGE_PARENT_ENUM.MASTER,
    url: "/master/category-item/detail/fertilizer/:subType",
    component: DetailFertilizer
  },
  //  pesticide
  {
    enum: [PRIVILEGE_ENUM.MODIFIED_CATEGORY_ITEM],
    parent: PRIVILEGE_PARENT_ENUM.MASTER,
    url: "/master/category-item/detail/pesticide/new/:subType",
    component: CreatePesticide
  },
  {
    enum: [PRIVILEGE_ENUM.CATEGORY_ITEM_DETAIL],
    parent: PRIVILEGE_PARENT_ENUM.MASTER,
    url: "/master/category-item/detail/pesticide/:subType",
    component: DetailPesticide
  },
  // plant type
  {
    enum: [PRIVILEGE_ENUM.DETAIL_PLANT_TYPE],
    parent: PRIVILEGE_PARENT_ENUM.MASTER,
    url: "/master/category-item/detail/plant_type/:subType",
    component: DetailPlantType
  },
  //  mesin
  {
    enum: [PRIVILEGE_ENUM.CATEGORY_ITEM_DETAIL],
    parent: PRIVILEGE_PARENT_ENUM.MASTER,
    url: "/master/category-item/detail/machine/:subType",
    component: DetailMachine
  },
  // pupuk
  {
    enum: [PRIVILEGE_ENUM.CATEGORY_ITEM],
    parent: PRIVILEGE_PARENT_ENUM.MASTER,
    url: "/master/category-item",
    component: CategoryItemList,
    iconMenu: ICON_CONSTANS.IC_INVENTORY,
    navigateLink: "/master/category-item",
    indexMenu: 2,
    menuTitle: "sub_header.category_item"
  },
  //  manage reason
  {
    enum: [PRIVILEGE_ENUM.MANAGE_REASON_DETAIL],
    parent: PRIVILEGE_PARENT_ENUM.MASTER,
    url: "/master/manage-reason/:id",
    component: ManageReasonDetail
  },
  {
    enum: [PRIVILEGE_ENUM.MANAGE_REASON_LIST],
    parent: PRIVILEGE_PARENT_ENUM.MASTER,
    url: "/master/manage-reason",
    component: ManageReasonList,
    iconMenu: ICON_CONSTANS.IC_IDEA,
    navigateLink: "/master/manage-reason",
    indexMenu: 3,
    menuTitle: "sub_header.manage_reason"
  },

  // order
  {
    enum: [PRIVILEGE_ENUM.APPROVAL_ORDER],
    parent: PRIVILEGE_PARENT_ENUM.ORDER,
    url: RoutesConstants.ORDER.APPROVAL_PASCA_TANAM(":status", ":id"),
    component: ApprovalPascaTanam
  },
  {
    enum: [PRIVILEGE_ENUM.APPROVAL_ORDER],
    parent: PRIVILEGE_PARENT_ENUM.ORDER,
    url: RoutesConstants.ORDER.APPROVAL_PRE_PLANTING(":status", ":id"),
    component: ApprovalPrePlatingPage
  },
  {
    enum: [PRIVILEGE_ENUM.APPROVAL_ORDER],
    parent: PRIVILEGE_PARENT_ENUM.ORDER,
    url: RoutesConstants.ORDER.APPROVAL_BULK_PASCA_TANAM(),
    component: ApprovalBulkPascaTanam
  },
  {
    enum: [PRIVILEGE_ENUM.APPROVAL_ORDER],
    parent: PRIVILEGE_PARENT_ENUM.ORDER,
    url: RoutesConstants.ORDER.APPROVAL_BULK_PRE_PLANTING(),
    component: ApprovalBulkPascaTanam
  },
  {
    enum: [PRIVILEGE_ENUM.DETAIL_ORDER],
    parent: PRIVILEGE_PARENT_ENUM.MAC,
    url: RoutesConstants.ORDER.DETAIL_PASCA_PLANTING(":status", ":id"),
    component: DetailPascaTanam
  },
  {
    enum: [PRIVILEGE_ENUM.DETAIL_ORDER],
    parent: PRIVILEGE_PARENT_ENUM.ORDER,
    url: RoutesConstants.ORDER.DETAIL_PRE_PLANTING(":status", ":id"),
    component: DetailPrePlantingPage
  },
  {
    enum: [PRIVILEGE_ENUM.LIST_ORDER_ALL],
    parent: PRIVILEGE_PARENT_ENUM.ORDER,
    url: RoutesConstants.ORDER.LIST_PASCA_PLANTING_BY_STATUS(":tab"),
    component: ListPascaTanam,
    iconMenu: ICON_CONSTANS.IC_ORDER_PASCA_PLANTING,
    navigateLink: RoutesConstants.ORDER.LIST_PASCA_PLANTING_BY_STATUS("pending"),
    indexMenu: 0,
    menuTitle: "menu_aside.schedule_pasca_planting"
  },
  {
    enum: [PRIVILEGE_ENUM.LIST_ORDER_ALL],
    parent: PRIVILEGE_PARENT_ENUM.ORDER,
    url: RoutesConstants.ORDER.LIST_PRE_PLANTING_BY_STATUS(":tab"),
    component: PrePlantingSprayingPage,
    iconMenu: ICON_CONSTANS.IC_DRONE_SPRAYING,
    navigateLink: RoutesConstants.ORDER.LIST_PRE_PLANTING_BY_STATUS("pending"),
    indexMenu: 1,
    menuTitle: "menu_aside.pre_planting"
  },
  {
    enum: [PRIVILEGE_ENUM.APPROVAL_ORDER],
    parent: PRIVILEGE_PARENT_ENUM.ORDER,
    url: RoutesConstants.ORDER.APPROVAL_BULK(":planting"),
    component: PrePlantingBulkApprove
  },
  //   mac
  {
    enum: [PRIVILEGE_ENUM.APPROVAL_ORDER],
    parent: PRIVILEGE_PARENT_ENUM.MAC,
    url: RoutesConstants.MAC.PROFILE_DETAIL(":id"),
    component: ProfileDetail
  },
  {
    enum: [PRIVILEGE_ENUM.APPROVAL_ORDER],
    parent: PRIVILEGE_PARENT_ENUM.MAC,
    url: RoutesConstants.MAC.DETAIL_ACTIVITY_REPORT(":id"),
    component: DetailActivityReportMac
  },
  {
    enum: [PRIVILEGE_ENUM.APPROVAL_ORDER],
    parent: PRIVILEGE_PARENT_ENUM.MAC,
    url: RoutesConstants.MAC.USER_DETAIL(":id"),
    component: UserDetail
  },
  {
    enum: [PRIVILEGE_ENUM.APPROVAL_ORDER],
    parent: PRIVILEGE_PARENT_ENUM.MAC,
    url: RoutesConstants.MAC.MAC_SPRAYING_APPROVAL(":status", ":id"),
    component: MacSprayingApproval
  },
  {
    enum: [PRIVILEGE_ENUM.APPROVAL_ORDER],
    parent: PRIVILEGE_PARENT_ENUM.MAC,
    url: RoutesConstants.MAC.SPRAYING_DETAIL(":status", ":id"),
    component: MacSprayingDetail
  },
  {
    enum: [PRIVILEGE_ENUM.APPROVAL_ORDER],
    parent: PRIVILEGE_PARENT_ENUM.MAC,
    url: RoutesConstants.MAC.LIST_PROFILE(),
    component: ListProfile,
    iconMenu: ICON_CONSTANS.IC_PROFILE_MAC,
    navigateLink: RoutesConstants.MAC.LIST_PROFILE(),
    indexMenu: 0,
    menuTitle: "menu_aside.mac_profile"
  },

  {
    enum: [PRIVILEGE_ENUM.APPROVAL_ORDER],
    parent: PRIVILEGE_PARENT_ENUM.MAC,
    url: RoutesConstants.MAC.LIST_USER(),
    component: ListUser,
    iconMenu: ICON_CONSTANS.IC_USER_MAC,
    navigateLink: RoutesConstants.MAC.LIST_USER(),
    indexMenu: 1,
    menuTitle: "menu_aside.mac_user"
  },

  {
    enum: [PRIVILEGE_ENUM.APPROVAL_ORDER],
    parent: PRIVILEGE_PARENT_ENUM.MAC,
    url: RoutesConstants.MAC.LIST_SPRAYING(":tab"),
    component: MacSprayingPage,
    iconMenu: ICON_CONSTANS.IC_PLANT_SPRAYING,
    navigateLink: RoutesConstants.MAC.LIST_SPRAYING(ROUTING_STATUS.MAC_SPRAYING_STRING.PENDING),
    indexMenu: 2,
    menuTitle: "menu_aside.spraying"
  },
  {
    enum: [PRIVILEGE_ENUM.APPROVAL_ORDER],
    parent: PRIVILEGE_PARENT_ENUM.MAC,
    url: RoutesConstants.MAC.ACTIVITY_REPORT_LIST(),
    component: MacActivityReportList,
    iconMenu: ICON_CONSTANS.IC_TASK,
    navigateLink: RoutesConstants.MAC.ACTIVITY_REPORT_LIST(),
    indexMenu: 3,
    menuTitle: "menu_aside.area_activity_report"
  },
  //   submission
  {
    enum: [PRIVILEGE_ENUM.CERTIFICATION_DETAIL_SUBMISSION],
    parent: PRIVILEGE_PARENT_ENUM.SUBMISSION,
    url: RoutesConstants.SUBMISSION.DETAIL_AREA_CERTIFICATION_PENDING(":id"),
    component: DetailCertificationPending
  },
  {
    enum: [PRIVILEGE_ENUM.CERTIFICATION_DETAIL_SUBMISSION],
    parent: PRIVILEGE_PARENT_ENUM.SUBMISSION,
    url: RoutesConstants.SUBMISSION.DETAIL_BLOCK_CERTIFICATION(":status", ":id"),
    component: DetailCertificationBlock
  },
  {
    enum: [PRIVILEGE_ENUM.CERTIFICATION_LIST_SUBMISSION_ALL],
    parent: PRIVILEGE_PARENT_ENUM.SUBMISSION,
    url: RoutesConstants.SUBMISSION.LIST_SUBMISSION_BY_STATUS(":tab"),
    component: ListCertificationBlock,
    iconMenu: ICON_CONSTANS.IC_SUBMISSION_AND_BLOCK,
    navigateLink: RoutesConstants.SUBMISSION.LIST_SUBMISSION_BY_STATUS(
      ROUTING_STATUS.CERTIFICATION_STATUS_STRING.PENDING
    ),
    indexMenu: 0,
    menuTitle: "menu_aside.sertification_and_block"
  },
  {
    enum: [PRIVILEGE_ENUM.CERTIFICATION_CREATE_SUBMISSION],
    parent: PRIVILEGE_PARENT_ENUM.SUBMISSION,
    url: RoutesConstants.SUBMISSION.CREATE_SUBMISSION(),
    component: CreateSubmissionPage
  },
  //QUICK ACCESS
  {
    enum: [PRIVILEGE_ENUM.CREATE_FARMER],
    parent: PRIVILEGE_PARENT_ENUM.ADD,
    url: "/farmer/action/:actionDetail",
    navigateLink: RoutesConstants.FARMER.CREATE(),
    component: CreateEditFarmer,
    iconMenu: ICON_CONSTANS.SUB_MENU_ADD.ADD_FARMER,
    title: "Petani",
    subTitle: "Tambah petani baru ke dalam sistem",
    subMenu: SUB_MENU_ENUM.USER
  },
  {
    enum: [PRIVILEGE_ENUM.CREATE_MITRA],
    parent: PRIVILEGE_PARENT_ENUM.ADD,
    url: "/mitras/action/:actionDetail",
    navigateLink: RoutesConstants.MITRA.CREATE(),
    component: CreateEditMitra,
    iconMenu: ICON_CONSTANS.SUB_MENU_ADD.ADD_MITRA,
    title: "Mitra",
    subTitle: "Tambah mitra baru ke dalam sistem",
    subMenu: SUB_MENU_ENUM.USER
  },
  {
    enum: [PRIVILEGE_ENUM.CREATE_FIELD_OFFICER],
    parent: PRIVILEGE_PARENT_ENUM.ADD,
    url: RoutesConstants.FIELD_OFFICER.CREATE_FO(),
    navigateLink: RoutesConstants.FIELD_OFFICER.CREATE_FO(),
    component: FieldOfficerFormWrapper,
    iconMenu: ICON_CONSTANS.SUB_MENU_ADD.ADD_FO,
    title: "FO",
    subTitle: "Tambah FO baru ke dalam sistem",
    subMenu: SUB_MENU_ENUM.USER
  },
  {
    enum: [PRIVILEGE_ENUM.CREATE_USER_CMS],
    parent: PRIVILEGE_PARENT_ENUM.ADD,
    url: "/account/action/:actionDetail",
    navigateLink: RoutesConstants.SETTING.CREATE_USER(),
    component: CreateAccount,
    iconMenu: ICON_CONSTANS.SUB_MENU_ADD.ADD_USER_CMS,
    title: "User CMS",
    subTitle: "Tambah user CMS (admin) baru ke dalam sistem",
    subMenu: SUB_MENU_ENUM.USER
  },
  {
    enum: [PRIVILEGE_ENUM.CREATE_USER_CMS],
    parent: PRIVILEGE_PARENT_ENUM.ADD,
    url: RoutesConstants.REPORT.CREATE_REPORT(),
    navigateLink: RoutesConstants.REPORT.CREATE_REPORT(),
    component: CreateAccount,
    iconMenu: ICON_CONSTANS.SUB_MENU_ADD.ADD_LAND_CLEARING,
    title: "Bongkar",
    subMenu: SUB_MENU_ENUM.BA
  },
  // REPORT
  {
    enum: [PRIVILEGE_ENUM.LIST_AREA_ACTIVITY],
    parent: PRIVILEGE_PARENT_ENUM.REPORT,
    url: RoutesConstants.AREA.LIST_AREA_ACTIVITY_KUR(),
    component: ListAreaActivityKur,
    iconMenu: ICON_CONSTANS.SIDE_MENU.ACTIVITY,
    navigateLink: RoutesConstants.AREA.LIST_AREA_ACTIVITY_KUR(),
    indexMenu: 1,
    menuTitle: "menu_aside.area_activity_report_kur"
  },
  {
    enum: [PRIVILEGE_ENUM.LIST_AREA_ACTIVITY],
    parent: PRIVILEGE_PARENT_ENUM.REPORT,
    url: RoutesConstants.AREA.LIST_AREA_ACTIVITY(":tab"),
    component: ListActivityReportV2,
    iconMenu: ICON_CONSTANS.SIDE_MENU.ACTIVITY,
    navigateLink: RoutesConstants.AREA.LIST_AREA_ACTIVITY(
      ROUTING_STATUS.ACTIVITY_REPORT.READY_SEND_FINANCE
    ),
    indexMenu: 0,
    menuTitle: "menu_aside.area_activity_report"
  },
  {
    enum: [PRIVILEGE_ENUM.CERTIFICATION_LIST_SUBMISSION_ALL],
    parent: PRIVILEGE_PARENT_ENUM.REPORT,
    url: RoutesConstants.REPORT.LIST_BA_SEEDING(),
    component: SeedingBaPage,
    iconMenu: ICON_CONSTANS.IC_TASK,
    navigateLink: RoutesConstants.REPORT.LIST_BA_SEEDING(),
    indexMenu: 2,
    menuTitle: "menu_aside.ba_seeding"
  },
  {
    enum: [PRIVILEGE_ENUM.CERTIFICATION_CREATE_SUBMISSION],
    parent: PRIVILEGE_PARENT_ENUM.REPORT,
    url: RoutesConstants.REPORT.LIST_BA_SEEDING(),
    component: SeedingBaPage
  },
  {
    enum: [PRIVILEGE_ENUM.CERTIFICATION_CREATE_SUBMISSION],
    parent: PRIVILEGE_PARENT_ENUM.REPORT,
    url: RoutesConstants.REPORT.CREATE_REPORT(),
    component: CreateReportBaPage
  },
  {
    enum: [PRIVILEGE_ENUM.CREATE_USER_CMS],
    parent: PRIVILEGE_PARENT_ENUM.ADD,
    url: "/",
    navigateLink: "/",
    component: CreateAccount,
    iconMenu: ICON_CONSTANS.SUB_MENU_ADD.ADD_INCORRECT_VARIETY,
    title: "Salah Varietas",
    subMenu: SUB_MENU_ENUM.BA
  }
]

export const PrivilegeCollectionParent = [
  {
    parent: PRIVILEGE_PARENT_ENUM.FARMER,
    iconMenu: ICON_CONSTANS.SIDE_MENU.FARMER,
    menuTitle: "menu.farmer"
  },
  {
    parent: PRIVILEGE_PARENT_ENUM.MITRA,
    iconMenu: ICON_CONSTANS.SIDE_MENU.MITRA,
    menuTitle: "menu.mitra"
  },
  {
    parent: PRIVILEGE_PARENT_ENUM.FO,
    iconMenu: ICON_CONSTANS.SIDE_MENU.FO,
    menuTitle: "menu.fo"
  },
  {
    parent: PRIVILEGE_PARENT_ENUM.AREA,
    iconMenu: ICON_CONSTANS.SIDE_MENU.AREA,
    menuTitle: "menu.area"
  },
  {
    parent: PRIVILEGE_PARENT_ENUM.REPORT,
    iconMenu: ICON_CONSTANS.IC_TASK,
    menuTitle: "menu.report"
  },
  {
    parent: PRIVILEGE_PARENT_ENUM.ORDER,
    iconMenu: ICON_CONSTANS.SIDE_MENU.ORDER,
    menuTitle: "menu.order"
  },
  {
    parent: PRIVILEGE_PARENT_ENUM.SUBMISSION,
    iconMenu: ICON_CONSTANS.SIDE_MENU.SUBMISSION,
    menuTitle: "menu.submission"
  },
  {
    parent: PRIVILEGE_PARENT_ENUM.MAC,
    iconMenu: ICON_CONSTANS.SIDE_MENU.MAC,
    menuTitle: "menu.mac"
  },
  {
    parent: PRIVILEGE_PARENT_ENUM.SETTING,
    iconMenu: ICON_CONSTANS.SIDE_MENU.SETTING,
    menuTitle: "menu.setting"
  },
  {
    parent: PRIVILEGE_PARENT_ENUM.MASTER,
    iconMenu: ICON_CONSTANS.SIDE_MENU.MASTER,
    menuTitle: "menu.master_data"
  }
]

export const QuickAccessMenu = [
  {
    parent: "USER",
    title: "Tambah Pengguna"
  },
  {
    parent: "BA",
    title: "Buat Berita Acara"
  }
]
