import {
  Card,
  CardBody,
  CardHeaderTitle
} from "../../../../../../_metronic/_partials/controls/index.js"
import {
  AddressMolecule,
  RadioButtonGroup,
  SelectInput,
  TextInput
} from "../../../../../../component/index.jsx"
import { RowModule } from "../../../../../../component/atoms/RowModule/RowModule.jsx"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { getAllMitra } from "../../../../../../redux/actions/MitraAction.jsx"
import { renderLabelRequired } from "../../../../../helpers/FormikHelper.js"
import { getListRegionalAll } from "../../../../../../redux/actions/MasterDataAction.jsx"
import { getUserRegional } from "../../../../../service/userManagementAction.js"
import { useFormikContext } from "formik"
import { useTranslation } from "react-i18next"
import { GENDER_TYPE } from "../../../../../../constants/StringConstant.js"

export const DataFarmer = ({ isReadOnly, onKeyDown, id }) => {
  const { Mitralist, MasterData } = useSelector((state) => state)
  const dispatch = useDispatch()
  const [mitraList, setMitraList] = useState([])
  const [mitraId, setMitraId] = useState(null)
  const [gender, setGender] = useState("")
  const {t} = useTranslation()

  const [regionalList, setRegionalList] = useState([])
  const [regionalId, setRegionalId] = useState(null)
  const [isHaveRegional, setIsHaveRegional] = useState(false)
  const [isChangeRegional, setIsChangeRegional] = useState(false)

  const { values: formValues } = useFormikContext()

  useEffect(() => {
    dispatch(getAllMitra())
    dispatch(getListRegionalAll())
  }, [dispatch])

  useEffect(() => {
    const userRegional = getUserRegional()
    if (userRegional !== "null") {
      formValues["regionalId"] = userRegional
      setRegionalId(userRegional)
      setIsHaveRegional(true)
    }
  }, [regionalList])

  useEffect(() => {
    if (Mitralist.data != undefined) {
      setMitraList(
        Mitralist.data.map((value) => ({
          label: value.name,
          value: value.id_mitra
        }))
      )
    }
  }, [Mitralist.data])

  useEffect(() => {
    if (MasterData.listRegionalAll != undefined) {
      setRegionalList(
        MasterData.listRegionalAll.map((value) => ({
          label: value.name + " (" + value.code + ")",
          value: value.id
        }))
      )
    }
  }, [MasterData.listRegionalAll])

  useEffect(() => {
    if (formValues.regionalId) {
      setRegionalId(formValues.regionalId)
    }
  }, [formValues, dispatch])

  useEffect(() => {
    if (formValues.mitraId) {
      setMitraId(formValues.mitraId)
    }
  }, [formValues, dispatch, mitraList])

  function onChangeMitra(e) {
    setMitraId(e)
  }

  function onChangeRegional(e) {
    setRegionalId(e)
    setIsChangeRegional(true)
  }

  return (
    <>
      <Card className="card-custom card-shadowless">
        <CardBody>
          <RowModule>
            <CardHeaderTitle className={"my-12"}>
              {t("sub_header.farmer_detail").toUpperCase()}
            </CardHeaderTitle>
          </RowModule>
          <RowModule>
            <SelectInput
              name="regionalId"
              required
              onChange={onChangeRegional}
              editable={isHaveRegional ? false : true}
              label={t("label.regional")}
              placeholder={t("placeholder.choose_one")}
              value={regionalId}
              options={regionalList}
            />
          </RowModule>
          <RowModule>
            <SelectInput
              name="mitraId"
              required
              onChange={onChangeMitra}
              editable={id ? false : !isReadOnly}
              label={t("label.mitra_name")}
              placeholder={t("placeholder.choose_one")}
              value={mitraId}
              options={mitraList}
            />
          </RowModule>
          <RowModule>
            <TextInput
              onKeyDown={onKeyDown}
              editable={!isReadOnly}
              label={renderLabelRequired(t("label.farmer_name"))}
              name="farmerName"
              placeholder={t("placeholder.input_farmer_name")}
            />
          </RowModule>
          <RowModule>
            <TextInput
              required
              editable={!isReadOnly}
              label={t("label.date_of_birth")}
              name="dob"
              type="date"
              placeholder={"mm-dd-yyyy"}
            />
          </RowModule>
          <RowModule>
            <TextInput
              editable={!isReadOnly}
              required
              label={t("label.phone_number")}
              name="phoneNumber"
              placeholder={t("placeholder.input_no_hp")}
            />
          </RowModule>
          <label>{renderLabelRequired(t("label.gender"))}</label>
          <RowModule withoutSeparator={true}>
            <RadioButtonGroup
              label={t("label.men")}
              name="gender"
              value={GENDER_TYPE.LAKI_LAKI.replace("-", "_")}
              editable={true}
              withoutFeedback={true}
              checked={gender === GENDER_TYPE.LAKI_LAKI.replace("-", "_")}
              chooseAtBack={true}
              onClick={() => setGender(GENDER_TYPE.LAKI_LAKI.replace("-", "_"))}
            />
            <RadioButtonGroup
              label={t("label.woman")}
              name="gender"
              value={GENDER_TYPE.PEREMPUAN}
              editable={true}
              withoutFeedback={true}
              checked={gender === GENDER_TYPE.PEREMPUAN}
              chooseAtBack={true}
              onClick={() => setGender(GENDER_TYPE.PEREMPUAN)}
            />
          </RowModule>
        </CardBody>
      </Card>

      <AddressMolecule
        name={"farmer"}
        isReadOnly={isReadOnly}
        addressTitle={t("label.farmer_domicile_address").toUpperCase()}
        byRegional={true}
        regionalId={regionalId}
        isChangeRegional={isChangeRegional}
      />
    </>
  )
}