import React from "react"
import { Button, Col, Modal } from "react-bootstrap"
import { toAbsoluteUrl } from "../../../_metronic/_helpers"
import SVG from "react-inlinesvg"
import { ILUSTRATION_CONSTANS } from "../../../constants/ImageConstants"
import { POP_UP_TYPE_STRING } from "../../../constants/StringConstant.js"

export const Popup = ({
  show = false,
  title,
  titleCustom,
  size,
  body,
  onCancel,
  contentClassName,
  showButton = true,
  bodyCustom,
  type,
  closeButton = false,
  centered = true,
  textButton = "Ok",
  onClick = () => {}
}) => {
  return (
    <Modal
      show={show}
      contentClassName={contentClassName}
      centered={centered}
      onHide={onCancel}
      size={size}>
      {(title || titleCustom) && (
        <Modal.Header closeButton={closeButton}>
          {title ? <h6 className="mb-0">{title}</h6> : titleCustom}
        </Modal.Header>
      )}
      <div className="mb-1 mt-3 text-center">
        {type === POP_UP_TYPE_STRING.EMPTY ? <SVG src={toAbsoluteUrl(ILUSTRATION_CONSTANS.EMPTY)} /> : ""}
        {type === POP_UP_TYPE_STRING.BERHASIL ? <SVG src={toAbsoluteUrl(ILUSTRATION_CONSTANS.SUCCESS)} /> : ""}
        {type === POP_UP_TYPE_STRING.GAGAL ? <SVG src={toAbsoluteUrl(ILUSTRATION_CONSTANS.FAIL)} /> : ""}
        {type === POP_UP_TYPE_STRING.NONIMAGE && ""}
      </div>

      {(body || bodyCustom) && (
        <Modal.Body>{body ? <div className="text-center">{body}</div> : bodyCustom}</Modal.Body>
      )}
      {showButton && (
        <div className="justify-content-center my-4">
          <Col className="px-10">
            <Button className="font-weight-bold" variant="primary" block={true} onClick={onClick}>
              {textButton}
            </Button>
          </Col>
        </div>
      )}
    </Modal>
  )
}
