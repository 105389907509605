import React, { useCallback, useEffect, useRef, useState } from "react"
import { RowModule } from "../../../../component/atoms/RowModule/RowModule.jsx"
import { Search, TabsComponent } from "../../../../component"
import { ListFoAgro } from "./TabsFoAgro/ListFoAgro"
import { ListTeamDrone } from "./TabsFoAgro/ListTeamDrone"
import { ListTeamSchedule } from "./TabsFoAgro/ListTeamSchedule"
import { useSubheader } from "../../../../_metronic/layout"
import { useDispatch, useSelector } from "react-redux"
import {
  clearDetailFo,
  getListFOAgro,
  getListFOTeam
} from "../../../../redux/actions/FieldOfficerAction"
import { setDataTabMultiple } from "../../../../redux/actions/FilterAction"
import { initialFilterTable } from "../../../../component/atoms/Tabel"
import { getUrlParsingPageFilter } from "../../../../config/EndpointCollection.js"
import { useLocation } from "react-router"
import { useUrlParam } from "../../../hooks/useUrlParams"
import { loadUserLogin } from "../../../service/userManagementAction"
import { RoutesConstants } from "../../../../constants/RoutesConstants"
import { PRIVILEGE_ENUM } from "../../../../constants/PrivilegeConstants.js"
import { useTranslation } from "react-i18next"

export const FoAgro = ({
  history,
  match: {
    params: { tab }
  }
}) => {
  const dispatch = useDispatch()
  const {t} = useTranslation()
  const { General, FieldOfficer } = useSelector((state) => state)
  const formRef = useRef()
  const [search, setSearch] = useState({
    string_filter: ""
  })
  const subHeader = useSubheader()
  const [isActiveSearch, setIsActiveSearch] = useState(false)
  const [tabActive, setTabActive] = useState(
    tab === "team-drone" ? 1 : tab === "team-schedule" ? 2 : 0
  )
  const [queryStringObj, setQueryStringObj] = useState(undefined)
  const location = useLocation()
  const urlParams = useUrlParam()
  const privileges = loadUserLogin().privilege

  useEffect(() => {
    setTabActive(tab === "team-drone" ? 1 : tab === "team-schedule" ? 2 : 0)
  }, [tab])

  useEffect(() => {
    subHeader.setTitle(t("sub_header.fo_agronomist"))
    subHeader.setButton(false)
    return () => {
      dispatch(clearDetailFo())
    }
  }, [])

  useEffect(() => {
    if (queryStringObj?.string_filter) {
      setSearch({ string_filter: queryStringObj?.string_filter })
      setIsActiveSearch(true)
    }
  }, [queryStringObj])
  const fetchData = (param) => {
    if (tabActive === 0) {
      return dispatch(getListFOAgro(param))
    } else if (tabActive === 1) {
      return dispatch(getListFOTeam(param))
    }
  }

  const searchFunc = (values) => {
    setSearch({ string_filter: values.string_filter })
    setIsActiveSearch(true)
    let param = {
      ...initialFilterTable,
      string_filter: values.string_filter
    }
    const urlParam = getUrlParsingPageFilter(param)
    history.push({ pathname: location.pathname, search: urlParam })
  }

  const handleReset = () => {
    urlParams.resetStringFilter()
    setSearch({ string_filter: "" })
  }

  const changePage = useCallback(
    (param) => {
      let urlParam
      if (param.sortField != null) {
        urlParam = getUrlParsingPageFilter({
          ...param,
          sort: param?.sortField ? param.sortField + "," + param.sortOrder : "",
          string_filter: search.string_filter
        })
      } else {
        urlParam = getUrlParsingPageFilter({
          ...param,
          string_filter: search.string_filter
        })
      }
      history.push({ pathname: location.pathname, search: urlParam })
    },
    [dispatch, search]
  )

  useEffect(() => {
    const result = location?.search
    fetchData(result)
  }, [location.search])

  useEffect(() => {
    setQueryStringObj(urlParams.getUrlParamsObj)
  }, [urlParams.getUrlParamsObj])

  const changeTabs = (data) => {
    dispatch(setDataTabMultiple(data))
    setTabActive(data)
    let urlParam = ""
    if (search?.string_filter) {
      let param = {
        ...initialFilterTable,
        string_filter: search.string_filter
      }
      urlParam = getUrlParsingPageFilter(param)
    }
    if (data === 1) {
      history.push(RoutesConstants.FIELD_OFFICER.LIST_FO_AGRONOMIS("team-drone") + urlParam ?? "")
    } else if (data === 2) {
      history.push(
        RoutesConstants.FIELD_OFFICER.LIST_FO_AGRONOMIS("team-schedule") + urlParam ?? ""
      )
    } else {
      history.push(RoutesConstants.FIELD_OFFICER.LIST_FO_AGRONOMIS("list-fo") + urlParam ?? "")
    }
  }

  const getTabsContents = () => {
    const list = (
      <ListFoAgro
        data={FieldOfficer}
        changePage={changePage}
        history={history}
        general={General}
        initialFilterTable={initialFilterTable}
        isActiveSearch={isActiveSearch}
      />
    )
    const teamDrone = (
      <ListTeamDrone
        data={FieldOfficer}
        changePage={changePage}
        history={history}
        general={General}
        initialFilterTable={initialFilterTable}
        isActiveSearch={isActiveSearch}
      />
    )
    const calendarTeam = <ListTeamSchedule />
    const labelList = []
    const ComponentsList = []
    if (privileges.indexOf(PRIVILEGE_ENUM.LIST_FIELD_OFFICER_AGRONOMIST_TAB_LIST_FO) > 1) {
      ComponentsList.push(list)
      labelList.push(t("label.fo_list"))
    }
    if (privileges.indexOf(PRIVILEGE_ENUM.LIST_FIELD_OFFICER_AGRONOMIST_TAB_TEAM_DRONE) > 1) {
      ComponentsList.push(teamDrone)
      labelList.push(t("label.drone_team"))
    }
    if (privileges.indexOf(PRIVILEGE_ENUM.LIST_FIELD_OFFICER_AGRONOMIST_TAB_JADWAL_TEAM) > 1) {
      ComponentsList.push(calendarTeam)
      labelList.push(t("label.team_schedule").toUpperCase())
    }

    return {
      labels: labelList,
      contents: ComponentsList
    }
  }

  return (
    <>
      {tabActive !== 2 && (
        <div className="filter-container">
          <div className="d-flex flex-wrap justify-content-between align-items-center mb-10">
            <Search
              isActiveSearch={isActiveSearch}
              placeholder={t("placeholder.enter_fo_name")}
              onSubmitForm={searchFunc}
              initialValues={search}
              name={"string_filter"}
              innerRef={formRef}
              trigerReset={handleReset}
            />
          </div>
        </div>
      )}

      <RowModule>
        <TabsComponent
          changeTab={changeTabs}
          labelTab={getTabsContents().labels}
          componentTab={getTabsContents().contents}
          initValue={tabActive}
        />
      </RowModule>
    </>
  )
}