import React, { useCallback, useEffect, useRef, useState } from "react"
import { RowModule } from "../../../../../../../component/atoms/RowModule/RowModule.jsx"
import {
  Card,
  CardBody,
  CardHeader
} from "../../../../../../../_metronic/_partials/controls/index.js"
import TableView, {
  formatterRowComponent,
  initialFilterTable
} from "../../../../../../../component/atoms/Tabel/index.jsx"
import { sortCaret } from "../../../../../../../_metronic/_helpers/index.js"
import { Button } from "react-bootstrap"
import { loadUserLogin } from "../../../../../../service/userManagementAction.js"
import { useDispatch, useSelector } from "react-redux"
import { useSubheader } from "../../../../../../../_metronic/layout/index.js"
import {
  EmptyCard,
  FilterCard,
  SelectInput,
  TableSkeleton
} from "../../../../../../../component/index.jsx"
import {
  getCategoryItemList,
  getPlantForm,
  getPlantType
} from "../../../../../../../redux/actions/MasterDataAction.jsx"
import { useTranslation } from "react-i18next"
import { PRIVILEGE_ENUM } from "../../../../../../../constants/PrivilegeConstants.js"
import { RoutesConstants } from "../../../../../../../constants/RoutesConstants.js"
import { MASTER_DATA_CATEGORY_ITEM_STRING } from "../../../../../../../constants/StringConstant.js"

export const DetailFertilizer = ({
  history,
  match: {
    params: { subType }
  }
}) => {
  const privileges = loadUserLogin().privilege
  const subHeader = useSubheader()
  const { t } = useTranslation()
  const { General, MasterData } = useSelector((state) => state)
  const dispatch = useDispatch()

  const [search, setSearch] = useState({
    string_filter: ""
  })
  const [initialValues, setInitialValues] = useState({
    plant_type_id: "",
    item_shape: "",
    string_filter: ""
  })
  const [showFilter, setShowFilter] = useState(false)
  const [filterOn, setFilterOn] = useState(false)

  const formRef = useRef()

  const isReadOnly = false

  const [statePlantType, setStatePlantType] = useState()
  const [statePlantForm, setStatePlantForm] = useState()

  const [plantType, setPlantType] = useState("")
  const [plantForm, setPlantForm] = useState("")

  const changePage = useCallback(
    (param) => {
      let newParam = {
        ...search,
        ...param,
        plant_type_id: plantType,
        item_shape: plantForm
      }
      if (param.sortField) {
        newParam = {
          ...newParam,
          sort: param.sortField + "," + param.sortOrder
        }
      }

      dispatch(getCategoryItemList(subType.toUpperCase(), { pageReq: newParam }))
    },
    [dispatch, search, initialValues]
  )

  useEffect(() => {
    dispatch(
      getCategoryItemList(subType.toUpperCase(), {
        pageReq: initialFilterTable
      })
    )
  }, [])

  useEffect(() => {
    subHeader.setTitle(t("sub_header.fertilizer"))
    subHeader.setBreadcrumbs([
      {
        pathname: RoutesConstants.MASTER_DATA.CATEGORY_ITEM.PAGE(),
        title: t("sub_header.category_item")
      },
      {
        title: t("sub_header.fertilizer")
      }
    ])
    if (privileges.indexOf(PRIVILEGE_ENUM.MODIFIED_CATEGORY_ITEM) > -1) {
      subHeader.setButton(
        <Button
          variant="primary"
          onClick={() => {
            history.push(
              RoutesConstants.MASTER_DATA.CATEGORY_ITEM.NEW_ITEM(
                MASTER_DATA_CATEGORY_ITEM_STRING.FERTILIZER
              )
            )
          }}>
          {t("button.add")}
        </Button>
      )
    } else {
      subHeader.setButton(null)
    }
  }, [])

  const uiDate = (e) => {
    return (
      <>
        <div className="mb-1">{e?.submitted_date ? e?.submitted_date : "-"}</div>
        <div>{e?.submitted_time ? e?.submitted_time : "-"}</div>
      </>
    )
  }
  const uiName = (e) => {
    return (
      <>
        <div className="">{e?.name ? e?.name : "-"}</div>
      </>
    )
  }
  const uiType = (e) => {
    return (
      <>
        <div className="">{e?.plant_type_name ? e?.plant_type_name : "-"}</div>
      </>
    )
  }
  const uiUnit = (e) => {
    return (
      <>
        <div className="">{e?.unit_name ? e?.unit_name : "-"}</div>
      </>
    )
  }
  const uiBentuk = (e) => {
    return (
      <>
        <div className="">{e?.shape_name ? e?.shape_name : "-"}</div>
      </>
    )
  }

  const columns = [
    {
      dataField: "name",
      text: t("label.variant_name").toUpperCase(),
      formatter: formatterRowComponent,
      formatExtraData: {
        externalStyle: uiName
      }
    },
    {
      dataField: "plantType",
      text: t("label.plant_type").toUpperCase(),
      formatter: formatterRowComponent,
      sortCaret: sortCaret,
      formatExtraData: {
        externalStyle: uiType
      },
      sort: true
    },
    {
      dataField: "bentuk",
      text: t("label.plant_form").toUpperCase(),
      formatter: formatterRowComponent,
      formatExtraData: {
        externalStyle: uiBentuk
      }
    },
    {
      dataField: "unit",
      text: "UNIT",
      formatter: formatterRowComponent,
      formatExtraData: {
        externalStyle: uiUnit
      }
    },
    {
      dataField: "createdDate",
      text: t("label.created_date").toUpperCase(),
      formatter: formatterRowComponent,
      formatExtraData: {
        externalStyle: uiDate
      },
      headerStyle: () => {
        return { width: "15%" }
      },
      sortCaret: sortCaret,
      sort: true
    }
  ]

  const trigerReset = () => {
    setSearch({
      string_filter: ""
    })
    let param = {
      ...initialFilterTable,
      ...initialValues,
      string_filter: ""
    }
    dispatch(getCategoryItemList(subType.toUpperCase(), { pageReq: param }))
  }

  const onSubmitForm = (values) => {
    let param = {
      ...initialFilterTable,
      ...initialValues,
      string_filter: values.string_filter,
      plant_type_id: plantType,
      item_shape: plantForm
    }

    setSearch({
      string_filter: values.string_filter
    })
    dispatch(getCategoryItemList(subType.toUpperCase(), { pageReq: param }))
  }

  const onChangePlantType = (e) => {
    setPlantType(e)
  }

  const onChangePlantForm = (e) => {
    setPlantForm(e)
  }

  const onSubmiFilter = (e) => {
    const { values, submited } = e
    setFilterOn(true)
    setShowFilter(false)
    if (submited === true) {
      let param = {
        ...initialFilterTable,
        ...search,
        plant_type_id: values.plant_type_id,
        item_shape: values.item_shape
      }
      if (param) {
        setInitialValues(param)
        dispatch(getCategoryItemList(subType.toUpperCase(), { pageReq: param }))
      }
    }
  }

  const filterOnReset = () => {
    setFilterOn(false)

    setPlantType("")
    setPlantForm("")
    setShowFilter(false)
    setFilterOn(false)

    let param = {
      ...initialFilterTable,
      ...search,
      plant_type_id: "",
      item_shape: ""
    }
    dispatch(getCategoryItemList(subType.toUpperCase(), { pageReq: param }))
  }

  const handleOpenCloseFilter = (value) => {
    setShowFilter(value)
  }

  useEffect(() => {
    dispatch(getPlantType())
    dispatch(getPlantForm())
  }, [])

  useEffect(() => {
    if (MasterData.plantType) {
      setStatePlantType(
        MasterData.plantType.map((value) => ({ label: value.name, value: value.id }))
      )
    }
  }, [MasterData.plantType])

  useEffect(() => {
    if (MasterData.plantForm) {
      setStatePlantForm(
        MasterData.plantForm.map((value) => ({ label: value.enum_value, value: value.enum_name }))
      )
    }
  }, [MasterData.plantForm])
  const filterComponent = (
    <>
      <Card className="card-custom card-shadowless">
        <CardHeader title={t("label.plant_type")} className="px-0" />
        <CardBody className="px-0">
          <RowModule>
            <SelectInput
              name="plant_type_id"
              onChange={onChangePlantType}
              editable={!isReadOnly}
              label={t("label.plant_type")}
              placeholder={t("placeholder.select_plant_type")}
              options={statePlantType}
              withoutFeedback={true}
              clearNow={true}
              value={plantType}
            />
          </RowModule>
        </CardBody>
      </Card>
      <Card className="card-custom card-shadowless">
        <CardHeader title={t("card_title.fertilizer_form")} className="px-0" />
        <CardBody className="px-0">
          <RowModule>
            <SelectInput
              name="item_shape"
              onChange={onChangePlantForm}
              editable={!isReadOnly}
              label={t("label.plant_form")}
              placeholder={t("placeholder.select_plant_type")}
              options={statePlantForm}
              withoutFeedback={true}
              clearNow={true}
              value={plantForm}
            />
          </RowModule>
        </CardBody>
      </Card>
    </>
  )

  return (
    <>
      <FilterCard
        // search
        placeholder={t("placeholder.insert_variant_name")}
        onSubmitForm={onSubmitForm}
        initialValues={search}
        name={"string_filter"}
        innerRef={formRef}
        trigerReset={trigerReset}
        // Filter
        filterInnerRef={formRef}
        filterHandleSubmit={onSubmiFilter}
        filterFormInitialValues={initialValues}
        filterComponent={filterComponent}
        filterOnReset={filterOnReset}
        showFilter={showFilter}
        handleOpenCloseFilter={handleOpenCloseFilter}
        filterOn={filterOn}
      />

      <Card>
        <RowModule>
          <Card className="card-shadowless">
            <CardBody>
              {General.loading ? (
                <TableSkeleton column={5} row={10} />
              ) : MasterData?.pageData?.length === 0 ? (
                <EmptyCard
                  body={
                    search?.string_filter?.length > 0
                      ? t("messages.response.data_not_found")
                      : t("messages.response.empty_state")
                  }
                />
              ) : (
                ""
              )}
              <TableView
                callbackAfterPageChange={changePage}
                columnProperties={columns}
                dataTable={MasterData.pageData}
                currentPage={MasterData.page.currentPage}
                currentElement={MasterData.page.currentElement}
                totalCount={MasterData.page.totalCount}
                loadingGetData={General.loading}
                rowClasses={() => {
                  return "cursor-default"
                }}
              />
            </CardBody>
          </Card>
        </RowModule>
      </Card>
    </>
  )
}