import React, { useEffect } from "react"
import { loadUserLogin } from "../../../../service/userManagementAction.js"
import { useLocation } from "react-router"
import { useSubheader } from "../../../../../_metronic/layout/index.js"
import { Card, OverlayTrigger, Tooltip } from "react-bootstrap"
import { DetailSvgIcon, EditSvgIcon } from "../../../../../component/atoms/Icons/SVG/index.jsx"
import { formatterRowComponent } from "../../../../../component/atoms/Tabel/index.jsx"
import { sortCaret } from "../../../../../_metronic/_helpers/index.js"
import { getUrlParsingPageFilter } from "../../../../../config/EndpointCollection.js"
import { CardBody } from "../../../../../_metronic/_partials/controls/index.js"
import { EmptyCard, TableSkeleton, TableView } from "../../../../../component/index.jsx"
import { RoutesConstants } from "../../../../../constants/RoutesConstants.js"
import { linkWithCtrl } from "../../../../helpers/Utils.js"
import { useSelector } from "react-redux"
import { useTranslation } from "react-i18next"
import { ILLUSTRATION_ASSETS_V2 } from "../../../../../constants/ImageConstants.js"
import { PRIVILEGE_ENUM } from "../../../../../constants/PrivilegeConstants.js"
import { AREA_STATUS_STRING } from "../../../../../constants/StringConstant.js"

export const Table = ({
  data,
  changePage,
  history,
  general,
  initialFilterTable,
  isFilter,
  isActiveSearch
}) => {
  const privileges = loadUserLogin().privilege
  const location = useLocation()
  const subheader = useSubheader()
  const { t } = useTranslation()
  const { General } = useSelector((state) => state)

  useEffect(() => {
    subheader.setButton(undefined)
  }, [])

  const uiName = (e) => {
    return (
      <>
        <a className="text-dark font-weight-bolder mb-1 font-size-lg">{e.name}</a>
        {privileges.indexOf(PRIVILEGE_ENUM.FARMER_PHONE_NUMBER) > -1 && (
          <span className="text-muted font-weight-bold d-block">{e.phone}</span>
        )}
      </>
    )
  }

  const uiMitra = (e) => {
    return (
      <>
        <a className="text-dark font-weight-bolder mb-1 font-size-lg">{e.mitra_name}</a>
        <span className="text-muted font-weight-bold d-block">{e.mitra_code}</span>
      </>
    )
  }

  const uiCode = (e) => {
    return (
      <>
        <a className="text-dark font-weight-bolder mb-1 font-size-lg">{e.code}</a>
      </>
    )
  }

  const uiAction = (e) => {
    return (
      <div>
        {privileges.indexOf(PRIVILEGE_ENUM.DETAIL_FARMER_REGISTERED) > -1 && (
          <OverlayTrigger overlay={<Tooltip id="products-edit-tooltip">Detail</Tooltip>}>
            <div
              className="btn btn-icon btn-detail btn-hover-secondary btn-sm mx-3"
              onClick={() => history.push(RoutesConstants.FARMER.DETAIL_FARMER(AREA_STATUS_STRING.ACTIVE, e.id))}>
              <DetailSvgIcon />
            </div>
          </OverlayTrigger>
        )}
        {privileges.indexOf(PRIVILEGE_ENUM.UPDATE_FARMER) > -1 && (
          <OverlayTrigger overlay={<Tooltip id="products-edit-tooltip">Edit</Tooltip>}>
            <div
              className="btn btn-icon btn-detail btn-hover-secondary btn-sm mx-3"
              onClick={() => history.push(RoutesConstants.FARMER.EDIT_FARMER_STATUS(AREA_STATUS_STRING.ACTIVE, e.id))}>
              <EditSvgIcon />
            </div>
          </OverlayTrigger>
        )}
      </div>
    )
  }

  const columns = [
    {
      dataField: "code",
      text: t("table_header.farmer_id").toUpperCase(),
      formatter: formatterRowComponent,
      formatExtraData: {
        externalStyle: uiCode
      },
      sortCaret: sortCaret,
      sort: false
    },
    {
      dataField: "name",
      text: t("label.name").toUpperCase(),
      formatter: formatterRowComponent,
      formatExtraData: {
        externalStyle: uiName
      },
      sortCaret: sortCaret,
      sort: true
    },

    {
      dataField: "account.mitra.name",
      text: "MITRA",
      formatter: formatterRowComponent,
      formatExtraData: {
        externalStyle: uiMitra
      },
      sortCaret: sortCaret,
      sort: true,
      onSort: (field, order) => {
        let param = getUrlParsingPageFilter({
          ...initialFilterTable,
          sort: field + "," + order
        })
        history.push({ pathname: location.pathname, search: param })
      }
    },
    {
      dataField: "address",
      text: t("label.address").toUpperCase(),
      sort: false,
      headerStyle: () => {
        return { width: "25%" }
      }
    },
    {
      dataField: "action",
      text: "ACTION",
      sort: false,
      formatter: formatterRowComponent,
      formatExtraData: {
        externalStyle: uiAction
      }
    }
  ]

  return (
    <>
      <Card className="card-shadowless">
        <CardBody>
          {General.loading ? (
            <TableSkeleton column={7} row={10} />
          ) : data?.pageData.length == 0 ? (
            <EmptyCard
              subtitle={isActiveSearch ? t("messages.response.empty_search_subtitle") : ""}
              image={
                isFilter || isActiveSearch
                  ? ILLUSTRATION_ASSETS_V2.farmer.IL_EMPTY_STATE_NO_DATA
                  : ILLUSTRATION_ASSETS_V2.farmer.IL_EMPTY_STATE
              }
              body={
                isFilter || isActiveSearch
                  ? t("messages.response.searching_not_found")
                  : t("messages.response.empty_state", { name: "petani" })
              }
            />
          ) : (
            ""
          )}
          <TableView
            callbackAfterPageChange={changePage}
            columnProperties={columns}
            dataTable={data?.pageData ?? []}
            currentPage={data?.page?.currentPage ?? 1}
            currentElement={data?.page?.currentElement ?? 10}
            totalCount={data?.page?.totalCount ?? 0}
            loadingGetData={general.loading}
            ignoreSinglePage
            rowClasses={() => {
              return "cursor-pointer"
            }}
            rowEvents={{
              onClick: (e, row) => {
                if (privileges.indexOf(PRIVILEGE_ENUM.DETAIL_FARMER_REGISTERED) > 1) {
                  if (e.target.cellIndex < 5) {
                    const url = RoutesConstants.FARMER.DETAIL_FARMER(AREA_STATUS_STRING.ACTIVE, row.id)
                    if (e.ctrlKey) {
                      linkWithCtrl(url)
                    } else {
                      history.push(url)
                    }
                  }
                }
              }
            }}
          />
        </CardBody>
      </Card>
    </>
  )
}