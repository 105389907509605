import React, { useEffect, useState } from "react"
import { BadgeStatus, CardDetail } from "../../../../../component/index.jsx"
import {
  FlatIconAndText,
  FlatIconAndText2Row
} from "../../../../../component/atoms/FlaticonAndText/index.jsx"
import { Accordion } from "../../../../../component/atoms/Expands/accordion.jsx"
import { Badge, Card } from "react-bootstrap"
import { CardBody } from "../../../../../_metronic/_partials/controls/index.js"
import SVG from "react-inlinesvg"
import { useDispatch, useSelector } from "react-redux"
import { getAreas } from "../../../../../redux/actions/FarmerAction.jsx"
import { Skeleton } from "@material-ui/lab"
import { ICON_CONSTANS, ILLUSTRATION_ASSETS_V2 } from "../../../../../constants/ImageConstants.js"
import { useTranslation } from "react-i18next"

export const AreaTab = ({ id }) => {
  const { Farmer, General } = useSelector((state) => state)
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const [listArea, setListArea] = useState([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setLoading(General.loading)
  }, [General.loading])
  useEffect(() => {
    dispatch(getAreas(id))
  }, [])

  useEffect(() => {
    setListArea(Farmer.areas)
  }, [Farmer.areas])

  const descriptionLeftArea = (data) => {
    return (
      <>
        <div className="d-flex flex-wrap mb-4">
          <FlatIconAndText
            text={data.land_area != null ? data.land_area + " m2" : "-"}
            svg={ICON_CONSTANS.IC_FIELD}
          />
          <FlatIconAndText
            text={
              data.plant_namelist_comma_separated != null
                ? data.plant_namelist_comma_separated + " - " + data.plant_varieties_name
                : "-"
            }
            svg={ICON_CONSTANS.IC_PADDY}
          />
        </div>
        {General.loading ? (
          <>
            <Skeleton animation="wave" variant={"text"} width={100} className={"mb-2"} />
            <Skeleton animation="wave" variant={"text"} width={100} className={"mb-2"} />
          </>
        ) : (
          <>
            <span className="font-weight-bold text-dark-50">
              {data?.address ?? "-"} - {data?.subdistrict_name ?? ""} - {data?.district_name ?? ""}
            </span>
            <span className="font-weight-bold text-dark-50">
              {data?.regency_name ?? ""} - {data?.province_name ?? ""}
            </span>
          </>
        )}
      </>
    )
  }

  const descriptionRightArea = (data) => {
    return (
      <>
        {data?.is_hst && (
          <div className="d-flex flex-wrap justify-content-around mb-1">
            <div>
              <div className="font-weight-bold mb-1">{t("label.planting_date")}</div>
              <Badge variant="primary">{data?.planting_date ? data?.planting_date : "-"}</Badge>
            </div>
            <div className="ml-10">
              <div className="font-weight-bold mb-1">{t("label.harvest_estimation")}</div>
              <Badge variant="danger">{data?.harvest_date ? data?.harvest_date : "-"}</Badge>
            </div>
          </div>
        )}
      </>
    )
  }

  const flatIconAndText2RowsArea = (data) => {
    return (
      <>
        {data?.is_hst ? (
          <>
            <FlatIconAndText2Row
              title={t("label.pending_activity")}
              description={data?.pending_activity ?? "0"}
              svg={ICON_CONSTANS.IC_CALENDAR_DATE}
            />
            <FlatIconAndText2Row
              title={t("label.finish_activity")}
              description={data?.finish_activity ?? "0"}
              svg={ICON_CONSTANS.IC_CALENDAR_DATE}
            />
            <FlatIconAndText2Row
              title={t("label.next_activity")}
              description={data?.next_activity ?? "-"}
              svg={ICON_CONSTANS.IC_CALENDAR_DATE}
            />
          </>
        ) : null}
      </>
    )
  }

  const emptyArea = () => (
    <>
      <Card>
        <CardBody className="py-20 text-center">
          <SVG src={ILLUSTRATION_ASSETS_V2.farmer.IL_EMPTY_STATE_NO_DATA_AREA} />
          <h4 className="mt-12">{t("messages.response.empty_area_farmer")}</h4>
        </CardBody>
      </Card>
    </>
  )

  return (
    <>
      {listArea.length > 0 ? (
        <Accordion
          items={listArea.map((e) => ({
            header: loading ? (
              <Skeleton animation="wave" variant={"rect"} width={100} />
            ) : (
              <div className="d-flex align-items-center">
                <h6 className="mb-0 pr-3">{e?.name != null ? e?.name : "Unamed"}</h6>
                <BadgeStatus
                  variant={e?.is_hst ? "primary" : "secondary"}
                  status={e?.is_hst ? "ACTIVE" : "INACTIVE"}
                />
              </div>
            ),
            content: (
              <CardDetail
                titleCardDetail={e?.name}
                descriptionLeft={descriptionLeftArea(e)}
                descriptionRight={descriptionRightArea(e)}
                flatIconAndText2Rows={flatIconAndText2RowsArea(e)}
              />
            )
          }))}
        />
      ) : (
        emptyArea()
      )}
    </>
  )
}