import { useDispatch, useSelector } from "react-redux"
import { Card, CardBody, CardHeader } from "../../../../../../_metronic/_partials/controls/index.js"
import { RowModule } from "../../../../../../component/atoms/RowModule/RowModule.jsx"
import { SelectInput, TextInput } from "../../../../../../component/index.jsx"
import React, { useEffect, useState } from "react"
import { fetchBanks } from "../../../../../../redux/actions/MasterDataAction.jsx"
import { ErrorMessage, useFormikContext } from "formik"
import { useTranslation } from "react-i18next"

export const AkunBank = ({ isReadOnly, setDisableBtn }) => {
  const { values: formValues } = useFormikContext()
  const { MasterData } = useSelector((state) => state)
  const {t} = useTranslation()

  const dispatch = useDispatch()

  const [farmerBankId, setFarmerBankId] = useState(null)
  const [bankList, setBankList] = useState([])

  const renderError = (message) => (
    <p className="help is-danger">
      <b>{message}</b>
    </p>
  )

  useEffect(() => {
    dispatch(fetchBanks())
  }, [])

  useEffect(() => {
    if (MasterData.bankList.length !== 0) {
      setBankList(
        MasterData.bankList.map((value) => ({
          label: value.name,
          value: value.id
        }))
      )
    }
  }, [MasterData.bankList])

  function onChangeBank(e) {
    setFarmerBankId(e)
  }

  useEffect(() => {
    if (
      formValues?.farmerBankId &&
      (!formValues?.farmerBankAccountName || !formValues?.farmerBankAccountNumber)
    ) {
      setDisableBtn(false)
    } else if (
      !formValues?.farmerBankId &&
      !formValues?.farmerBankAccountName &&
      !formValues?.farmerBankAccountNumber
    ) {
      setDisableBtn(true)
    } else {
      setDisableBtn(true)
    }
  }, [formValues])

  return (
    <Card>
      <CardHeader title={t("card_title.farmer_bank_account").toUpperCase()} />
      <CardBody>
        <RowModule>
          <b>{t("messages.confirmation.farmer_bank_account_warning")}</b>
        </RowModule>
        <RowModule>
          <>
            <SelectInput
              name="farmerBankId"
              onChange={onChangeBank}
              editable={!isReadOnly}
              label={t("label.bank_name")}
              placeholder={t("label.choose_one")}
              value={farmerBankId}
              options={bankList}
            />

            <ErrorMessage name="farmerBankId" render={renderError} />
          </>

          <>
            <TextInput
              editable={!isReadOnly}
              value={!formValues?.farmerBankId && ""}
              disabled={formValues?.farmerBankId ? false : true}
              label={t("label.account_number")}
              name="farmerBankAccountNumber"
              placeholder={t("placeholder.input_account_number")}
            />
          </>
        </RowModule>

        <RowModule>
          <>
            <TextInput
              editable={!isReadOnly}
              value={!formValues?.farmerBankId && ""}
              disabled={formValues?.farmerBankId ? false : true}
              label={t("label.account_name")}
              name="farmerBankAccountName"
              placeholder={t("placeholder.input_account_name")}
            />
          </>
        </RowModule>
      </CardBody>
    </Card>
  )
}