import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useFormikContext } from "formik"
import {
  getListRegionalAll,
  resetUrlResponse,
  uploadAction
} from "../../../../../redux/actions/MasterDataAction.jsx"
import { getUserRegional } from "../../../../service/userManagementAction.js"
import { UploadIcon } from "../../../../../component/atoms/Icons/SVG/upload.jsx"
import { UPLOAD } from "../../../../../constants/InitTypeConstants.js"
import {
  Card,
  CardBody,
  CardHeaderTitle
} from "../../../../../_metronic/_partials/controls/index.js"
import { RowModule } from "../../../../../component/atoms/RowModule/RowModule.jsx"
import {
  AddressMolecule,
  RadioButtonGroup,
  SelectInput,
  TextInput,
  UploadComponent
} from "../../../../../component/index.jsx"
import { handleSpace } from "../../../../helpers/TextHelper.js"
import { useTranslation } from "react-i18next"
import { GENDER_TYPE } from "../../../../../constants/StringConstant.js"

export const DataMitraIndividual = ({ mitraTypeEdit, isReadOnly, mitraType, changeType }) => {
  const { MasterData } = useSelector((state) => state)
  const dispatch = useDispatch()
  const [avatar, setAvatar] = useState("")
  const { values: formValues } = useFormikContext()
  const [gender, setGender] = useState("")
  const {t} = useTranslation()

  const [regionalList, setRegionalList] = useState([])
  const [regionalId, setRegionalId] = useState(null)
  const [isHaveRegional, setIsHaveRegional] = useState(false)
  const [isChangeRegional, setIsChangeRegional] = useState(false)

  useEffect(() => {
    const userRegional = getUserRegional()
    if (userRegional !== "null") {
      formValues["regionalId"] = userRegional
      setRegionalId(userRegional)
      setIsHaveRegional(true)
    }
  }, [regionalList])

  useEffect(() => {
    dispatch(getListRegionalAll())
  }, [dispatch])

  useEffect(() => {
    if (MasterData.listRegionalAll != undefined) {
      setRegionalList(
        MasterData.listRegionalAll.map((value) => ({
          label: value.name + " (" + value.code + ")",
          value: value.id
        }))
      )
    }
  }, [MasterData.listRegionalAll])

  function onChangeRegional(e) {
    setRegionalId(e)
    setIsChangeRegional(true)
  }

  function onChangeAvatar(e) {
    dispatch(uploadAction({ data: e, type: UPLOAD.AVATAR }))
  }

  useEffect(() => {
    if (formValues["mitra_type"]) {
      changeType(formValues)
    }
  }, [formValues])

  useEffect(() => {
    if (MasterData.urlResponseUpload) {
      if (MasterData.uploadType === UPLOAD.AVATAR) {
        setAvatar(MasterData.urlResponseUpload)
      }
    }
    return () => {
      dispatch(resetUrlResponse())
    }
  }, [MasterData.urlResponseUpload])

  const placeholder = <UploadIcon />

  return (
    <>
      <Card className="card-custom card-shadowless">
        <CardBody>
          <RowModule>
            <CardHeaderTitle className={"my-12"}>{"list mitra".toUpperCase()}</CardHeaderTitle>
          </RowModule>
          <RowModule>
            <SelectInput
              required={true}
              name="mitra_type"
              editable={!mitraTypeEdit}
              label={t("label.select_mitra_type")}
              placeholder={t("placeholder.select_one")}
              options={mitraType}
              withoutFeedback={true}
              clearNow={true}
              value={formValues["mitra_type"]}
            />
          </RowModule>
          <RowModule>
            <SelectInput
              name="regionalId"
              required
              onChange={onChangeRegional}
              editable={isHaveRegional ? false : true}
              label={t("label.regional")}
              placeholder={t("label.choose_one")}
              value={regionalId}
              options={regionalList}
            />
          </RowModule>
          <RowModule>
            <UploadComponent
              urlParsing={avatar}
              name="mitra_pic_url"
              required
              label={t("label.photo_profile")}
              editable={!isReadOnly}
              onChange={onChangeAvatar}
              placeholder={placeholder}
              maxSize={2048000}
              maxSizeText="2 MB"
            />
          </RowModule>
          <RowModule>
            <TextInput
              editable={!isReadOnly}
              label={t("label.mitra_name")}
              name="name"
              required
              placeholder={t("placeholder.input_mitra_name")}
              onKeyDown={handleSpace}
            />
          </RowModule>
          <RowModule>
            <TextInput
              editable={!isReadOnly}
              label="Email"
              required
              name="email"
              placeholder="Input email"
            />
          </RowModule>
          <RowModule>
            <TextInput
              editable={!isReadOnly}
              type="number"
              label={t("label.phone_number")}
              required
              name="phone"
              placeholder={t("placeholder.input_no_hp")}
            />
          </RowModule>
          <RowModule>
            <TextInput
              editable={!isReadOnly}
              label={t("label.date_of_birth")}
              required
              name="dob"
              type="date"
              placeholder={"mm-dd-yyyy"}
            />
          </RowModule>
          <label>
            {t("label.gender")} <span className={"text-danger"}>*</span>
          </label>
          <RowModule withoutSeparator={true}>
            <RadioButtonGroup
              label={t("label.men")}
              name="gender"
              value={GENDER_TYPE.LAKI_LAKI.replace("-", "_")}
              required
              editable={true}
              withoutFeedback={true}
              checked={gender === GENDER_TYPE.LAKI_LAKI.replace("-", "_")}
              chooseAtBack={true}
              onClick={() => setGender(GENDER_TYPE.LAKI_LAKI.replace("-", "_"))}
            />
            <RadioButtonGroup
              label={t("label.woman")}
              name="gender"
              value={GENDER_TYPE.PEREMPUAN}
              required
              editable={true}
              withoutFeedback={true}
              checked={gender === GENDER_TYPE.PEREMPUAN}
              chooseAtBack={true}
              onClick={() => setGender(GENDER_TYPE.PEREMPUAN)}
            />
          </RowModule>
        </CardBody>
      </Card>
      <AddressMolecule
        name={"mitra"}
        isReadOnly={isReadOnly}
        required
        addressTitle={t("label.mitra_domicile_address").toUpperCase()}
        regionalId={regionalId}
        byRegional={true}
        isChangeRegional={isChangeRegional}
      />
    </>
  )
}