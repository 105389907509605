import React, { useEffect, useState } from "react"
import { Card, CardBody, CardHeader } from "../../../../../../_metronic/_partials/controls/index.js"
import { RadioButtonGroup, TextInput } from "../../../../../../component/index.jsx"
import { RowModule } from "../../../../../../component/atoms/RowModule/RowModule.jsx"
import { useDispatch, useSelector } from "react-redux"
import { fetchCategoryVideos } from "../../../../../../redux/actions/MasterDataAction.jsx"
import ReactPlayer from "react-player/youtube"
import { useTranslation } from "react-i18next"

export const DetailInputVideo = ({
  isReadOnly,
  categoryId,
  changeCategory,
  actionDetail,
  linkEdit
}) => {
  const { MasterData } = useSelector((state) => state)
  const dispatch = useDispatch()
  const {t} = useTranslation()
  const [categoryList, setCategoryList] = useState([])
  const [category, setCategory] = useState(categoryId)
  const [showPreview, setShowPreview] = useState(actionDetail === "edit")
  const [link, setLink] = useState(null)

  useEffect(() => {
    if (MasterData.categoryVideoList.length > 0) {
      setCategoryList(MasterData.categoryVideoList)
    }
  }, [MasterData.categoryVideoList])

  useEffect(() => {
    dispatch(fetchCategoryVideos())
  }, [dispatch])

  useEffect(() => {
    setCategory(categoryId)
  }, [categoryId])

  useEffect(() => {
    setShowPreview(actionDetail === "edit")
  }, [actionDetail])

  useEffect(() => {
    if (linkEdit && actionDetail === "edit") {
      setLink(linkEdit)
    }
  }, [linkEdit])

  return (
    <>
      <Card className="card-custom card-shadowless">
        <CardHeader title="DETAIL VIDEO" />
        <CardBody>
          <RowModule>
            <TextInput
              editable={!isReadOnly}
              label="Youtube Video Link*"
              name="url"
              placeholder={t("placeholder.input_youtube_video_link")}
              onKeyUp={(e) => {
                setLink(e.target.value)
                if (e.target.value) {
                  setShowPreview(true)
                } else {
                  setShowPreview(false)
                }
              }}
            />
          </RowModule>
          <RowModule>
            <TextInput
              editable={!isReadOnly}
              label={`${t("label.video_title")}*`}
              name="title"
              placeholder={t("placeholder.input_youtube_video_title")}
            />
          </RowModule>
          <label>{t("label.video_category")}*</label>
          <RowModule customColumnCss="col-lg-6 col-md-6 col-sm-6 mb-2">
            {categoryList?.length > 0 &&
              categoryList?.map((item, idx) => (
                <RadioButtonGroup
                  key={idx}
                  label={item}
                  name="category"
                  value={item}
                  editable={true}
                  withoutFeedback={true}
                  checked={category === item}
                  chooseAtBack={true}
                  onClick={(v) => {
                    if (v?.target?.checked) {
                      changeCategory(item)
                      setCategory(item)
                    }
                  }}
                />
              ))}
          </RowModule>
          {showPreview && ReactPlayer.canPlay(link) && (
            <RowModule>
              <ReactPlayer url={link} width="100%" />
            </RowModule>
          )}
        </CardBody>
      </Card>
    </>
  )
}