import React, { Fragment, useEffect, useState } from "react"
import { useSubheader } from "../../../../../_metronic/layout/index.js"
import { useTranslation } from "react-i18next"
import { Button, Card, Form } from "react-bootstrap"
import { ORDER_STATUS_ENUM, ROUTING_STATUS } from "../../../../../constants/StringConstant.js"
import { RoutesConstants } from "../../../../../constants/RoutesConstants.js"
import {
  ACTIVITY_ICONS,
  ICON_CONSTANS,
  ILUSTRATION_CONSTANS
} from "../../../../../constants/ImageConstants.js"
import { ActivityDetailHeader } from "../../../../../component/molecule/ActivityDetailHeader/ActivityDetailHeader.jsx"
import { ButtonDropdown, RowText, Status, TableView } from "../../../../../component/index.jsx"
import { RowModule } from "../../../../../component/atoms/RowModule/RowModule.jsx"
import { CardBody, CardHeader } from "../../../../../_metronic/_partials/controls/index.js"
import { timeStampToDate } from "../../../../helpers/DateHelper.js"
import {
  cancelScheduleSpraying,
  getDetailSprayingMac,
  rejectScheduleSpraying
} from "../../../../../redux/actions/MacAction.jsx"
import { useDispatch, useSelector } from "react-redux"
import { checkCommaInDataPic, toMeter } from "../../../../helpers/TextHelper.js"
import { Popup, PopupQuestion } from "../../../../../component/atoms/Popup/index.jsx"
import { formatterRowComponent } from "../../../../../component/atoms/Tabel/index.jsx"
import SVG from "react-inlinesvg"
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers/index.js"
import { clearFinishUpdateOrDelete } from "../../../../../redux/actions/MasterDataAction.jsx"
import { MAC } from "../../../../../constants/InitTypeConstants.js"
import { GetCancelEnumList } from "../../../../../redux/actions/OrderAction.jsx"
import { Skeleton } from "@material-ui/lab"

export const MacSprayingDetail = ({
  history,
  match: {
    params: { status, id }
  }
}) => {
  const subHeader = useSubheader()
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const { Order, Mac, General } = useSelector((state) => state)
  const [detail, setDetail] = useState({})
  const [dataPopUpPic, setDataPopUpPic] = useState([])
  const [openPopupTableList, setOpenPopupTableList] = useState(false)
  const [reasonReject, setReasonReject] = useState("")
  const [showPopupReject, setShowPopupReject] = useState(false)
  const [showPopupCancel, setShowPopupCancel] = useState(false)
  const [isDisableBtnReject, setIsDisableBtnReject] = useState(true)
  const [isDisableBtnCancel, setIsDisableBtnCancel] = useState(true)
  const [showModalSuccess, setShowModalSuccess] = useState(false)
  const [messageModal, setMessageModal] = useState("")
  const [listCancelEnum, setListCancelEnum] = useState([])
  const [selected, setSelected] = useState(new Set())
  const [reasonOtherString, setReasonOtherString] = useState(null)
  const [checkedSelectReason, setCheckedSelectReason] = useState([])

  const btnApprove = (
    <Button
      className={"btn btn-primary mx-6"}
      onClick={() =>
        history.push(
          RoutesConstants.MAC.MAC_SPRAYING_APPROVAL(ROUTING_STATUS.MAC_SPRAYING_STRING.PENDING, id)
        )
      }>
      {t("button.approve")}
    </Button>
  )
  const btnReject = (
    <Button variant="danger" onClick={() => setShowPopupReject(true)}>
      {t("button.reject")}
    </Button>
  )

  const listDropdown = [
    {
      title: "Edit Aktivitas",
      icon: ICON_CONSTANS.IC_EDIT,
      action: () =>
        history.push(
          RoutesConstants.MAC.MAC_SPRAYING_APPROVAL(ROUTING_STATUS.MAC_SPRAYING_STRING.APPROVED, id)
        ),
      showed: true
    },
    {
      title: "Batalkan Aktivitas",
      icon: ICON_CONSTANS.IC_SUSPEND,
      showed: true,
      action: () => setShowPopupCancel(true)
    }
  ]

  const listDropdownCancel = [
    {
      title: "Batalkan Aktivitas",
      icon: ICON_CONSTANS.IC_SUSPEND,
      showed: true,
      action: () => setShowPopupCancel(true)
    }
  ]

  const ButtonEditAndCancel = <ButtonDropdown title={"Action"} dropdownList={listDropdown} />
  const ButtonCancel = <ButtonDropdown title={"Action"} dropdownList={listDropdownCancel} />
  const ButtonApproveReschedule = (
    <Button
      className={"btn btn-primary mx-6"}
      onClick={() =>
        history.push(
          RoutesConstants.MAC.MAC_SPRAYING_APPROVAL(
            ROUTING_STATUS.MAC_SPRAYING_STRING.RESCHEDULED,
            id
          )
        )
      }>
      {t("button.approve")}
    </Button>
  )

  useEffect(() => {
    dispatch(getDetailSprayingMac(id))
    if (status === ROUTING_STATUS.MAC_SPRAYING_STRING.APPROVED) {
      dispatch(GetCancelEnumList())
    }
  }, [])

  useEffect(() => {
    if (Mac?.detail) {
      setDetail(Mac?.detail)
    }
  }, [Mac?.detail])

  useEffect(() => {
    if (Order?.cancelEnumList) {
      const data = [...Order.cancelEnumList].reverse()
      setListCancelEnum(data)
    }
  }, [Order])

  useEffect(() => {
    const data = Array.from(selected)
    const value = data.map((item) => {
      return item?.enum_value
    })
    setCheckedSelectReason(value)
  }, [selected])

  useEffect(() => {
    if (checkedSelectReason.length > 0) {
      if (checkedSelectReason.find((result) => result === "OTHER")) {
        if (reasonOtherString) {
          setIsDisableBtnCancel(false)
        } else {
          setIsDisableBtnCancel(true)
        }
      } else {
        setIsDisableBtnCancel(false)
      }
    } else {
      setIsDisableBtnCancel(true)
    }
  }, [checkedSelectReason, reasonOtherString])

  useEffect(() => {
    switch (status) {
      case ROUTING_STATUS.MAC_SPRAYING_STRING.PENDING:
        subHeader.setButton(
          <RowModule>
            <>
              {btnReject}
              {btnApprove}
            </>
          </RowModule>
        )
        break
      case ROUTING_STATUS.MAC_SPRAYING_STRING.APPROVED:
        subHeader.setButton(
          detail?.reschedule_detail?.reschedule_date ? ButtonCancel : ButtonEditAndCancel
        )
        break
      case ROUTING_STATUS.MAC_SPRAYING_STRING.OVERDUE:
        subHeader.setButton(
          detail?.reschedule_detail?.reschedule_date ? ButtonCancel : ButtonEditAndCancel
        )
        break
      case ROUTING_STATUS.MAC_SPRAYING_STRING.RESCHEDULED:
        subHeader.setButton(ButtonApproveReschedule)
        break
      default:
        subHeader.setButton(null)
    }

    subHeader.setBreadcrumbs([
      {
        pathname: RoutesConstants.MAC.LIST_PROFILE(),
        title: "MAC"
      },
      {
        pathname: RoutesConstants.MAC.LIST_SPRAYING(ROUTING_STATUS.MAC_SPRAYING_STRING.PENDING),
        title: "Penyemprotan"
      },
      {
        pathname: RoutesConstants.MAC.LIST_SPRAYING(status),
        title: detail?.status_string
      },
      {
        title: detail?.schedule_code ? detail?.schedule_code : ""
      }
    ])
  }, [status, detail])

  useEffect(() => {
    if (reasonReject.length > 0) {
      setIsDisableBtnReject(false)
    } else {
      setIsDisableBtnReject(true)
    }
  }, [reasonReject])

  useEffect(() => {
    if (General.isFinishUpdateOrDelete) {
      if (General.actionInitType === MAC.REJECT_SPRAYING) {
        setShowModalSuccess(true)
        setMessageModal(t("messages.response.order_rejected"))
        dispatch(clearFinishUpdateOrDelete())
      } else if (General.actionInitType === MAC.CANCEL_SPRAYING) {
        setShowModalSuccess(true)
        setMessageModal(t("messages.response.order_cancel"))
        dispatch(clearFinishUpdateOrDelete())
      }
    }
  }, [General.isFinishUpdateOrDelete])

  const bottomContent = (title, value) => (
    <div className={"mt-2 d-flex"} style={{ gap: 12 }}>
      <div className={""}>
        <span className={"text-black-50"}>{title} : </span>
        {value ?? "-"}
      </div>
    </div>
  )

  const titleActivity = (
    <ActivityDetailHeader
      title={detail?.schedule_code}
      icon={ACTIVITY_ICONS.IC_PENYEMPROTAN}
      rightContent={<Status variant={detail?.status_enum} text={detail?.status_string} />}
      bottomContent={
        detail?.status_enum === ORDER_STATUS_ENUM.PENDING
          ? bottomContent(
              t("label.created_date"),
              timeStampToDate(detail?.created_date, "dd-MM-yyyy HH:mm:ss")
            )
          : detail?.status_enum === ORDER_STATUS_ENUM.APPROVED ||
            detail?.status_enum === ORDER_STATUS_ENUM.CONFIRMED_FO
          ? bottomContent(t("label.scheduled_by"), detail?.approved_by)
          : detail?.status_enum === ORDER_STATUS_ENUM.REJECTED
          ? bottomContent(t("label.rejected_by"), detail?.rejected_by)
          : detail?.status_enum === ORDER_STATUS_ENUM.CANCELED
          ? bottomContent(t("label.canceled_by"), detail?.canceled_by)
          : ""
      }
    />
  )

  const onClickGoogleMap = (lat, lng) => {
    window.open("https://maps.google.com?q=" + lat + "," + lng)
  }
  const uiName = (e) => {
    return <div className={"font-weight-bolder"}>{e?.name ?? "-"}</div>
  }

  const uiAnggota = (e) => {
    return (
      <Fragment className={"font-weight-bolder"}>
        {e?.member.map((item, i) => {
          if (i === e?.member.length - 1) return item
          else return item + ", "
        })}
      </Fragment>
    )
  }
  const columnsPic = [
    {
      dataField: "name",
      text: t("label.team_name").toUpperCase(),
      formatter: formatterRowComponent,
      formatExtraData: {
        externalStyle: uiName
      },
      headerStyle: () => {
        return { width: "25%" }
      }
    },
    {
      dataField: "member",
      text: t("label.member").toUpperCase(),
      formatter: formatterRowComponent,
      formatExtraData: {
        externalStyle: uiAnggota
      }
    }
  ]

  const dataPic = () => {
    return (
      <>
        {detail?.activity_detail?.pic && (
          <div>
            {detail?.activity_detail?.pic?.map((item, i) => (
              <>
                {i < 2 && (
                  <Fragment>{`${item?.name} (${item?.member.map((mem, i) => {
                    if (i === item?.member?.length - 1) {
                      if (item?.member?.length === 1) return mem
                      else return " " + mem
                    } else return mem
                  })})${
                    checkCommaInDataPic(i, detail?.activity_detail?.pic?.length) ? "," : ""
                  } `}</Fragment>
                )}
              </>
            ))}
            {detail?.activity_detail?.pic?.length > 2 && (
              <span
                onClick={() => {
                  setOpenPopupTableList(true)
                  setDataPopUpPic(detail?.activity_detail?.pic)
                }}
                className={"text-success font-weight-bolder  underline-hover cursor-pointer"}
                style={{ fontSize: 12, textDecoration: "underline" }}>
                +{detail?.activity_detail?.pic?.length - 2} {t("label.lainnya")}
              </span>
            )}
          </div>
        )}
      </>
    )
  }

  const dataPicRescheduled = () => {
    return (
      <>
        {detail?.reschedule_detail?.pic?.length ? (
          <div>
            {detail?.reschedule_detail?.pic?.map((item, i) => (
              <>
                {i < 2 && (
                  <Fragment>{`${item?.name} (${item?.member}) ${
                    checkCommaInDataPic(i, detail?.reschedule_detail?.pic?.length) ? "," : ""
                  } `}</Fragment>
                )}
              </>
            ))}
            {detail?.reschedule_detail?.pic?.length > 2 && (
              <span
                onClick={() => {
                  setOpenPopupTableList(true)
                  setDataPopUpPic(detail?.reschedule_detail?.pic)
                }}
                className={"text-success font-weight-bolder  underline-hover cursor-pointer"}
                style={{ fontSize: 12, textDecoration: "underline" }}>
                +{detail?.reschedule_detail?.pic?.length - 2} {t("label.lainnya")}
              </span>
            )}
          </div>
        ) : (
          "-"
        )}
      </>
    )
  }

  const informasiUserMac = (
    <Card className={"mb-4"}>
      <CardBody>
        <h4 className={"mb-6 text-uppercase"}>
          {General.loading ? (
            <Skeleton animation="wave" variant={"text"} height={32} width={200} />
          ) : (
            t("label.mac_user_information")
          )}
        </h4>
        <RowModule customColumnCss={"col-md-6 mb-2"}>
          <RowText
            label={t("label.mac_id_user")}
            value={detail?.information_detail?.user_merchant_code ?? "-"}
          />
          <RowText label={t("label.phone")} value={detail?.information_detail?.phone ?? "-"} />
          <RowText
            label={t("label.mac_user_name")}
            value={detail?.information_detail?.user_merchant_name ?? "-"}
          />
          <RowText
            label={t("label.mac_full_address")}
            value={detail?.information_detail?.full_address ?? "-"}
            hasLink={true}
            handleClick={() =>
              onClickGoogleMap(detail?.information_detail?.lat, detail?.information_detail?.lon)
            }
          />
        </RowModule>
      </CardBody>
    </Card>
  )

  const dataScheduled = (
    <Card className={"mb-4"}>
      <CardBody>
        <h4 className={"mb-6 mt-6 text-uppercase"}>
          {General.loading ? (
            <Skeleton animation="wave" variant={"text"} height={32} width={200} />
          ) : (
            t("label.mac_data_scheduled")
          )}
        </h4>
        <RowModule customColumnCss={"col-md-6 mb-2"}>
          <RowText
            label={t("label.type_schedule")}
            value={detail?.request_detail?.request_type ?? "-"}
          />
          <RowText
            label={t("label.area_location")}
            value={detail?.request_detail?.full_address ?? "-"}
          />
          <RowText
            label={t("label.schedule_date")}
            value={
              detail?.request_detail?.request_activity_date
                ? timeStampToDate(detail?.request_detail?.request_activity_date, "dd MMMM yyyy")
                : "-"
            }
          />
          <RowText
            label={t("label.product")}
            value={
              detail?.request_detail?.product_name.length
                ? detail?.request_detail?.product_name.map((item, index) => {
                    if (detail?.request_detail?.product_name.length > 0) {
                      if (index === detail?.request_detail?.product_name.length - 1) return item
                      else return item + ", "
                    } else {
                      return item
                    }
                  })
                : "-"
            }
          />
          <RowText
            label={t("label.land_area_total")}
            value={toMeter(detail?.request_detail?.land_area) ?? "-"}
          />
          <RowText
            label={t("label.plant_type")}
            value={
              detail?.request_detail?.plant_type.length
                ? detail?.request_detail?.plant_type.map((item, index) => {
                    if (detail?.request_detail?.plant_type.length > 0) {
                      if (index === detail?.request_detail?.plant_type.length - 1) return item
                      else return item + ", "
                    } else {
                      return item
                    }
                  })
                : "-"
            }
          />
          <RowText
            label={t("label.cp_name")}
            value={detail?.request_detail?.cp_name ? detail?.request_detail?.cp_name : "-"}
          />
          <RowText
            label={t("label.note")}
            value={detail?.request_detail?.note ? detail?.request_detail?.note : "-"}
          />
          <RowText
            label={t("label.cp_phone")}
            value={detail?.request_detail?.cp_phone ? detail?.request_detail?.cp_phone : "-"}
          />
        </RowModule>
      </CardBody>
    </Card>
  )

  const dataActivity = (
    <Card className={"mb-4"}>
      <CardBody>
        <h4 className={"mb-6 mt-6 text-uppercase"}>
          {General.loading ? (
            <Skeleton animation="wave" variant={"text"} height={32} width={200} />
          ) : (
            t("label.mac_data_activity")
          )}
        </h4>
        <RowModule customColumnCss={"col-md-12 mb-2"}>
          <RowText
            label={
              detail?.reschedule_detail?.reschedule_date
                ? t("label.first_scheduled_date")
                : t("label.scheduled_date")
            }
            value={timeStampToDate(detail?.activity_detail?.schedule_date, "dd MMMM yyyy") ?? "-"}
          />
          <RowText
            label={
              detail?.reschedule_detail?.reschedule_date ? t("label.first_pic") : t("label.pic")
            }
            value={dataPic()}
          />
        </RowModule>
      </CardBody>
    </Card>
  )

  function bodyPopupTable() {
    return (
      <div className={"table_list_popup_schedule table_hidden_bottom"}>
        <TableView dataTable={dataPopUpPic} loadingGetData={false} columnProperties={columnsPic} />
      </div>
    )
  }

  const reason = (
    <RowModule>
      <Card className={"reject-list-card"}>
        <CardHeader
          title={
            General.loading ? (
              <Skeleton animation="wave" variant={"text"} height={32} width={200} />
            ) : detail?.status_enum === ORDER_STATUS_ENUM.REJECTED ? (
              t("label.reason_reject")
            ) : (
              t("label.reason_cancelation")
            )
          }
          className="mb-0 pb-2"
        />
        <CardBody>
          {!detail ? null : detail?.reject_cancel_reason?.length === 0 ? (
            <span>{t("messages.response.reason_not_found")}</span>
          ) : (
            <ul>
              {detail?.reject_cancel_reason?.map((e) => (
                <li key={e}>{e}</li>
              ))}
            </ul>
          )}
        </CardBody>
      </Card>
    </RowModule>
  )

  const dataRescheduled = (
    <Card className={"mb-4"}>
      <CardBody>
        <h4 className={"mb-6 mt-6 text-uppercase"}>
          {General.loading ? (
            <Skeleton animation="wave" variant={"text"} height={32} width={200} />
          ) : (
            t("label.mac_data_rescheduled")
          )}
        </h4>
        <RowModule customColumnCss={"col-md-12 mb-2"}>
          <RowText
            label={t("label.scheduled_date")}
            value={
              timeStampToDate(detail?.reschedule_detail?.reschedule_date, "dd MMMM yyyy") ?? "-"
            }
            secondLabel={detail?.reschedule_detail?.day_difference ?? 0}
            secondValue={"hari"}
          />
          <RowText label={t("label.pic")} value={dataPicRescheduled()} />
          <RowText
            label={t("label.rescheduled_reason")}
            value={detail?.reschedule_detail?.reason ? detail?.reschedule_detail?.reason : "-"}
          />
          <RowText label={t("label.supporting_photo")} images={detail?.reschedule_detail?.images} />
        </RowModule>
      </CardBody>
    </Card>
  )

  const rejectComponents = (
    <Form>
      <label className={"mb-4"}>
        {t("label.input_reason")}
        <span className={"ml-1"} style={{ color: "red" }}>
          *
        </span>
      </label>
      <textarea
        rows={3}
        className="form-control"
        value={reasonReject}
        onChange={(e) => {
          setReasonReject(e.target.value)
        }}
        placeholder={t("placeholder.input_reason")}
      />
    </Form>
  )

  const onChangeCheckBox = (value) => {
    const newSet = new Set(selected)
    if (newSet.has(value)) newSet.delete(value)
    else newSet.add(value)
    setSelected(newSet)
  }

  const cancelComponents = (
    <Form>
      {listCancelEnum && (
        <>
          {listCancelEnum.map((e, i) => (
            <Form.Check
              key={e?.enum_value}
              id={e?.enum_value}
              className={
                (i === 0 ? "mb-2" : i === listCancelEnum.length - 1 ? "mt-2" : "my-2") +
                " cursor-pointer"
              }
              type="checkbox"
              label={e?.enum_string}
              checked={
                checkedSelectReason.find((result) => result === e?.enum_value) === e?.enum_value
              }
              onChange={() => onChangeCheckBox(e)}
            />
          ))}
          <div className={"mt-4"}>
            {checkedSelectReason.find((result) => result === "OTHER") && (
              <textarea
                rows={3}
                className="form-control"
                value={reasonOtherString}
                onChange={(e) => {
                  setReasonOtherString(e.target.value)
                }}
                placeholder={t("placeholder.input_reason")}
              />
            )}
          </div>
        </>
      )}
    </Form>
  )

  const onRejectSchedule = () => {
    const data = {
      reason: reasonReject
    }
    dispatch(rejectScheduleSpraying(id, data))
  }

  const onCancelSchedule = () => {
    const data = {
      cancel_enum_list: checkedSelectReason,
      cancel_reason: reasonOtherString ?? null
    }
    dispatch(cancelScheduleSpraying(id, data))
  }

  return (
    <>
      {/*popup team*/}
      <Popup
        contentClassName={"modal-main modal_detail_team_customs"}
        show={openPopupTableList}
        titleCustom={<h1>Anggota Tim</h1>}
        closeButton={true}
        showButton={false}
        onClick={() => setOpenPopupTableList(false)}
        body={bodyPopupTable()}
        size={"lg"}
        onCancel={() => {
          setOpenPopupTableList(false)
        }}
      />
      {/*popup reject*/}
      <PopupQuestion
        show={showPopupReject}
        title={t("messages.title.reject_order")}
        textCancel={t("button.cancel")}
        textOk={t("button.reject")}
        onOk={onRejectSchedule}
        variantOkButton={isDisableBtnReject ? "primary" : "danger"}
        body={rejectComponents}
        disable={isDisableBtnReject}
        onCancel={() => {
          setShowPopupReject(false)
          setReasonReject("")
        }}
      />
      {/*popup cancel*/}
      <PopupQuestion
        show={showPopupCancel}
        title={t("messages.title.cancel_order")}
        textCancel={t("button.cancel")}
        textOk={t("button.submit")}
        onOk={() => {
          setShowPopupCancel(false)
          onCancelSchedule()
        }}
        variantOkButton={isDisableBtnReject ? "primary" : "danger"}
        body={cancelComponents}
        disable={isDisableBtnCancel}
        onCancel={() => {
          setShowPopupCancel(false)
          setSelected(new Set())
          setCheckedSelectReason([])
          setReasonOtherString("")
        }}
      />
      {/*popup success after reject or cancel*/}
      <PopupQuestion
        onCancel={() =>
          history.push(
            RoutesConstants.MAC.SPRAYING_DETAIL(
              detail?.status_enum === ORDER_STATUS_ENUM.PENDING
                ? ROUTING_STATUS.MAC_SPRAYING_STRING.REJECT
                : ROUTING_STATUS.MAC_SPRAYING_STRING.CANCEL,
              id
            )
          )
        }
        textCancel={t("button.see_detail")}
        textOk={t("button.oke")}
        onOk={() =>
          history.push(
            RoutesConstants.MAC.LIST_SPRAYING(
              detail?.status_enum === ORDER_STATUS_ENUM.PENDING
                ? ROUTING_STATUS.MAC_SPRAYING_STRING.PENDING
                : ROUTING_STATUS.MAC_SPRAYING_STRING.APPROVED
            )
          )
        }
        show={showModalSuccess}
        bodyCustom={
          <div className="d-flex flex-column align-items-center">
            <SVG src={toAbsoluteUrl(ILUSTRATION_CONSTANS.SUCCESS_PLACEHOLDER_ORDER)} />
            <p className={"swal-text text-center"}>{messageModal}</p>
          </div>
        }
      />

      {(detail?.status_enum === ORDER_STATUS_ENUM.REJECTED ||
        detail?.status_enum === ORDER_STATUS_ENUM.CANCELED) &&
        reason}

      {titleActivity}
      {informasiUserMac}
      {dataScheduled}
      {(detail?.status_enum === ORDER_STATUS_ENUM.APPROVED ||
        detail?.status_enum === ORDER_STATUS_ENUM.CANCELED ||
        detail?.status_enum === ORDER_STATUS_ENUM.OVERDUE ||
        detail?.status_enum === ORDER_STATUS_ENUM.RESCHEDULED ||
        detail?.status_enum === ORDER_STATUS_ENUM.CONFIRMED_FO) &&
        dataActivity}
      {detail?.reschedule_detail?.reschedule_date && dataRescheduled}
    </>
  )
}