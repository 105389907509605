import { shallowEqual, useDispatch, useSelector } from "react-redux"
import React, { useEffect, useState, useCallback } from "react"
import TableView, {
  formatterRowComponent,
  initialFilterTable
} from "../../../../../../component/atoms/Tabel/index.jsx"
import { OverlayTrigger, Tooltip } from "react-bootstrap"
import { EditSvgIcon } from "../../../../../../component/atoms/Icons/SVG/index.jsx"
import { Popup } from "../../../../../../component/atoms/Popup/index.jsx"
import { getListRoleFo } from "../../../../../../redux/actions/RoleAction.jsx"
import { loadUserLogin } from "../../../../../service/userManagementAction.js"
import { sortCaret } from "../../../../../../_metronic/_helpers/index.js"
import { TableSkeleton } from "../../../../../../component/index.jsx"
import { PRIVILEGE_ENUM } from "../../../../../../constants/PrivilegeConstants.js"
import { useTranslation } from "react-i18next"
import { RoutesConstants } from "../../../../../../constants/RoutesConstants.js"

export const FoList = ({ history }) => {
  const privileges = loadUserLogin().privilege
  const dispatch = useDispatch()
  const { General, Role } = useSelector((state) => state, shallowEqual)
  const [showPopupError, setShowPopupError] = useState(false)
  const {t} = useTranslation()

  useEffect(() => {
    dispatch(getListRoleFo({ pageReq: { ...initialFilterTable, param_filter: "fo" } }))
  }, [dispatch])

  const changePage = useCallback(
    (param) => {
      let newParam = {
        ...param,
        param_filter: "fo"
      }
      if (param.sortField) {
        newParam = {
          ...newParam,
          sort: param.sortField + "," + param.sortOrder
        }
      }
      dispatch(getListRoleFo({ pageReq: newParam }))
    },
    [dispatch]
  )

  const uiName = (e) => {
    return (
      <>
        <p className="text-dark font-weight-bolder mb-1 font-size-lg">{e?.display_name ?? "-"}</p>
      </>
    )
  }

  const uiAction = (e) => {
    return (
      <>
        {privileges.indexOf(PRIVILEGE_ENUM.EDIT_ROLE_PRIVILEGE) > -1 && (
          <OverlayTrigger overlay={<Tooltip id="products-edit-tooltip">Edit</Tooltip>}>
            <div
              className="btn btn-icon btn-detail btn-hover-secondary btn-sm mx-3"
              onClick={() => history.push(RoutesConstants.ROLE.SETTING_PRIVILAGE_FO(e.display_name, e.id))}>
              <EditSvgIcon />
            </div>
          </OverlayTrigger>
        )}
      </>
    )
  }

  const columns = [
    {
      dataField: "name",
      sortCaret: sortCaret,
      text: t("table_header.role_name"),
      sort: false,
      formatter: formatterRowComponent,
      formatExtraData: {
        externalStyle: uiName
      }
    },
    {
      dataField: "action",
      text: "Actions",
      formatter: formatterRowComponent,
      formatExtraData: {
        externalStyle: uiAction
      }
    }
  ]

  if (General?.loading) {
    return (
      <div className={"m-5"}>
        <TableSkeleton column={2} row={10} />
      </div>
    )
  } else {
    return (
      <>
        <div>
          <Popup
            type={"empty"}
            body={<span>{t("label.empty_data")}</span>}
            centered={true}
            persistent={true}
            show={showPopupError}
            onClick={() => {
              setShowPopupError(false)
            }}
          />
          <div className="m-5">
            <TableView
              callbackAfterPageChange={changePage}
              columnProperties={columns}
              dataTable={Role.listRoleFo}
              loadingGetData={General.loading}
              currentElement={0}
              currentPage={0}
              totalCount={0}
            />
          </div>
        </div>
      </>
    )
  }
}