import React, { useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { DataFarmer } from "./step/DataFarmer.jsx"
import { TabsInputComponent } from "../../../../../component/index.jsx"
import * as Yup from "yup"
import { KtpSwafotoFarmer } from "./step/KtpSwafotoFarmer.jsx"
import { AkunBank } from "./step/AkunBank.jsx"
import {
  FORM_NEED,
  getMaximumErrorString,
  getMinimumErrorString,
  getStringErrorMassage
} from "../../../../helpers/StringCollection.js"
import swal from "sweetalert"
import {
  createFarmer,
  getDetail,
  updateFarmer
} from "../../../../../redux/actions/FarmerAction.jsx"
import { DetailInputFarmer } from "./step/DetailInputFarmer.jsx"
import { fetchBanks, fetchProvince } from "../../../../../redux/actions/MasterDataAction.jsx"
import { EndpointRouter } from "../../../../model/EndpointRouter.jsx"
import { useSubheader } from "../../../../../_metronic/layout/index.js"
import { dateSelect, dateSelectReserve } from "../../../../helpers/DateHelper.js"
import { titleCase } from "../../../../helpers/TextHelper.js"
import { PopupQuestion } from "../../../../../component/atoms/Popup/index.jsx"
import { Col } from "react-bootstrap"
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers/index.js"
import { ILLUSTRATION_ASSETS_V2 } from "../../../../../constants/ImageConstants.js"
import { RoutesConstants } from "../../../../../constants/RoutesConstants.js"
import { ROUTING_STATUS } from "../../../../../constants/StringConstant.js"
import { useTranslation } from "react-i18next"
import { GENERAL } from "../../../../../constants/InitTypeConstants.js"

export const CreateEditFarmer = ({
  history,
  match: {
    params: { id, actionDetail }
  }
}) => {
  const [isSubmit, setIsSubmit] = useState(true)
  const {t} = useTranslation()
  const [stepsTitle, setStepsTitle] = useState([
    t("card_title.farmer_data"),
    t("label.ktp_selfie"),
    t("label.bank_account"),
    t("label.preview")
  ])
  const { Farmer, General } = useSelector((state) => state)
  const subHeader = useSubheader()
  const dispatch = useDispatch()
  const formRef = useRef()
  const [formData, setFormData] = useState({})

  const [disableBtn, setDisableBtn] = useState(true)
  useEffect(() => {
    const { farmerBankId, bank_account_number, bank_account_name } = formRef?.current?.values

    if (!farmerBankId && !bank_account_name && !bank_account_number) {
      setIsSubmit(true)
    } else {
      setIsSubmit(false)
    }
  }, [formRef])

  const [initialValue, setInitialValue] = useState({
    regionalId: "",
    phone: "",
    name: "",
    farmerName: "",
    postal: "",
    address: "",
    ktpNo: "",
    ktpName: "",
    ktpPicUrl: "",
    selfiePicUrl: "",
    province: "",
    regency: "",
    district: "",
    subDistrict: "",
    bankAccountNumber: 0,
    bankAccountName: "",
    bankId: "",
    gender: "",
    dob: "",
    mitraId: "",
    farmerProvince: "",
    farmerRegency: "",
    farmerDistrict: "",
    farmerSubDistrict: "",
    farmerPostalCode: "",
    farmerAddress: "",
    phoneNumber: ""
  })

  const [showPopup, setShowPopup] = useState(false)
  const [dataSend, setDataSend] = useState([])
  const [active, setActive] = useState(true)
  const [editStep, setEditStep] = useState("")
  const [isValid, setIsValid] = useState(false)

  useEffect(() => {
    if (disableBtn && !isSubmit && isValid) {
      setActive(false)
    } else {
      setActive(true)
    }
  }, [disableBtn, isSubmit, isValid])

  const handleSpace = (e) => {
    if (formRef?.current?.values?.farmerName === "" || e.target.selectionStart === 0) {
      e.keyCode === 32 && e.preventDefault()
    }
  }

  const handleEditStep = (val) => {
    setEditStep(val)
  }

  useEffect(() => {
    // this code for can multiple edit into select step (only create)
    if (!id) {
      const stepPreview = 3
      if (!active) {
        setEditStep(stepPreview)
      }
    }
  }, [active])

  const addFarmerFlow = [
    <DataFarmer key={0} isReadOnly={false} onKeyDown={handleSpace} id={id} />,
    <KtpSwafotoFarmer key={1} isReadOnly={false} />,
    <AkunBank key={2} isReadOnly={false} setDisableBtn={setDisableBtn} />,
    <DetailInputFarmer key={3} formData={formData} editStep={handleEditStep} />
  ]

  useEffect(() => {
    subHeader.setButton(buttonSubmited)
  }, [isSubmit, active])

  const stepsDescription = [
    t("label.user_information"),
    t("label.ktp_selfie_photo"),
    t("label.bank_information"),
    t("label.review_individual_submit")
  ]

  useEffect(() => {
    fetchProvince()
    fetchBanks()
    let _title = id ? "" : t("sub_header.add_farmer")
    if (id) {
      dispatch(getDetail(id))
      setStepsTitle([t("card_title.farmer_data"), t("label.ktp_selfie"), t("label.bank_account")])
    } else {
      setInitialValue((prevState) => ({
        ...prevState
      }))
    }
    subHeader.setTitle(_title)
    subHeader.setBreadcrumbs(null)
    subHeader.setButton(buttonSubmited)
  }, [id, actionDetail])

  useEffect(() => {
    if (General.isFinishUpdateOrDelete && General.actionInitType !== GENERAL.UPLOAD) {
      swal({
        text: Farmer.messages,
        icon: toAbsoluteUrl(
          id
            ? ILLUSTRATION_ASSETS_V2.farmer.IL_SUCCESS_UPDATED
            : ILLUSTRATION_ASSETS_V2.farmer.IL_SUCCESS_CREATED
        )
      }).then(() =>
        history.push(RoutesConstants.FARMER.LIST_FARMER(ROUTING_STATUS.STATUS_ENUM.PENDING))
      )
    }
  }, [General.isFinishUpdateOrDelete, Farmer])

  const buttonSubmited = (
    <button
      type="button"
      disabled={active}
      className="btn btn-primary"
      onClick={() => {
        if (formRef.current) {
          formRef.current.handleSubmit()
        }
      }}>
      Submit
    </button>
  )

  useEffect(() => {
    const farmer = Farmer.data
    if (farmer !== undefined && id) {
      subHeader.setTitle(t("sub_header.edit_farmer_with_name", {name: farmer.name}))

      setInitialValue((prevState) => ({
        ...prevState,
        regionalId: farmer.regional_id,
        mitraId: farmer.mitra_id,
        farmerName: farmer.name,
        dob: dateSelectReserve(farmer.dob),
        phoneNumber: farmer.phone,
        gender: farmer.gender,
        farmerProvince: farmer.province.id,
        farmerRegency: farmer.regency.id,
        farmerDistrict: farmer.district.id,
        farmerSubDistrict: farmer.sub_district.id,
        farmerPostalCode: farmer.postal,
        farmerAddress: farmer.address,
        farmerProvinceName: farmer.province.name,
        farmerRegencyName: farmer.regency.name,
        farmerDistrictName: farmer.district.name,
        farmerSubDistrictName: farmer.sub_district.name,
        nomorKtp: farmer.ktp_no,
        namaKtp: farmer.ktp_name,
        fotoKtp: farmer.ktp_pic_url,
        swafoto: farmer.selfie_pic_url,
        farmerBankId: farmer.bank_account.bank_id ?? "",
        farmerBankAccountName: farmer.bank_account.account_name ?? "",
        farmerBankAccountNumber: farmer.bank_account.account_number ?? ""
      }))

      subHeader.setBreadcrumbs([
        {
          pathname: RoutesConstants.FARMER.LIST_FARMER(ROUTING_STATUS.STATUS_ENUM.PENDING),
          title: t("sub_header.farmer")
        },
        {
          pathname: RoutesConstants.FARMER.LIST_FARMER(farmer?.status?.toUpperCase()),
          title: titleCase(
            farmer?.status?.toLowerCase() === ROUTING_STATUS.STATUS_STRING.ACTIVE
              ? t("label.active").toLowerCase()
              : farmer?.status?.toLowerCase() === ROUTING_STATUS.STATUS_STRING.INACTIVE
              ? t("label.inactive").toLowerCase()
              : t("label.rejected").toLowerCase()
          )
        },
        {
          pathname: RoutesConstants.FARMER.DETAIL_FARMER(farmer?.status?.toLowerCase(), farmer?.id),
          title: titleCase(farmer.name)
        },
        {
          title: actionDetail
        }
      ])
    }
  }, [Farmer.data])

  let [FarmerAddScheme, setFarmerAddScheme] = useState([
    Yup.object().shape({
      regionalId: Yup.string().required(getStringErrorMassage("Regional ", FORM_NEED.harusDiIsi)),
      mitraId: Yup.string().required(getStringErrorMassage("mitra ", FORM_NEED.harusDiIsi)),
      farmerName: Yup.string()
        .min(3, getMinimumErrorString(3))
        .max(50, getMaximumErrorString(50))
        .required(getStringErrorMassage("Nama", FORM_NEED.harusDiIsi)),
      dob: Yup.string().required(getStringErrorMassage("Tanggal Lahir ", FORM_NEED.harusDiIsi)),
      gender: Yup.string().required(getStringErrorMassage("Jenis Kelamin ", FORM_NEED.harusDiIsi)),
      phoneNumber: Yup.string()
        .required(getStringErrorMassage("Phone Number ", FORM_NEED.harusDiIsi))
        .matches(/^[0-9]+$/, "Harus Angka")
        .min(6, "Minimal 6 Digit")
        .max(15, "Maximal 15 Digit"),
      farmerProvince: Yup.string().required(
        getStringErrorMassage("Provinsi ", FORM_NEED.harusDiIsi)
      ),
      farmerRegency: Yup.string().required(getStringErrorMassage("Kota ", FORM_NEED.harusDiIsi)),
      farmerDistrict: Yup.string().required(
        getStringErrorMassage("Kecamatan ", FORM_NEED.harusDiIsi)
      ),
      farmerSubDistrict: Yup.string().required(
        getStringErrorMassage("Kelurahan ", FORM_NEED.harusDiIsi)
      ),
      farmerPostalCode: Yup.string(),
      farmerAddress: Yup.string().required(getStringErrorMassage("Alamat ", FORM_NEED.harusDiIsi))
    }),

    Yup.object().shape({
      nomorKtp: Yup.string()
        .required(getStringErrorMassage("Nomor KTP ", FORM_NEED.harusDiIsi))
        .matches(/^[0-9]+$/, "Harus Angka")
        .min(16, "Harus 16 Digit")
        .max(16, "Harus 16 Digit"),
      fotoKtp: Yup.string()
        .required(getStringErrorMassage("Foto KTP ", FORM_NEED.harusDiIsi))
        .nullable(),
      swafoto: Yup.string()
        .required(getStringErrorMassage("Swafoto ", FORM_NEED.harusDiIsi))
        .nullable()
    }),

    Yup.object().shape({
      farmerBankAccountNumber: Yup.string()
        .matches(/^[0-9]+$/, "Harus Angka")
        .max("15", "Maximal 15 Digit")
        .when("farmerBankId", {
          is: (item) => item === undefined,
          then: "",
          otherwise: Yup.string()
        }),
      farmerBankAccountName: Yup.string()
        .max(50, "Nama Maksimal 50 Karakter")
        .when("farmerBankId", {
          is: (item) => item === undefined,
          then: "",
          otherwise: Yup.string()
        })
    })
  ])

  useEffect(() => {
    if (!disableBtn) {
      setFarmerAddScheme([
        Yup.object().shape({
          regionalId: Yup.string().required(
            getStringErrorMassage("Regional ", FORM_NEED.harusDiIsi)
          ),
          mitraId: Yup.string().required(getStringErrorMassage("mitra ", FORM_NEED.harusDiIsi)),
          farmerName: Yup.string()
            .min(3, getMinimumErrorString(3))
            .max(50, getMaximumErrorString(50))
            .required(getStringErrorMassage("Nama", FORM_NEED.harusDiIsi)),
          dob: Yup.string().required(getStringErrorMassage("Tanggal Lahir ", FORM_NEED.harusDiIsi)),
          gender: Yup.string().required(
            getStringErrorMassage("Jenis Kelamin ", FORM_NEED.harusDiIsi)
          ),
          phoneNumber: Yup.string()
            .required(getStringErrorMassage("Phone Number ", FORM_NEED.harusDiIsi))
            .matches(/^[0-9]+$/, "Harus Angka")
            .min(6, "Minimal 6 Digit")
            .max(15, "Maximal 15 Digit"),
          farmerProvince: Yup.string().required(
            getStringErrorMassage("Provinsi ", FORM_NEED.harusDiIsi)
          ),
          farmerRegency: Yup.string().required(
            getStringErrorMassage("Kota ", FORM_NEED.harusDiIsi)
          ),
          farmerDistrict: Yup.string().required(
            getStringErrorMassage("Kecamatan ", FORM_NEED.harusDiIsi)
          ),
          farmerSubDistrict: Yup.string().required(
            getStringErrorMassage("Kelurahan ", FORM_NEED.harusDiIsi)
          ),
          farmerPostalCode: Yup.string(),
          farmerAddress: Yup.string().required(
            getStringErrorMassage("Alamat ", FORM_NEED.harusDiIsi)
          )
        }),

        Yup.object().shape({
          nomorKtp: Yup.string()
            .required(getStringErrorMassage("Nomor KTP ", FORM_NEED.harusDiIsi))
            .matches(/^[0-9]+$/, "Harus Angka")
            .min(16, "Harus 16 Digit")
            .max(16, "Harus 16 Digit"),
          fotoKtp: Yup.string()
            .required(getStringErrorMassage("Foto KTP ", FORM_NEED.harusDiIsi))
            .nullable(),
          swafoto: Yup.string()
            .required(getStringErrorMassage("Swafoto ", FORM_NEED.harusDiIsi))
            .nullable()
        }),

        Yup.object().shape({
          farmerBankAccountNumber: Yup.string()
            .required(getStringErrorMassage("Nomor Rekening ", FORM_NEED.harusDiIsi))
            .matches(/^[0-9]+$/, "Harus Angka")
            .max("15", "Maximal 15 Digit")
            .when("farmerBankId", {
              is: (item) => item === undefined,
              then: "",
              otherwise: Yup.string()
            }),
          farmerBankAccountName: Yup.string()
            .required(getStringErrorMassage("Nama Pemilik Akun Bank ", FORM_NEED.harusDiIsi))
            .max(50, "Nama Maksimal 50 Karakter")
            .when("farmerBankId", {
              is: (item) => item === undefined,
              then: "",
              otherwise: Yup.string()
            })
        })
      ])
    } else {
      setFarmerAddScheme([
        Yup.object().shape({
          regionalId: Yup.string().required(
            getStringErrorMassage("Regional ", FORM_NEED.harusDiIsi)
          ),
          mitraId: Yup.string().required(getStringErrorMassage("mitra ", FORM_NEED.harusDiIsi)),
          farmerName: Yup.string()
            .min(3, getMinimumErrorString(3))
            .max(50, getMaximumErrorString(50))
            .required(getStringErrorMassage("Nama", FORM_NEED.harusDiIsi)),
          dob: Yup.string().required(getStringErrorMassage("Tanggal Lahir ", FORM_NEED.harusDiIsi)),
          gender: Yup.string().required(
            getStringErrorMassage("Jenis Kelamin ", FORM_NEED.harusDiIsi)
          ),
          phoneNumber: Yup.string()
            .required(getStringErrorMassage("Phone Number ", FORM_NEED.harusDiIsi))
            .matches(/^[0-9]+$/, "Harus Angka")
            .min(6, "Minimal 6 Digit")
            .max(15, "Maximal 15 Digit"),
          farmerProvince: Yup.string().required(
            getStringErrorMassage("Provinsi ", FORM_NEED.harusDiIsi)
          ),
          farmerRegency: Yup.string().required(
            getStringErrorMassage("Kota ", FORM_NEED.harusDiIsi)
          ),
          farmerDistrict: Yup.string().required(
            getStringErrorMassage("Kecamatan ", FORM_NEED.harusDiIsi)
          ),
          farmerSubDistrict: Yup.string().required(
            getStringErrorMassage("Kelurahan ", FORM_NEED.harusDiIsi)
          ),
          farmerPostalCode: Yup.string(),
          farmerAddress: Yup.string().required(
            getStringErrorMassage("Alamat ", FORM_NEED.harusDiIsi)
          )
        }),

        Yup.object().shape({
          nomorKtp: Yup.string()
            .required(getStringErrorMassage("Nomor KTP ", FORM_NEED.harusDiIsi))
            .matches(/^[0-9]+$/, "Harus Angka")
            .min(16, "Harus 16 Digit")
            .max(16, "Harus 16 Digit"),
          fotoKtp: Yup.string()
            .required(getStringErrorMassage("Foto KTP ", FORM_NEED.harusDiIsi))
            .nullable(),
          swafoto: Yup.string()
            .required(getStringErrorMassage("Swafoto ", FORM_NEED.harusDiIsi))
            .nullable()
        }),

        Yup.object().shape({
          farmerBankAccountNumber: Yup.string()
            .matches(/^[0-9]+$/, "Harus Angka")
            .max("15", "Maximal 15 Digit")
            .when("farmerBankId", {
              is: (item) => item === undefined,
              then: "",
              otherwise: Yup.string()
            }),
          farmerBankAccountName: Yup.string()
            .max(50, "Nama Maksimal 50 Karakter")
            .when("farmerBankId", {
              is: (item) => item === undefined,
              then: "",
              otherwise: Yup.string()
            })
        })
      ])
    }
  }, [disableBtn])

  function onSubmitForm(e) {
    const { values, submited } = e
    setFormData(values)

    // change format date to dd-mm-yyyy
    const date = values.dob
    const [year] = date.split("-")
    let newDate
    if (year > 31) {
      newDate = dateSelect(values.dob)
    } else {
      newDate = values.dob
    }

    if (submited) {
      const data = {
        phone: values.phoneNumber,
        name: values.farmerName,
        postal: values.farmerPostalCode,
        address: values.farmerAddress,
        ktp_no: values.nomorKtp,
        ktp_name: values.namaKtp,
        ktp_pic_url: values.fotoKtp,
        selfie_pic_url: values.swafoto,
        province: values.farmerProvince,
        regency: values.farmerRegency,
        district: values.farmerDistrict,
        sub_district: values.farmerSubDistrict,
        bank_account_number:
          values.farmerBankAccountNumber === undefined || values.farmerBankAccountNumber === ""
            ? ""
            : values.farmerBankAccountNumber,
        bank_account_name:
          values.farmerBankAccountName === undefined || values.farmerBankAccountName === ""
            ? ""
            : values.farmerBankAccountName,
        bank_id:
          values.farmerBankId === undefined || values.farmerBankId === ""
            ? ""
            : values.farmerBankId,
        dob: newDate,
        gender: values.gender,
        mitra_id: values.mitraId
      }
      setDataSend(data)
      onClickSendValue(data)
    }
  }

  function onClickSendValue(data) {
    if (actionDetail === "edit") {
      setShowPopup(true)
    } else {
      dispatch(createFarmer(data))
    }
  }

  const handleOk = (approve) => {
    if (approve) {
      if (actionDetail === "edit") {
        dispatch(updateFarmer(id, dataSend))
      } else {
        dispatch(createFarmer(dataSend))
      }
    }
  }

  return (
    <>
      <PopupQuestion
        onCancel={() => setShowPopup(false)}
        title={t("messages.title.update_farmer_data")}
        onOk={handleOk}
        show={showPopup}
        bodyCustom={
          <Col>
            <p className={"text-center"}>
              {t("messages.confirmation.update_farmer_data_confirmation")}
            </p>
          </Col>
        }
      />
      <TabsInputComponent
        isEditTabs={actionDetail !== EndpointRouter.action.newAction}
        steps={stepsTitle}
        stepDescription={stepsDescription}
        formInitialValues={initialValue}
        formId="farmerInput"
        innerRef={formRef}
        componentTab={addFarmerFlow}
        validationSchema={FarmerAddScheme}
        onSubmitForm={onSubmitForm}
        backToStep={editStep}
        onChangeStep={(activeStep) => {
          setIsSubmit(activeStep < stepsTitle.length - 1 ? true : false)
          setIsValid(formRef?.current?.isValid)
        }}
      />
    </>
  )
}