import React, { useEffect, useState } from "react"
import { PopupQuestion } from "../../../../../../component/atoms/Popup"
import { Button } from "react-bootstrap"
import { useSelector } from "react-redux"
import { useTranslation } from "react-i18next"

const ModalConfirmation = ({ showModalConfirmation, setModalConfirmation, onSubmitForm }) => {
  const { General } = useSelector((state) => state)
  const {t} = useTranslation()
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setLoading(General.loading)

    return () => {
      setLoading(false)
    }
  }, [General])

  return (
    <PopupQuestion
      show={showModalConfirmation}
      title={t("messages.title.new_clearing_report_ba")}
      bodyCustom={
        <div>
          <div className={"text-center p-8"}>
            Dengan menyatakan lahan bongkar, lahan terpilih akan
            <span className="font-weight-bold text-dark"> direset musim tanam</span> dan semua
            kegiatan akan dihentikan. <br />
            Pastikan semua data yang diinput telah benar.
          </div>
          <div className="d-flex gap-4 w-100 mt-8">
            <Button
              className="font-weight-bold btn-block my-0 mr-2 w-100"
              onClick={() => setModalConfirmation(false)}
              type="reset"
              variant={"secondary-info"}>
              {t("button.cancel")}
            </Button>
            <Button
              className="font-weight-bold btn-block my-0 ml-2 w-100"
              type={"submit"}
              variant={"primary"}
              disabled={loading}
              onClick={() => {
                onSubmitForm()
                setModalConfirmation(false)
              }}>
              {t("button.next")}
            </Button>
          </div>
        </div>
      }
      isUseFooter={false}
    />
  )
}

export default ModalConfirmation
