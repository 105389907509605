import React, { useCallback, useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { getAreaRejectList } from "../../../../redux/actions/AreaAction.jsx"
import { clearDataDetail, getAllMitra } from "../../../../redux/actions/MitraAction.jsx"
import { useSubheader } from "../../../../_metronic/layout/index.js"
import { Card, CardBody, CardHeader } from "../../../../_metronic/_partials/controls/index.js"
import { RowModule } from "../../../../component/atoms/RowModule/RowModule.jsx"
import { FilterCard, TableSkeleton } from "../../../../component/molecule/index.jsx"
import {
  EmptyCard,
  LocationMolecule,
  SelectInput,
  TextInput
} from "../../../../component/index.jsx"
import TableView, {
  formatterRowComponent,
  initialFilterTable
} from "../../../../component/atoms/Tabel/index.jsx"
import { Badge, OverlayTrigger, Tooltip } from "react-bootstrap"
import { DetailSvgIcon } from "../../../../component/atoms/Icons/SVG/index.jsx"
import { sortCaret } from "../../../../_metronic/_helpers/index.js"
import { loadUserLogin } from "../../../service/userManagementAction.js"
import { linkWithCtrl } from "../../../helpers/Utils.js"
import { currentUrl } from "../../../helpers/UrlParamHelper.js"
import {
  resetUrlParam,
  setDataValueAction,
  SetFilterAction,
  setPageStringActions,
  setSearchAction
} from "../../../../redux/actions/FilterAction.js"
import { toThousandConvert } from "../../../helpers/TextHelper.js"
import { useTranslation } from "react-i18next"
import { PRIVILEGE_ENUM } from "../../../../constants/PrivilegeConstants.js"
import { RoutesConstants } from "../../../../constants/RoutesConstants.js"
import { AREA_STATUS_STRING } from "../../../../constants/StringConstant.js"

export const ListAreaReject = ({ history }) => {
  const privileges = loadUserLogin().privilege
  const subHeader = useSubheader()
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { General, Area, Mitralist, UrlParam } = useSelector((state) => state)
  const formRef = useRef()

  const [search, setSearch] = useState({
    string_filter: ""
  })
  const [stateMitra, setStateMitra] = useState()
  const [showFilter, setShowFilter] = useState(false)
  const [filterOn, setFilterOn] = useState(false)
  const [initialValue, setInitialValue] = useState({
    mitra_id: "",
    province_id: "",
    regency_id: "",
    district_id: "",
    sub_district_id: "",
    min_area: "",
    max_area: ""
  })
  const isReadOnly = false
  const [isFilter, setIsFilter] = useState(false)
  const [reset, setReset] = useState(false)
  const [mitra, setMitra] = useState("")
  const [min, setMin] = useState("")
  const [max, setMax] = useState("")
  const [btnFilterDisable, setBtnFilterDisable] = useState(true)
  const [isActiveSearch, setIsActiveSearch] = useState(false)
  const urlCurrent = currentUrl()
  const filterValue = UrlParam.data

  useEffect(() => {
    if (UrlParam.pageString !== urlCurrent) {
      dispatch(resetUrlParam())
    }
  }, [])

  useEffect(() => {
    if (UrlParam.search) {
      setIsActiveSearch(true)
    } else {
      setIsActiveSearch(false)
    }
  }, [UrlParam])

  useEffect(() => {
    if (UrlParam.search) {
      setSearch({
        string_filter: UrlParam.search
      })
    } else {
      setSearch({
        string_filter: ""
      })
    }
  }, [UrlParam.search, useCallback])

  useEffect(() => {
    const dataPage = {
      ...initialFilterTable
    }
    dispatch(setPageStringActions(urlCurrent))
    if (filterValue !== null && UrlParam.pageString === urlCurrent) {
      setMitra(filterValue?.mitra_id)
      const data = {
        ...initialFilterTable,
        ...UrlParam.dataValue,
        string_filter: UrlParam.search,
        mitra_id: filterValue?.mitra_id,
        province_id: filterValue.province_id,
        regency_id: filterValue.regency_id,
        district_id: filterValue.district_id,
        sub_district_id: filterValue.sub_district_id,
        max_area: filterValue.max_area,
        min_area: filterValue.min_area
      }
      if (data) {
        setFilterOn(true)
        setInitialValue(data)
        dispatch(getAreaRejectList({ pageReq: data }))
        setShowFilter(false)
      }
    }
    if (UrlParam.pageString === urlCurrent) {
      if (UrlParam.dataValue && !UrlParam.search) {
        setSearch({
          string_filter: UrlParam.search
        })

        let param = {
          ...UrlParam.dataValue,
          string_filter: UrlParam.search
        }
        dispatch(getAreaRejectList({ pageReq: param }))
      }
    } else {
      dispatch(getAreaRejectList({ pageReq: dataPage }))
    }
  }, [UrlParam, dispatch])

  useEffect(() => {
    if (
      formRef.current.values.mitra_id ||
      formRef.current.values.province_id ||
      formRef.current.values.min_area ||
      formRef.current.values.max_area
    )
      setBtnFilterDisable(false)
    else setBtnFilterDisable(true)
  }, [formRef.current, mitra, min, max])

  useEffect(() => {
    subHeader.setButton(false)
    subHeader.setTitle(t("sub_header.rejected_area"))
  }, [])

  const changePage = useCallback(
    (param) => {
      setSearch({
        string_filter: UrlParam.search
      })
      let newParam = {
        ...initialValue,
        ...search,
        ...param
      }
      if (param.sortField) {
        newParam = {
          ...param,
          ...newParam,
          ...search,
          sort: param.sortField + "," + param.sortOrder
        }
      } else if (UrlParam.search) {
        newParam = {
          ...initialValue,
          ...search,
          ...param
        }
      } else if (!UrlParam.search) {
        newParam = {
          ...initialValue,
          ...search,
          ...param
        }
      }
      dispatch(getAreaRejectList({ pageReq: newParam }))
      dispatch(setDataValueAction(newParam))
    },
    [dispatch, initialValue, search]
  )

  // mitralist
  useEffect(() => {
    dispatch(getAllMitra())
    return () => {
      dispatch(clearDataDetail())
    }
  }, [])

  useEffect(() => {
    if (Mitralist.data) {
      setStateMitra(
        Mitralist.data.map((value) => ({
          label: value.name,
          value: value.id_mitra
        }))
      )
    }
  }, [Mitralist.data])

  useEffect(() => {
    subHeader.setButton(false)
  }, [])

  const filterOnSubmitForm = (e) => {
    setReset(false)
    const { values, submited } = e
    setMin(values.min_area)
    setMax(values.max_area)
    setIsFilter(true)
    if (submited === true) {
      let param = {
        ...initialFilterTable,
        mitra_id: values.mitra_id,
        province_id: values.province_id,
        regency_id: values.regency_id,
        district_id: values.district_id,
        sub_district_id: values.sub_district_id,
        min_area: values.min_area,
        max_area: values.max_area,
        string_filter: search.string_filter
      }

      if (param) {
        setInitialValue(param)
        dispatch(SetFilterAction(param))
        dispatch(getAreaRejectList({ pageReq: param }))
        setShowFilter(false)
        setFilterOn(true)
      }
    }
  }

  const onReset = () => {
    dispatch(SetFilterAction())
    setReset(true)
    setMitra("")
    setMin("")
    setMax("")
    setShowFilter(false)
    setIsFilter(false)
    setFilterOn(false)
    setInitialValue({
      mitra_id: "",
      province_id: "",
      regency_id: "",
      district_id: "",
      sub_district_id: "",
      min_area: "",
      max_area: ""
    })
    let param = {
      ...initialFilterTable,
      mitra_id: "",
      province_id: "",
      regency_id: "",
      district_id: "",
      sub_district_id: "",
      min_area: "",
      max_area: "",
      string_filter: search.string_filter
    }
    dispatch(getAreaRejectList({ pageReq: param }))
  }

  const seacrhFunc = (values) => {
    let param = {
      ...initialFilterTable,
      ...initialValue,
      string_filter: values.string_filter
    }
    setSearch({
      string_filter: values.string_filter
    })
    dispatch(setSearchAction(values?.string_filter))
    dispatch(getAreaRejectList({ pageReq: param }))
    setIsFilter(true)
  }

  const handleReset = () => {
    dispatch(setSearchAction())
    setIsFilter(false)
    setSearch({
      string_filter: ""
    })
    let param = {
      ...initialFilterTable,
      ...initialValue,
      string_filter: ""
    }
    dispatch(getAreaRejectList({ pageReq: param }))
  }

  const handleOpenCloseFilter = (value) => {
    setShowFilter(value)
  }

  function onChangeMitra(e) {
    setMitra(e)
  }

  const filterComponent = (
    <>
      <Card className="card-custom card-shadowless">
        <CardHeader title="Mitra" className="px-0" />
        <CardBody className="px-0">
          <RowModule>
            <SelectInput
              name="mitra_id"
              onChange={onChangeMitra}
              editable={!isReadOnly}
              label={t("label.mitra_name")}
              placeholder={t("label.choose_one") + "..."}
              options={stateMitra}
              withoutFeedback={true}
              clearNow={true}
              value={mitra}
            />
          </RowModule>
        </CardBody>
      </Card>

      <LocationMolecule
        resetLocation={reset}
        name="filter"
        withoutFeedback={true}
        isReadOnly={isReadOnly}
        addressTitle={t("label.location")}
        removeHorizontalPadding={true}
      />
      <Card className="card-custom card-shadowless">
        <CardHeader title={t("label.land_area") + " (m2)"} className="px-0" />
        <CardBody className="px-0">
          <RowModule>
            <TextInput
              editable={!isReadOnly}
              label={t("label.min")}
              name="min_area"
              placeholder={t("label.minimal_value")}
              withoutFeedback={true}
              value={min}
              onChangeValue={setMin}
            />
          </RowModule>
          <RowModule>
            <TextInput
              editable={!isReadOnly}
              label={t("label.max")}
              name="max_area"
              placeholder={t("label.maximal_value")}
              withoutFeedback={true}
              value={max}
              onChangeValue={setMax}
            />
          </RowModule>
        </CardBody>
      </Card>
    </>
  )

  const uiCode = (e) => {
    return (
      <>
        <p className="text-dark font-weight-bolder mb-1 font-size-lg">{e.area_code ?? "-"}</p>
      </>
    )
  }
  const uiMitra = (e) => {
    return (
      <>
        <p className="text-dark font-weight-bolder mb-1 font-size-lg">{e.mitra_name ?? "-"}</p>
        <span className="text-muted font-weight-bold d-block">{e.mitra_phone ?? "-"}</span>
      </>
    )
  }

  const uiStatus = (e) => {
    return <Badge variant="danger">{e.status}</Badge>
  }
  const uiAction = () => {
    return (
      <>
        {privileges.indexOf(PRIVILEGE_ENUM.DETAIL_AREA_REJECT) > -1 && (
          <OverlayTrigger overlay={<Tooltip id="products-edit-tooltip">Detail</Tooltip>}>
            <a className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3">
              <DetailSvgIcon></DetailSvgIcon>
            </a>
          </OverlayTrigger>
        )}
      </>
    )
  }

  const linkDetail = (e) => {
    history.push(RoutesConstants.AREA.DETAIL_AREA(ROUTING_STATUS.STATUS_STRING.REJECT, e.id))
  }

  const uiReason = (e) => {
    return (
      <ul className="mb-0">
        {e.reject_reason && typeof e?.reject_reason !== "string" && e.reject_reason.length > 0 ? (
          e.reject_reason.map((item, i) => {
            return <li key={i}>{item}</li>
          })
        ) : (
          <p className="mb-0">-</p>
        )}
      </ul>
    )
  }

  const uiLuas = (e) => {
    return (
      <>
        <span>{toThousandConvert(e.area_land) ?? "-"}</span>
      </>
    )
  }

  const uiDate = (e) => {
    return (
      <>
        <div className="mb-1">{e.submitted_date ? e.submitted_date : "-"}</div>
        <div>{e.submitted_time ? e.submitted_time : "-"}</div>
      </>
    )
  }

  const headerLuas = (
    <>
      {t("table_header.land_area_only")} (<span className={"text-lowercase"}>m</span>
      <sup>2</sup>)
    </>
  )

  const columns = [
    {
      dataField: "area_code",
      text: t("table_header.area_name"),
      formatter: formatterRowComponent,
      formatExtraData: {
        externalStyle: uiCode
      }
    },
    {
      dataField: "farmer.account.name",
      text: t("table_header.mitra"),
      formatter: formatterRowComponent,
      headerStyle: () => {
        return { width: "15%" }
      },
      formatExtraData: {
        externalStyle: uiMitra
      },
      sortCaret: sortCaret,
      sort: true,
      onSort: (field, order) => {
        let param = {
          ...initialFilterTable,
          ...initialValue,
          search: search.search,
          sort: field + "," + order
        }
        dispatch(getAreaRejectList({ pageReq: param }))
      }
    },
    {
      dataField: "landArea",
      text: headerLuas,
      formatter: formatterRowComponent,
      formatExtraData: {
        externalStyle: uiLuas
      },
      sortCaret: sortCaret,
      sort: true,
      onSort: (field, order) => {
        let param = {
          ...initialFilterTable,
          ...initialValue,
          search: search.search,
          sort: field + "," + order
        }
        dispatch(getAreaRejectList({ pageReq: param }))
      }
    },
    {
      dataField: "status",
      text: t("table_header.status_area"),
      formatter: formatterRowComponent,

      formatExtraData: {
        externalStyle: uiStatus
      }
    },
    {
      dataField: "reject_reason",
      text: t("table_header.reject_reason"),
      formatter: formatterRowComponent,
      formatExtraData: {
        externalStyle: uiReason
      }
    },
    {
      dataField: "modifiedDate",
      text: t("table_header.reject_date"),
      formatter: formatterRowComponent,
      formatExtraData: {
        externalStyle: uiDate
      },
      sortCaret: sortCaret,
      sort: true,
      onSort: (field, order) => {
        let param = {
          ...initialFilterTable,
          search: search.string_filter,
          sort: field + "," + order
        }
        dispatch(getAreaRejectList({ pageReq: param }))
      }
    },
    {
      dataField: "action",
      text: "ACTION",
      sort: false,
      formatter: formatterRowComponent,
      formatExtraData: {
        externalStyle: uiAction,
        onClickEvent: linkDetail
      }
    }
  ]

  return (
    <>
      <FilterCard
        // seacrh
        isActiveSearch={isActiveSearch}
        placeholder={t("placeholder.insert_area_name")}
        onSubmitForm={seacrhFunc}
        initialValues={search}
        name={"string_filter"}
        innerRef={formRef}
        trigerReset={handleReset}
        // filter
        filterInnerRef={formRef}
        filterHandleSubmit={filterOnSubmitForm}
        filterFormInitialValues={initialValue}
        filterComponent={filterComponent}
        filterOnReset={onReset}
        showFilter={showFilter}
        handleOpenCloseFilter={handleOpenCloseFilter}
        filterOn={filterOn}
        btnDisable={btnFilterDisable}></FilterCard>

      <Card className="card-shadowless">
        <CardBody>
          {General.loading ? (
            <TableSkeleton column={7} row={10} />
          ) : Area?.pageData?.length == 0 ? (
            <EmptyCard
              body={
                isFilter || isActiveSearch
                  ? t("messages.response.data_not_found")
                  : t("messages.response.empty_state", { name: "lahan" })
              }
            />
          ) : (
            ""
          )}
          <TableView
            callbackAfterPageChange={changePage}
            columnProperties={columns}
            dataTable={Area.pageData}
            currentPage={Area.page.currentPage}
            currentElement={Area.page.currentElement}
            totalCount={Area.page.totalCount}
            loadingGetData={General.loading}
            ignoreSinglePage
            rowClasses={() => {
              return "cursor-pointer"
            }}
            rowEvents={{
              onClick: (e, row) => {
                if (privileges.indexOf(PRIVILEGE_ENUM.DETAIL_AREA_REJECT) > -1) {
                  const url = RoutesConstants.AREA.DETAIL_AREA(AREA_STATUS_STRING.REJECT, row.area_id)
                  if (e.ctrlKey) {
                    linkWithCtrl(url)
                  } else {
                    history.push(url)
                  }
                }
              }
            }}
          />
        </CardBody>
      </Card>
    </>
  )
}