import React from "react"
import { Card, CardBody, CardHeader } from "../../../../../_metronic/_partials/controls/index.js"
import { DetailText } from "../../../../../component/index.jsx"
import { RowModule } from "../../../../../component/atoms/RowModule/RowModule.jsx"
import { useTranslation } from "react-i18next"

export const PreviewFieldOfficer = ({ formData }) => {
  const {t} = useTranslation()
  return (
    <Card>
      <CardHeader title="REVIEW DATA FO" />
      <CardBody>
        <RowModule>
          <Card>
            <CardHeader title="DATA FO" />
            <CardBody>
              <DetailText title={t("label.name")} value={formData?.name ?? "-"} />
              <DetailText title={t("label.phone_number")} value={formData?.phone_number ?? "-"} />
              <DetailText title="Email" value={formData?.email ?? "-"} />
              <DetailText title="Role" value={formData?.roleFO?.display_name ?? "-"} />
              <DetailText
                title={t("label.address")}
                type="address"
                value={
                  formData?.address +
                  ", " +
                  formData?.postal_code +
                  ", " +
                  formData?.sub_district_name +
                  ", " +
                  formData?.district_name +
                  ", " +
                  formData?.regency_name +
                  ", " +
                  formData?.province_name
                }
              />
            </CardBody>
          </Card>
        </RowModule>
        <RowModule>
          <Card>
            <CardHeader title={t("card_title.photo_profile").toUpperCase()} />
            <CardBody>
              <DetailText type="image" value={formData?.avatar} />
            </CardBody>
          </Card>
        </RowModule>
      </CardBody>
    </Card>
  )
}