import React, { useEffect, useState } from "react"
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderTitle
} from "../../../../../_metronic/_partials/controls"
import { useDispatch, useSelector } from "react-redux"
import {
  fetchDistrictByRegencyId,
  fetchRegencyByProvinceId,
  fetchSubDistrictByDistrictId,
  getListRegionalAll
} from "../../../../../redux/actions/MasterDataAction"
import { DetailText, RowText } from "../../../../../component/index.jsx"
import { RowModule } from "../../../../../component/atoms/RowModule/RowModule.jsx"
import { useTranslation } from "react-i18next"

export const ReviewEdu = ({ formData, editStep }) => {
  const dispatch = useDispatch()
  const { Mitra, MasterData } = useSelector((state) => state)
  const {t} = useTranslation()
  const [bankName, setBankName] = useState("")
  const [proviceName, setProvinceName] = useState("")
  const [regencyName, setRegencyName] = useState("")
  const [districtName, setDistrictName] = useState("")
  const [subDistrictName, setSubDistrictName] = useState("")
  const [mitraType, setMitraType] = useState("")

  const regionalList = MasterData?.listRegionalAll ?? []
  const regional = regionalList?.find((e) => e?.id === formData?.regionalId)?.name ?? "-"

  useEffect(() => {
    dispatch(getListRegionalAll())
  }, [])

  useEffect(() => {
    if (MasterData.bankList.length !== 0) {
      setBankName(MasterData.bankList.find((value) => value.id === formData?.bank_id))
    }
  }, [MasterData.bankList])

  useEffect(() => {
    if (MasterData.bankList.length !== 0) {
      setBankName(MasterData.bankList.find((value) => value.id === formData?.bank_id))
    }
  }, [MasterData.bankList])

  useEffect(() => {
    if (MasterData.provinceList.length !== 0) {
      setProvinceName(MasterData.provinceList.find((value) => value.id === formData?.mitraProvince))
      dispatch(fetchRegencyByProvinceId(formData?.mitraProvince))
    }
  }, [MasterData.provinceList])

  useEffect(() => {
    if (MasterData.regencyList.length !== 0) {
      setRegencyName(MasterData.regencyList.find((value) => value.id === formData?.mitraRegency))
      dispatch(fetchDistrictByRegencyId(formData?.mitraRegency))
    }
  }, [MasterData.regencyList])

  useEffect(() => {
    if (MasterData.districtList.length !== 0) {
      setDistrictName(MasterData.districtList.find((value) => value.id === formData?.mitraDistrict))
      dispatch(fetchSubDistrictByDistrictId(formData?.mitraDistrict))
    }
  }, [MasterData.districtList])

  useEffect(() => {
    if (MasterData.subDistrictList.length !== 0) {
      setSubDistrictName(
        MasterData.subDistrictList.find((value) => value.id === formData?.mitraSubDistrict)
      )
    }
  }, [MasterData.subDistrictList])

  useEffect(() => {
    if (Mitra.type) {
      setMitraType(Mitra.type.filter((item) => item.mitra_type_enum === formData.mitra_type))
    }
  }, [Mitra])

  return (
    <>
      <Card className="card-custom card-shadowless">
        <CardBody>
          <RowModule>
            <Card className="card-custom card-shadowless">
              <CardBody>
                <CardHeaderTitle className="py-10">
                  {"ReviewIndividual data MITRA".toUpperCase()}
                </CardHeaderTitle>
              </CardBody>
              <div className={"d-flex justify-content-between align-items-center px-7"}>
                <h4>{t("label.mitra_data").toUpperCase()}</h4>
                <a
                  className={"link"}
                  onClick={() => {
                    editStep(0)
                  }}>
                  EDIT
                </a>
              </div>
              <CardBody>
                <div className="mb-10">
                  <DetailText title={t("label.photo_profile")} />
                  <DetailText type="image-dashed" value={formData?.mitra_pic_url} />
                </div>

                <RowModule customColumnCss={"col-md-6"}>
                  <RowModule customColumnCss={"col-md-12 mb-2"}>
                    <RowText label={t("label.mitra_type")} value={mitraType[0]?.title} />
                  </RowModule>
                  <RowModule customColumnCss={"col-md-12 mb-2"}>
                    <RowText label={t("label.telp_number")} value={formData?.phone} />
                  </RowModule>
                  <RowModule customColumnCss={"col-md-12 mb-2"}>
                    <RowText label={"Regional"} value={regional} />
                  </RowModule>
                  <RowModule customColumnCss={"col-md-12 mb-2"}>
                    <RowText
                      label={t("label.organization_address")}
                      value={
                        formData?.mitraAddress +
                        ", " +
                        subDistrictName.name +
                        ", " +
                        districtName.name +
                        ", " +
                        regencyName.name +
                        ", " +
                        proviceName.name
                      }
                    />
                  </RowModule>
                  <RowModule customColumnCss={"col-md-12 mb-2"}>
                    <RowText label={t("label.organization_name")} value={formData?.name} />
                  </RowModule>
                  <RowModule customColumnCss={"col-md-12 mb-2"}>
                    <RowText label={"Email"} value={formData?.email} />
                  </RowModule>
                </RowModule>
              </CardBody>
            </Card>
          </RowModule>
          <RowModule>
            <Card className="card-custom card-shadowless">
              <div className={"d-flex justify-content-between align-items-center px-7"}>
                <h4>{t("label.person_in_charge").toUpperCase()}</h4>
              </div>
              <CardBody>
                <RowText label={t("label.name_as_per_id")} value={formData?.coordinator} />
                <RowText label={t("label.ktp_number")} value={formData?.ktp_no} />
                <RowText label={`${t("label.ktp_picture")} : `} />
                <DetailText type="image-dashed" value={formData?.ktp_pic_url} />
                <CardHeader />
              </CardBody>
            </Card>
          </RowModule>
          <RowModule>
            <Card className="card-custom card-shadowless mt-10">
              <div className={"d-flex justify-content-between align-items-center px-7"}>
                <h4>{t("label.organization_document").toUpperCase()}</h4>
                <a
                  className={"link"}
                  onClick={() => {
                    editStep(1)
                  }}>
                  EDIT
                </a>
              </div>
              <CardBody>
                <DetailText title={t("label.organizational_structure")} />
                <DetailText type="image-dashed" value={formData?.business_document_pic_url} />
                <RowText label={t("label.npwp_organization_number")} value={formData?.npwp_no} />
                <DetailText title={t("label.npwp_organziation_photo")} />
                <DetailText type="image-dashed" value={formData?.other_image} />
                <CardHeader />
              </CardBody>
            </Card>
          </RowModule>
          <RowModule>
            <Card className="card-custom card-shadowless">
              <div className={"d-flex justify-content-between align-items-center px-7"}>
                <h4>{t("label.organization_bank_account").toUpperCase()}</h4>
                <a
                  className={"link"}
                  onClick={() => {
                    editStep(2)
                  }}>
                  EDIT
                </a>
              </div>
              <CardBody>
                <RowText label={t("label.bank_name")} value={bankName?.name} />
                <RowText label={t("label.account_number")} value={formData?.bank_account_number} />
                <RowText label={t("label.account_name")} value={formData?.bank_account_name} />
                <CardHeader />
              </CardBody>
            </Card>
          </RowModule>
        </CardBody>
      </Card>
    </>
  )
}