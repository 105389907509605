export const ID_CONTENT = {
  button: {
    bulk_erp: "Bulk ERP ({{count}} Terpilih)",
    cancel: "Batal",
    confirmation_planting_season: "Konfirmasi Musim Tanam",
    continue: "Lanjut",
    download_so_selected: "Unduh So ({{selected}}) dipilih",
    oke: "Oke",
    previous: "Kembali",
    send_to_finance: "Kirim ke Finance",
    submit: "Kirim",
    approve: "Approve",
    add: "Tambah",
    reject: "Reject",
    see_detail: "Lihat Detail",
    next: "Lanjut",
    apply: "Terapkan",
    download: "Unduh",
    download_history: "Unduh Riwayat",
    add_pest_type: "Tambah Jenis Hama",
    delete: "Hapus",
    upload_ba: "Unggah Berita Acara",
    submission_review: "Review Pengajuan",
    select: "Pilih",
  },
  content: {
    attachment_maximum_size: "Akumulasi maksimal ukuran file 15Mb.",
    attachment_maximum_file: "Jumlah maksimal upload file adalah 10.",
    attachment_format_file: "Format file .pdf, .jpeg, .jpg, .png.",
    attachment_xlsx_file: "Format file yang diupload harus .xlsx.",
    attachment_xlsx_size: "Ukuran maksimal file 1Mb.",
  },
  description: {},
  menu: {
    add: "Tambah",
    area: "Lahan",
    dashboard: "Dashboard",
    farmer: "Petani",
    fo: "FO",
    mac: "MAC",
    cms_user: "User CMS",
    master_data: "Master Data",
    mitra: "Mitra",
    order: "Penjadwalan",
    setting: "Pengaturan",
    submission: "Pengajuan",
    quick_access: "Akses Cepat",
    land_clearing: "Bongkar",
    incorrect_variety: "Salah Varietas",
    report: "Laporan",
    change_filter: "Ubah Filter",
  },
  menu_aside: {
    area_location: "Lokasi Lahan",
    area_terverifikasi: "Teregistrasi",
    area_pending: "Sedang Menunggu",
    area_reject: "Ditolak",
    area_unassigned: "Unassigned",
    area_lead: "Calon Lahan",
    ba_seeding: "BA Pembenihan",
    area_activity_report: "Laporan Aktivitas",
    area_activity_report_kur: "Laporan Aktivitas KUR",
    area_sales_order: "Sales Order",
    all_schedule: "Semua Jadwal",
    approved_schedule: "Terjadwal",
    schedule_pasca_planting: "Pasca Tanam",
    schedule_pra_planting: "Pra Tanam",
    mac_profile: "Profil",
    mac_user: "Pengguna",
    pending: "Sedang Menunggu",
    rescheduled: "Jadwal Ulang",
    pre_planting: "Pra Tanam",
    spraying: "Penyemprotan",
    regional: "Regional",
    sertification_and_block: "Sertifikasi & Blok",
    ready_planting_season_confirmation: "Siap Konfirmasi MT"
  },
  sub_header: {
    activity_report: "Laporan Aktivitas",
    activity_report_kur: "Laporan Aktivitas Kur",
    title: "Sales Order",
    all_schedule: "Semua Jadwal",
    mac_activity_report: "Laporan Aktivitas MAC",
    spraying_pre_planting: "Penyemprotan Pra Tanam",
    pre_planting: "Pra Tanam",
    mitra_list_ca: "Daftar Mitra CA",
    regional_information: "Informasi Regional",
    mitra_list: "Daftar Mitra",
    seeding_minutes_report: "Laporan Berita Acara Pembenihan",
    registered_area: "Lahan Teregistrasi",
    area_location: "Lokasi Lahan",
    land_in_waiting: "Lahan Sedang Menunggu",
    mitra_detail: "Mitra Detail",
    add_new_mitra: "Tambah mitra Baru",
    farmer_detail: "Detail Petani",
    rejected_area: "Lahan Ditolak",
    unassign_area: "Lahan Unassigned",
    area_lead: "Calon Lahan",
    area_lead_list: "Daftar Calon Lahan",
    unassigned_area_list: "Daftar Lahan Unassigned",
    category_item: "Kategory Item",
    machine: "Mesin",
    pesticide: "Pestisida",
    edit_fo_with_name: "Edit FO Dengan Nama",
    add_field_officer: "Tambah Field Officer",
    fertilizer: "Pupuk",
    seed: "Benih",
    add_new_farmer_bulk: "Buat Data farmer Baru (Bulk)",
    add: "Tambah",
    spraying_package: "Paket Penyemprotan",
    add_farmer: "Tambah Petani",
    edit_farmer_with_name: "Edit Petani Dengan Nama {{ name }}",
    farmer: "Petani",
    fo_agronomist: "FO Agronomist",
    fo_coordinator: "FO Coordinator",
    fo_harvest: "FO Harvest",
    fo_qa: "FO Quality Assurance",
    manage_reason: "Kelola Alasan",
    fo: "Field Officer",
    edit_package: "Edit Paket",
    add_package: "Tambah Paket",
    edit_treatment: "Edit Treatment",
    add_treatment: "Tambah Treatment",
    edit_treatment_on: "Edit Treatment ke-",
    add_treatment_on: "Tambah Treatment ke-",
    user_cms: "User CMS",
    broadcast_message: "Broadcast Message",
    role_list: "Daftar Role",
    edit_video_with_title: "Edit video Dengan Judul '{{ title }}'",
    setting: "Pengaturan",
    manage_video_tutorial: "Kelola video Tutorial",
    video_tutorial: "Video Tutorial",
    admin_setting: "Setelan Admin",
  },
  placeholder: {
    insert_package_id_or_name: "Masukkan ID paket / nama paket",
    select_one: "Pilih salah satu",
    select_regional: "Pilih daerah regional",
    insert_plant_type: "Masukkan nama tanaman",
    insert_mac_username_or_request_id: "Cari nama pengguna / ID pengajuan",
    input_reason: "Input alasan disini",
    search_activity_report: "Cari nama pengguna / ID pengajuan",
    select_user_type: "Pilih tipe pengguna",
    insert_regional_id_or_name: "Masukkan ID regional / nama regional",
    insert_area_name: "Masukkan nama lahan",
    insert_area_name_or_farmer: "Masukkan nama lahan / petani",
    select_team_drone: "Pilih Tim Drone",
    insert_province: "Masukan Nama Provinsi",
    search_mitra_name_or_id: "Cari ID mitra / nama mitra",
    search_farmer_name_or_id: "Cari ID petani / nama petani",
    insert_regional_name: "Masukan nama regional",
    insert_sub_regional_name: "Input nama sub regional",
    search_area_block: "Cari nama lahan / blok",
    min_value: "Nilai Minimal",
    max_value: "Nilai Maksimal",
    select_date: "Pilih Tanggal",
    disaster_type: "Jenis Bencana Alam",
    select_date_range: "Pilih rentang tanggal",
    choose_province: "Pilih Provinsi...",
    choose_city: "Pilih Kota...",
    choose_district: "Pilih Kecamatan...",
    choose_subdistrict: "Pilih Kelurahan...",
    change_farmer_status_input: "Input alasan ubah status Petani.",
    change_farmer_mitra_input: "Input alasan mengubah Mitra.",
    reject_reason: "Alasan Penolakan",
    change_mitra_status_input: "Input alasan ubah status Mitra.",
    input_mitra_name: "Input Nama Mitra",
    input_name_as_per_ktp: "Input nama sesuai KTP",
    input_ktp_number: "Input Nomor KTP",
    input_bank_number: "Input Nomor Rekening",
    input_account_name: "Input Nama Pemilik Akun Bank",
    select_bank_name: "Pilih nama bank",
    input_organization_name: "Input Nama Instansi",
    input_telephone_number: "Input nomor Telephone",
    input_npwp_number: "Input nomor NPWP",
    input_bank_name: "Input Nama Bank",
    input_company_name: "Input Nama Perusahaan",
    enter_fo_name: "Masukkan Nama FO",
    input_reason_change_fo_status: "Input alasan ubah status FO.",
    empty_data: "Data kosong",
    input_description: "Input Deskripsi",
    select_branch: "Silahkan pilih cabang",
    input_nominal: "Tambahkan Nominal",
    input_no_hp: "Input nomor handphone",
    input_account_number: "Input Nomor Rekening",
    choose_drone_pilot_team: "Pilih tim drone pilot",
    input_lead_farmer_name: "Input Nama Calon Petani",
    input_team_name: "Input nama tim",
    input_team_member: "Input anggota tim",
    input_fo_name: "Input Nama Field Officer",
    select_plant_type: "Pilih type tanaman",
    select_fertilizer_form: "Pilih bentuk pupuk",
    insert_variant_name: "Masukkan nama varian",
    select_machine_owner: "Pilih pemilik mesin",
    select_machine_type: "Pilih tipe mesin",
    select_pesticide_form: "Pilih bentuk pestisida",
    select_fo_role: "Pilih Role FO",
    input_variant_name: "Tambahkan nama varian",
    input_package_name: "Input Nama Paket",
    input_package_desc: "Input deskripsi Paket",
    input_threatment_name: "Input nama threatment",
    input_first_hst: "Input HST Awal",
    input_last_hst: "Input HST Akhir",
    input_treatment_desc: "Input deskripsi threatment",
    input_dose_total: "Input Jumlah Dosis",
    input_resaon_change_status_user_cms: "Input alasan ubah status User CMS.",
    input_user_full_name: "Tambahkan nama user",
    choose_role_user: "Silahkan pilih role user",
    input_your_old_password: "Input Password Lama Anda",
    input_your_new_password: "Input Password Baru Anda",
    input_confirm_your_new_password: "Konfirmasi Password Baru Anda",
    input_youtube_video_link: "Silahkan tambahkan link video dari YouTube",
    input_youtube_video_title: "Judul Video mengikuti dari YouTube",
    choose_variety: "Pilih Varietas",
    select_seed_class: "Pilih Kelas Benih",
    select_planting_date: "Pilih tanggal tanam",
    select_seed_source_origin: "Pilih Asal Kelas Benih",
    input_count_seed: "Input Jumlah Benih",
    input_lot_number: "Input Nomor Lot",
    input_label_number: "Input nomor seri label",
    select_mitra: "Pilih Mitra",
    select_location: "Pilih Lokasi",
    select_plant_varieties: "Pilih Varietas Tanaman",
    search_area_name: "Cari nama lahan",
    reason_statement: "Keterangan Alasan",
    select_reporting_region: "Pilih regional pelaporan",
    enter_name: "Masukkan Nama",
    select_spray_date: "Pilih tanggal penyemprotan",
    enter_user_name: "Masukkan nama pengguna",
    input_search: "Masukkan Pencarian",
    input_video_title: "MAsukkan Judul Video",
    search_id_name_fo: "Cari ID FO / Nama FO",
    input_headline: "Masukkan headline",
    choose_one: "Pilih Salah Satu",
  },
  label: {
    clearing: "bongkar",
    select_maximu_120_day: "Pilih rentang tanggal maksimal 120 hari",
    download_history_note_view: "Riwayat berita acara akan diunduh dengan format csv.",
    download_history_note_view_description:
      "Riwayat dapat diunduh dengan rentang 120 hari dari tanggal dibuat.",
    land_clearing_data: "data bongkar",
    land_clearing_reasong: "Alasan Bongkar",
    certification_number: "Nomor Induk Sertifikasi",
    land_clearing_activity: "Aktivitas bongkar",
    download_history: "Unduh Riwayat",
    warning_select_activity:
      "Maksimal 20 aktivitas terpilih. Kurangi pilihan lahan anda sebelum lanjut.",
    send_to_bulk_finance: "Kirim ke Finance Massal",
    activity_selected_count: "{{count}} aktivitas terpilih",
    note_view_activity_report_v2:
      "Laporan aktivitas yang bersifat tagihan dan harus dikirim ke Finance hanya aktivitas pemupukan dan penyemprotan saja.",
    no_bill: "Tanpa tagihan",
    need_ba: "butuh berita acara",
    sending_finance: "Terkirim Finance",
    ready_send_finance: "Siap Kirim finance",
    edit_package: "PERBARUI PAKET",
    add_package: "Tambah Paket",
    total_pesticide: "Jumlah Pestisida",
    package_status: "Status Paket",
    total_treatment: "Jumlah Treatment",
    package_id: "ID Paket",
    spraying_package: "Paket Penyemprotan",
    detail_regional: "Detail Regional",
    farmer_id: "Id Petani",
    list_mitra_ca: "Daftar Mitra CA",
    farmer_list: "Daftar Petani",
    inactive: "Tidak Aktif",
    edit_sub_regional: "Edit Sub Regional",
    rejection_reason: "Alasan ditolak",
    edit_data_regional: "Edit Data Regional",
    regional_id: "ID regional",
    name: "Nama",
    province_name: "Nama Provinsi",
    rejected_date: "Tanggal ditolak",
    reject: "ditolak",
    sub_regional: "Sub Regional",
    sub_regional_name: "Nama Sub Regional",
    district_or_city: "Kabupaten / kota",
    add_sub_regional: "Tambah Sub Regional",
    id_sub_regional: "ID Sub Regional",
    sub_regional_amount: "Jumlah Sub Regional",
    active: "Aktif",
    address: "Alamat",
    report: "report",
    mitra_id: "ID Mitra",
    mitra_type: "Jenis Mitra",
    treatment_name: "Treatment Name",
    activity: "Aktivitas",
    activity_detail: "Detail Aktivitas",
    activity_type: "Jenis Aktivitas",
    activity_working_date: "Tanggal Aktivitas Dikerjakan",
    activity_picture: "foto aktivitas",
    area_name: "Lahan",
    area_information: "Informasi Lahan",
    area_address: "Alamat Lahan",
    activity_date: "Tanggal Aktivitas",
    area_location: "Lokasi Lahan",
    action: "action",
    bulk_scheduling: "Penjadwalan Massal",
    cabang: "Cabang",
    company_name: "Nama Perusahaan",
    company_phone: "No. Telephone Perusahaan",
    company_full_address: "Alamat Lengkap Perusahaan",
    company_email: "Email Perusahaan",
    company_document: "Dokumen Perusahaan",
    category_item: "Kategori Item",
    created_date: "Tanggal Dibuat",
    canceled_by: "Dibatalkan Oleh",
    date: "Tanggal",
    data_activity: "Data Aktivitas",
    data_rescheduled: "Data Jadwal Ulang",
    edit: "Edit",
    farmer: "Petani",
    farmer_name: "Nama Petani",
    full_address: "Alamat Lengkap",
    harvest_date: "Tanggal Panen",
    input_reason: "Input Alasan",
    land_area_worked: "Luas Lahan Dikerjakan",
    land_area_total: "Total Luas Lahan",
    land_conditions: "Kondisi Lahan",
    mitra: "Mitra",
    mitra_name: "Nama Mitra",
    manual_area_size: "Luas Lahan (Manual)",
    machine_owner: "Pemilik Alsintan",
    mac_profile_information: "Informasi Profil MAC",
    mac_user_information: "Informasi Pengguna MAC",
    mac_id_user: "ID Pengguna MAC",
    mac_user_name: "Nama Pengguna MAC",
    mac_full_address: "Alamat Pengguna MAC",
    mac_data_scheduled: "Data Pengajuan",
    scheduled_data: "Data Pengajuan",
    mac_data_activity: "Data Aktivitas",
    mac_data_rescheduled: "Data Jadwal Ulang",
    member: "anggota",
    nominal: "nominal",
    npwp: "Nomor NPWP",
    nik: "NIK",
    note: "Catatan",
    cp_name: "Narahubung",
    cp_phone: "No. Telephone",
    order_id: "ID Order",
    overview_activity: "Overview Aktivitas",
    overview_land_data: "Overview Data Lahan",
    operator: "Operator",
    others: "Lainnya",
    operator_name: "Nama Operator",
    plant_data: "Data Tanaman",
    plant_type: "Jenis Tanaman",
    plant_varieties: "Varietas Tanaman",
    planting_date: "Tanggal Tanam",
    plant_age: "Umur Tanaman",
    phone_number: "Nomor Handphone",
    province: "Provinsi",
    phone: "No. Telepon",
    pic: "PIC",
    pasca_planting: "Pasca Tanam",
    first_pic: "PIC Awal Terjadwal",
    regional: "Regional",
    request_id: "ID Pengajuan",
    request_detail: "detail pengajuan",
    pilot_name: "Nama Pilot",
    product: "Produk",
    pending: "Sedang Menunggu",
    payment_receipt: "bukti pembayaran",
    polygon_area_size: "Luas Lahan (Polygon)",
    upload_so: "unggah SO",
    total_activity: "Jumlah Kegiatan",
    type_schedule: "Tipe Pengajuan",
    warehouse: "Gudang Pestisida",
    worker_area_size: "Luas Lahan yang di kerjakan (Alsintan)",
    receipt_number: "No. Bukti",
    submit_spk: "KIRIM SPK",
    submission_data: "Data Pengajuan",
    select_date_range: "Pilih Range Tanggal",
    status: "Status",
    supporting_photo: "Foto Pendukung",
    scheduled: "Terjadwal",
    scheduling: "Penjadwalan",
    schedule_date: "Pengajuan Tanggal Aktivitas",
    submitted_by: "Diajukan Oleh",
    submitted_time: "Waktu Diajukan",
    scheduled_date: "Tanggal Terjadwal",
    first_scheduled_date: "Tanggal Awal Terjadwal",
    scheduled_by: "Dijadwalkan Oleh",
    scheduled_reason: "Alasan Pengajuan",
    user_name: "Nama Pengguna",
    spraying_pasca_planting: "Penyemprotan Pasca Tanam",
    spraying_activity: "Aktivitas Penyemprotan",
    spraying_activity_mac: "Aktivitas Penyemprotan MAC",
    spraying: "Penyemprotan",
    team_drone: "Tim Drone",
    team_name: "Nama Tim",
    reschedule: "Jadwal Ulang",
    rescheduled_date: "Tanggal Pengganti",
    submitted_date: "Tanggal Pengajuan",
    rescheduled_reason: "Alasan Jadwal Ulang",
    reason_reject: "Alasan Penolakan",
    reason_cancelation: "Alasan Pembatalan",
    reject_by: "ditolak oleh",
    overdue: "Terlewat",
    done: "Selesai",
    canceled: "Dibatalkan",
    rejected: "Ditolak",
    rejected_by: "Ditolak Oleh",
    reset_date: "Reset Tanggal",
    apply: "Terapkan",
    mac_user_merchant_name: "Nama Pengguna MAC",
    add_plant_type: "TAMBAH JENIS TANAMAN",
    edit_plant_type: "EDIT JENIS TANAMAN",
    insert_plant_type_name: "Masukkan nama jenis tanaman",
    input_plant_name: "Input Nama Tanaman",
    add_regional: "Tambah Regional",
    edit_regional: "Edit Regional",
    varietas: "Varietas",
    regional_name: "Nama Regional",
    list_province: "List Provinsi",
    insert_regional_name: "Input nama regional",
    spraying_schedule: "Jadwal Penyemprotan",
    preview: "Preview",
    detail_sub_regional: "List Sub Regional",
    spraying_activity_scheduling: "Penjadwalan Aktivitas Penyemprotan",
    review_and_submit: "Review & Submit",
    bulk_approval: "Penjadwalan Massal",
    land_area: "Luas Lahan",
    delete_sub_regional: "Hapus Sub Regional",
    add_treatment: "Tambah Treatment",
    edit_treatment: "Perbarui Treatment",
    id_treatment: "ID Treatment",
    hst: "HST",
    description: "Deskripsi",
    submitted: "Diajukan",
    received: "Diterima",
    submission_certification_block: "Pengajuan Sertifikasi & Blok",
    create_certification: "Buat Pengajuan Sertifikasi",
    canot_more_than: "Total lahan tidak boleh lebih dari 100.000 m² (10 Ha)",
    total_area: "Jumlah Lahan",
    female_planting_date: "Rencana Tanam Betina",
    see_map: "Lihat Peta",
    data_previous_plant: "Data Tanaman Sebelumnya",
    data_from_area_bigger:
      "Informasi diambil dari data Aktivasi Pembenihan dari ukuran lahan terbesar.",
    land_history: "Sejarah Lahan",
    former_bero: "Bekas Bero",
    disertifikasi: "Disertifikasi",
    seed_class: "Kelas Benih",
    field_inspection: "Pemeriksaan Lapangan",
    cancel_submission: "Batalkan Pengajuan",
    cancel_submission_certification: "BATALKAN PENGAJUAN SERTIFIKASI",
    submission_download: "Unduh Pengajuan",
    block_area: "Luas Blok",
    land_data: "Data Lahan",
    seed_source_data: "Data Asal Benih",
    base_seed_source: "Asal Sumber Benih",
    total_seed: "Jumlah Total Benih",
    female_seed: "Benih Betina",
    male_seed: "Benih Jantan",
    base_seed_name: "Nama Induk",
    no_lot: "Nomot Lot",
    count_seed: "Jumlah Benih",
    label_number: "Nomor Seri Label",
    label_images: "Foto Label",
    block_location: "Lokasi Blok Lahan",
    note_fo_qa: "Catatan dari FO Quality Assurance",
    planting_male_date: "Tanggal Tanam Jantan",
    planting_female_date: "Tanggal Tanam Betina",
    planting_date_data: "Data Tanggal Tanam",
    location_image: "Foto Lokasi",
    block_selected: "Blok Lahan Terpilih",
    sub_district: "Kelurahan",
    variety_name: "Nama Varietas",
    list_area: "Daftar Lahan",
    location: "Lokasi",
    land_area_m2: "Luas Lahan (m2)",
    min: "Minimal",
    max: "Maksimal",
    planting_status: "Status Tanam",
    ready_planting_season_confirmation: "Siap Konfirmasi Musim Tanam",
    reset: "Reset",
    confirmation_planting_season_bulk: "Konfirmasi MT Massal",
    plant: "Tanaman",
    planting_area: "Luas Penanaman",
    harvest_estimation: "Estimasi Panen",
    survey_date: "Tanggal Survey",
    harvest_age_estimation: "Estimasi Umur Tanaman (Panen)",
    harvest_method: "Metode Panen",
    alsintan_land_area_worked: "Luas Lahan Dikerjakan (Alsintan)",
    manual_land_area_worked: "Luas Lahan Dikerjakan (Manual)",
    mahcine_owner_name: "Nama Pemilik Alsintan",
    implement: "Implement",
    alsintan: "Alsintan",
    delivery_destination: "Tujuan Pengiriman",
    harvest_hours: "Jam Panen",
    delivery_document: "Surat Jalan",
    sj_number: "No. Surat Jalan",
    plat_number: "Nomor Plat Kendaraan",
    driver_name: "Nama Pengemudi",
    weight_slip_number: "No. Slip Timbang",
    net_harvest_weight: "Berat bersih panen",
    weight_slip_number_pass: "No. Slip Timbang (Lulus)",
    weight_slip_number_not_pass: "No. Slip Timbang (Tidak Lulus)",
    netto: "Berat Netto",
    pass_weight: "Berat Lulus",
    not_passed_weight: "Berat Tidak Lulus",
    created_by: "Dibuat Oleh",
    buyer_name: "Nama Pembeli",
    buyer_type: "Tipe Pembeli",
    age_at_activity: "Umur Saat Aktivitas",
    block_name: "Nama Blok",
    create_ba_land_clearing: "Buat Berita Acara (Bongkar)",
    select_active_area: "Pilih Lahan Aktif",
    fill_area_detail: "Isi Detail Lahan",
    total_selected_area: "Total Lahan Terpilih",
    number_selected_area: "Jumlah Lahan Terpilih",
    land_clearing_activity_date: "Tanggal Aktivitas Bongkar",
    area: "Luas",
    ownership: "Kepemilikan",
    land_ownership_proof: "Bukti Kepemilikan Lahan",
    planting_season_completed: "Musim Tanam Selesai",
    failed_harvest: "Gagal Panen",
    activity_history: "Riwayat Aktivitas",
    log: "Log",
    area_detail: "Detail Lahan",
    new_land_registration: "Registrasi lahan baru",
    approval_to_remove_farmers_from_the_land: "Persetujuan hapus petani dari lahan.",
    existing_fields_have: "Lahan yang ada memiliki",
    harvest_reports_and_have_been_fully_harvested: "laporan panen dan telah dipanen seluruhnya.",
    make_sure_the_land_to_be_confirmed_has_been_paid_in_full: "Pastikan lahan yang akan dikonfirmasi sudah lunas pembayaran.",
    choose_one: "Pilih Salah Satu",
    minimal_value: "Nilai Minimal",
    maximal_value: "Nilai Maksimal",

    city: "Kota",
    district: "Kecamatan",
    subdistrict: "Kelurahan",
    men: "Laki-Laki",
    woman: "Perempuan",
    farmer_detail: "Detail Petani",
    edit_farmer: "Edit Petani",
    edit_mitra: "Edit Mitra",
    change_farmer_mitra: "Silahkan tambah alasan mengubah Mitra.",
    select_farmer_mitra_assignment: "Silahkan pilih Mitra yang akan di assign ke Petani.",
    account_name: "Nama Pemilik Rekening",
    bank_name: "Nama Bank",
    account_number: "Nomor Rekening",
    pending_activity: "Kegiatan Pending",
    finish_activity: "Kegiatan Selesai",
    next_activity: "Kegiatan Selanjutnya",
    number_of_farmers: "Jumlah Petani",
    detail_mitra: "Detail Mitra",
    mitra_businnes: "Usaha Mitra",
    document: "Dokumen",
    mitra_reject_reason: "Silahkan tambah alasan menolak mitra",
    name_as_per_id: "Nama Sesuai KTP",
    ktp_number: "Nomor KTP",
    npwp_number: "No NPWP",
    ktp: "KTP",
    swafoto: "SWAFOTO",
    work_assignment_letter: "SURAT TUGAS KERJA",
    organizational_structure: "Struktur Organisasi",
    ktp_of_the_person_in_charge: "KTP PENANGGUNG JAWAB",
    deed_of_company_establishment: "Akta Pendirian Perusahaan",
    select_mitra_type: "Pilih Jenis Mitra",
    date_of_birth: "Tanggal Lahir",
    mitra_domicile_address: "Alamat Domisili mitra",
    postal_code: "Kode Pos",
    ktp_picture: "Foto KTP",
    mitra_data: "Data Mitra",
    telp_number: "Nomor Telefon",
    gender: "Jenis Kelamin",
    mitra_document: "Dokumen Mitra",
    selfie_with_ktp: "Swafoto dengan KTP",
    kk_picture: "Foto KK",
    npwp_picture: "Foto NPWP",
    bank_account: "Akun Bank",
    organization_name: "Nama Instansi",
    person_in_charge: "Penanggung Jawab",
    organization_address: "Alamat Instansi",
    organization_document: "Dokumen Instansi",
    photo_of_work_assignment_letter: "Foto Surat Tugas Kerja",
    organization_structure_photo: "Foto Struktur Organisasi",
    organization_bank_account: "Akun Bank Instansi",
    npwp_organization_number: "Nomor NPWP Instansi",
    npwp_organziation_photo: "Foto NPWP Instansi",
    company_address: "Alamat Perusahaan",
    company_bank_account: "Akun Bank Perusahaan",
    review_mitra_data: "Review Data Mitra",
    company_npwp_number: "Nomor NPWP Perusahaan",
    company_npwp_photo: "Foto NPWP Perusahaan",
    waiting: "Sedang Menunggu",
    registered: "Teregistrasi",
    area_lead: "Calon Lahan",
    scheduled_working_date: "Tanggal Aktivitas Terjadwal",
    completed_working_date: "Tanggal Aktivitas Dikerjakan",
    spraying_method: "Metode Penyemprotan",
    liquid_use: "Cairan Terpakai",
    pest_type: "Jenis Hama",
    reason: "Alasan",
    pesticide_name: "Nama Pestisida",
    pesticide_ownership: "Kepemilikan Pestisida",
    dose: "Dosis",
    quantity: "Pakai",
    pesticide_detail: "Rincian Pestisida",
    team_member: "Anggota Tim",
    total_activities: "Jumlah Aktivitas",
    total_revenue: "Total Pendapatan",
    confirmation_planting_season: "Konfirmasi Musim Tanam",
    reasons_for_harvest_failure: "Alasan Gagal Panen",
    pest: "Hama",
    choose_pest_type: "Pilih Jenis Hama",
    event_news_document: "Dokumen Berita Acara",
    photo_evidence: "Bukti Foto",
    max_8_photos_sub_label: "maks. 8 foto",
    note_description: "Keterangan",
    district_code: "Kode Kelurahan",
    farmer_data_type: "Jenis Data Petani",
    maxxi_tani_farmer: "Petani Maxxi Tani",
    lead_farmer: "Calon Petani",
    farmer_location: "Lokasi Petani",
    farmer_domicile_address: "Alamat Domisili Petani",
    lead_farmer_name: "Nama Calon Petani",
    assign_method: "Cara Assign",
    fo_name: "Nama FO",
    plant_form: "Bentuk Pupuk",
    variant_name: "Nama Varian",
    mesin_owner: "Pemilik Mesin",
    machine_type: "Tipe Mesin",
    machine_name: "Nama Mesin",
    frame_number: "No Rangka",
    engine_number: "No Mesin",
    pesticide_form: "Bentuk Pestisida",
    fo_address: "Alamat Field Officer",
    please_add_reason: "Silahkan tambah reason",
    please_edit_reason: "Silahkan edit reason.",
    package_name: "Nama Paket",
    from: "Dari",
    to: "To",
    dose_total: "Jumlah Dosis",
    edit_activity: "Edit Aktivitas",
    cancel_activity: "Batalkan Aktivitas",
    add_drone_team: "Tambah Tim Drone",
    user_information: "Informasi User",
    user_full_name: "Nama User",
    old_password: "Password Lama",
    new_password: "Password Baru",
    confirm_new_password: "Konfirmasi Password Baru",
    video_title: "Judul Video",
    video_category: "Kategori Video",
    detail_seed: "Detail Benih",
    seed_source: "Sumber Benih",
    seed_source_origin: "Asal Sumber Benih",
    seed_information: "Informasi Benih",
    lot_number: "Nomor Lot",
    total_count_seed: "Jumlah Benih Total",
    certification_form: "Formulir Sertifikasi",
    search_result: "Hasil Pencarian",
    select_all_area: "Pilih Semua Lahan",
    fill_out_form: "Isi Formulir",
    select_reporting_region_desc: "Daerah regional keuangan untuk pelaporan kegiatan",
    unassign_reason: "Alasan Unassign",
    selfie: "Swafoto",
    photo_profile: "Foto Profile",
    approved: "Disetujui",
    spary_date: "Tanggal Penyemprotan",
    fo_inactive: "FO tidak aktif",
    fo_active: "FO aktif",
    harvest_realization: "Realisasi Panen",
    harvest_age: "Umur Panen",
    number_of_sacks: "Jumlah Karung",
    moisture_content: "Kadar Air",
    waste: "Kotoran",
    weight: "Bobot",
    weight_slip: "Timbang Lapang Sorting",
    sack: "Karung",
    harvest_data: "Data Panen",
    type: "Tipe",
    price: "Harga",
    buyer_data: "Data Pembeli",
    sold_harvest_data: "Data Hasil Panen Terjual",
    weight_slip_data: "Data Slip Timbang",
    picture_of_weight_slip: "Foto Slip Timbang",
    estimated_land_area_worked: "Estimasi Luas Lahan Dikerjakan",
    requested_by: "Diminta oleh",
    id_user: "Id Pengguna",
    unassignment_reason: "Alasan Unassignment",
    no_order: "No. Order",
    no_spk: "No. SPK",
    no_sj: "No. SJ",
    no_faktur: "No. Faktur",
    last_30_days: "30 Hari Terakhir",
    last_90_days: "90 Hari Terakhir",
    total: "Jumlah",
    sequence: "Urutan",
    treatment_total: "Jumlah Treatment",
    add_video: "Tambah Video",
    ktp_selfie: "KTP & Swafoto",
    ktp_selfie_photo: "Foto KTP & Swafoto",
    bank_information: "Informasi Bank",
    review_individual_submit: "Review Individual & Submit",
    fo_list: "Daftar FO",
    drone_team: "Tim Drone",
    team_schedule: "Jadwal Tim",
    all: "Semua",
    pass: "Lulus",
    not_pass: "Tidak Lulus",
    price_per_kg: "Harga per Kg",
  },
  messages: {
    alert: {
      invalid_file_type: "tipe file tidak valid",
      max_mb: "max {{size}} mb",
      note_fo: `Lahan "{{block}}" tidak memenuhi kriteria untuk melakukan aktivitas pembenihan jagung hibrida.`,
      note_harvest_report_production:
        "Data slip timbang akan tersedia setelah tim harvest mengisi data slip timbang untuk lahan terkait.",
      note_slip_weight: "Data slip timbang yang ada berasal dari beberapa lahan sekaligus.",
      max_select_area: "Maksimal 20 lahan terpilih. Kurangi pilihan lahan anda sebelum lanjut.",
      netto_alert_weight_slip:
        "Berat Netto muncul jika slip timbang pada tiap aktivitas panen sudah ada.",
      buyed_all_by_farmer: "Semua hasil panen dibeli oleh petani lahan.",
      note_active_area_list_land_clearing:
        "Merupakan daftar lahan dengan laporan telah dikirim ke finance seluruhnya, pastikan lahan terpilih sudah lunas pembayaran.\nLahan terpilih akan direset musim tanam dengan status Bongkar.",
      failed_remove_assign_farmer_planting_activity: "Petani tidak dapat di hapus karena masih ada aktivitas tanam.",
      fo_cant_empty: "FO tidak boleh kosong",
    },
    title: {
      change_status_package: "UBAH STATUS PAKET",
      activity_send_to_erp: "KIRIM AKTIVITAS KE ERP",
      activity_select_regional: "REGIONAL PELAPORAN AKTIVITAS",
      approve_schedule: "APPROVE PENJADWALAN",
      cancel_schedule: "CANCEL PENJADWALAN",
      cancel_reschedule: "CANCEL EDIT PENJADWALAN",
      edit_order: "EDIT ORDER",
      reschedule_order: "RESCHEDULE ORDER",
      planting_season_confirmation: "KONFIRMASI MUSIM TANAM",
      reject_order: "REJECT ORDER",
      cancel_order: "ORDER CANCELATION",
      preview_information_regional: "Preview Informasi Regional",
      bulk_planting_season_confirmation: "KONFIRMASI MUSIM TANAM MASSAL",
      remove_farmer_from_area: "HAPUS PETANI Dari lahan",
      land_does_not_meet_the_criteria: "LAHAN TIDAK SESUAI KRITERIA",
      land_does_meet_the_criteria: "LAHAN SESUAI KRITERIA",
      land_hazard_potential: "Potensi Bahaya Lahan",
      farmer_reject_reason: "PETANI TIDAK SESUAI KRITERIA",
      farmer_approve: "PETANI SESUAI KRITERIA",
      erp_synchronization: "SINKRONISASI ERP",
      change_farmer_status: "UBAH STATUS PETANI",
      change_farmer_mitra: "UBAH MITRA PETANI",
      select_mitra_replacement: "PILIH MITRA PENGGANTI",
      mitra_reject_reason: "MITRA TIDAK SESUAI KRITERIA",
      mitra_approve: "MITRA SESUAI KRITERIA",
      change_mitra_status: "UBAH STATUS MITRA",
      reject_farmer_removal_request: "PERMINTAAN PENOLAKAN PEMINDAHAN PETANI",
      approve_farmer_removal_request: "PERMINTAAN PERSETUJUAN PEMINDAHAN PETANI",
      change_status_fo: "UBAH STATUS FO",
      add_reason: "TAMBAH REASON",
      remove_reason: "HAPUS REASON",
      confirmation: "KONFIRMASI",
      delete_video: "HAPUS VIDEO",
      new_clearing_report_ba: "BUAT BERITA ACARA BONGKAR",
      update_farmer_data: "UPDATE DATA PETANI",
      reset_password: "Reset Password",
      success: "Berhasil",
      remove_broadcast_message: "HAPUS BROADCAST MESSAGE",
      planting_season_information: "Informasi Musim Tanam"
    },
    confirmation: {
      edit_package:
        "Apakah anda yakin ingin memperbarui paket ini? Tindakan ini akan berpengaruh kepada semua data yang terkait dengan paket ini.",
      change_status_package:
        "Apakah anda yakin ingin menonaktifkan paket ini? Tindakan ini akan berpengaruh kepada semua data yang terkait dengan paket ini.",
      change_status_package_active:
        "Apakah anda yakin ingin mengaktifkan paket ini? Setelah diaktifkan, paket ini dapat digunakan kembali.",
      add_package:
        "Data paket yang sudah diisi akan masuk kedalam daftar paket. Pastikan data yang diisi sudah benar sebelum melanjutkan",
      edit_sub_regional:
        "Apakah anda yakin ingin memperbarui data sub regional ini? Tindakan ini akan berpengaruh kepada semua data yang terkait dengan sub regional ini.",
      edit_regional:
        "Apakah anda yakin ingin memperbarui data regional ini? Tindakan ini akan berpengaruh kepada semua data yang terkait dengan regional ini.",
      create_sub_regional:
        "Data sub regional yang sudah diisi akan masuk kedalam daftar sub regional terkait. Pastikan data yang diisi sudah benar sebelum melanjutkan",
      create_regional:
        "Data regional yang sudah diisi akan masuk kedalam daftar regional. Pastikan data yang diisi sudah benar sebelum melanjutkan",
      submit_add_regional: "Pastikan data regional yang di isi sudah benar sebelum melanjutkan.",
      activity_approve_to_erp_confirmation:
        "Dengan menyetujui aktivitas ini, maka data yang tersedia akan dikirim ke ERP. Pastikan seluruh data telah benar.",
      activity_approve_to_erp_confirmation_bulk:
        "Kegiatan yang dipilih akan di kirim ke sistem ERP (<strong>{{count}} Kegiatan</strong>). Pastikan semua data yang dipilih telah benar.",
      create_so:
        "Data yang diisi akan dikirim menjadi SPK dalam ERP. Pastikan semua data yang diinput telah benar.",
      create_so_success: "SO telah berhasil dibuat.",
      order_spraying_cancel:
        "Dengan membatalkan pengisian form, order tidak akan dijadwalkan dan semua data yang telah diisi akan hilang.",
      order_edit_spraying_cancel:
        "Dengan membatalkan edit form maka semua data yang telah diisi akan hilang.",
      order_spraying_approve:
        "Order yang di approve akan masuk kedalam tanggal penjadwalan. Pastikan data yang diinput telah benar.",
      order_edit: "Setelah menyetujui, data order akan berubah. Data yang berubah:",
      planting_season_confirmation_need_ba:
        "Dengan mengonfirmasi musim tanam, aktivitas musim tanam ini akan dipindahkan ke tab Riwayat Aktivitas. " +
        "Pastikan berita acara telah dibuat.",
      planting_season_confirmation:
        "Dengan mengonfirmasi musim tanam, aktivitas musim tanam ini akan dipindahkan ke tab Riwayat Aktivitas. ",
      team_edit_cancel:
        "Dengan membatalkan edit form maka semua data yang telah diisi akan hilang.",
      team_create_cancel:
        "Dengan membatalkan pengisian form, tim baru tidak akan dibuat dan semua data yang telah diisi akan hilang.",
      team_edit:
        "Setelah menyetujui, data tim drone akan berubah. Pastikan semua data telah diisi dengan benar.",
      team_create:
        "Dengan menambahkan tim drone, tim tersebut dapat dijadwalkan dalam aktivitas penyemprotan. Pastikan data yang diinput telah benar.",
      team_create_with_schedule:
        "Tim yang diedit masih memiliki jadwal penyemprotan dan Anggota tim yang baru akan mengikuti semua jadwal yang ada. Apakah anda yakin untuk mengganti anggota tim?",
      add_treatment:
        "Data treatment yang sudah diisi akan masuk kedalam daftar treatment terkait. Pastikan data yang diisi sudah benar sebelum melanjutkan",
      edit_treatment:
        "Apakah anda yakin ingin memperbarui treatment ini? Tindakan ini akan berpengaruh kepada semua data yang terkait dengan treatment ini.",
      cancel_submission:
        "Apakah anda yakin ingin membatalkan pengajuan lahan terpilih? Lahan yang dibatalkan akan kembali ke Field Officer.",
      bulk_planting_season_confirmation:
        "Dengan melakukan konfirmasi musim tanam massal, musim tanam lahan akan direset dan siap memulai musim tanam baru.",
      bulk_planting_season_confirmation_validation:
        "Pastikan lahan terpilih sudah benar dan lunas pembayaran.",
      cant_confirm_planting_season_plant_activity: "Musim tanam tidak bisa dikonfirmasi karena belum melakukan aktivitas panen.",
      planting_season_confirmation_question: "Apakah anda yakin mengkonfirmasi musim tanam?",
      planting_season_confirmation_question_info: "Informasi aktivitas akan dipindahkan ke tab <1>Riwayat Aktivitas</1>",
      planting_season_cancel_question: "Dengan membatalkan musim tanam, status tanam lahan yang terdaftar akan menjadi <1>Inactive.</1>",
      planting_season_cancel_question_info: "Pastikan lahan yang dipilih telah benar.",
      erp_synchronization_confirmation: "Apakah anda yakin ingin mengsinkronkan petani ini?",
      change_farmer_status_add_reason: "Silahkan tambah alasan ubah status Petani.",
      change_mitra_status_add_reason: "Silahkan tambah alasan ubah status Mitra.",
      log_will_be_recorded: "Setiap action akan terekam dalam log history.",
      add_reason_change_fo_status: "Silahkan tambah alasan ubah status FO, semua Mitra akan diunassign.",
      farmer_bank_account_warning: "Apabila bank telah dipilih maka nomor rekening & nama pemilik akun harus diisi",
      check_data_fo_mitra: "Pastikan data field officer dan mitra sudah sesuai.",
      new_password_from_user: "Password Baru dari User",
      add_reason_deactivate_user: "Silahkan tambah alasan deactivate User CMS.",
      delete_data_confirmation: "Apakah Anda yakin ingin menghapus data ini ?",
      delete_tutorial_video_confirmation: "Apakah anda yakin akan menghapus Video Tutorial? Data yang terhapus akan hilang.",
      common_data_correct_confirmation: "Apakah Anda yakin datanya sudah benar?",
      update_farmer_data_confirmation: "Apakah anda yakin mengedit data Petani? Pastikan semua data telah lengkap dan benar.",
      reset_password_confirmation: "Apakah Anda yakin ingin melakukan Reset Password User",
    },
    response: {
      area_unassigned_approve:
        "Unassign request disetujui dan status lahan berubah menjadi unassigned.",
      area_unassigned_reject:
        "Unassign request ditolak dan status lahan tetap menjadi teregistrasi.",
      activity_approve_to_erp_success: "Aktivitas berhasil dikirim ke ERP.",
      broadcast_success_delete: "Pesan broadcast berhasil dihapus.",
      broadcast_create_success: "Pesan broadcast berhasil dikirim.",
      data_not_found: "Data Tidak Ditemukan",
      erp_sync: "Sinkronisasi data petani ke ERP berhasil.",
      empty_state: "Data {{name}} kosong",
      searching_not_found: "Pencarian anda tidak ditemukan",
      empty_search_subtitle: "Silahkan periksa filter atau kata kunci dan coba lagi.",
      farmer_rejected: "Petani berhasil ditolak",
      farmer_approved: "Petani berhasil diverifikasi",
      farmer_edit_success: "Data petani berhasil diperbarui",
      farmer_create_success: "Petani berhasil diregistrasi.",
      order_rejected: "Aktivitas berhasil ditolak.",
      order_cancel: "Aktivitas berhasil dibatalkan.",
      order_success: "Aktivitas berhasil dijadwalkan.",
      order_success_edit: "Aktivitas berhasil diedit.",
      success_approve_area: "Lahan berhasil diverifikasi",
      team_drone_change_success: "Tim drone telah berhasil diperbarui.",
      team_drone_add_success: "Tim drone telah berhasil ditambah.",
      success_create_plant_type: "Tanaman berhasil ditambah",
      success_edit_plant_type: "Tanaman berhasil diedit",
      success_create_regional: "Sub regional berhasil ditambah.",
      success_edit_regional: "Pembaruan regional berhasil.",
      success_edit_sub_regional: "Pembaruan sub regional berhasil.",
      response_not_found_data: "Pencarian anda tidak ditemukan",
      sub_response_not_found_data: "Silahkan periksa filter atau kata kunci dan coba lagi.",
      success_download_submission: "Pengunduhan pengajuan sertifikasi berhasil.",
      success_download_history_clearing_ba: "Riwayat berita acara bongkar berhasil di unduh",
      success_create_clearing_report: "Laporan berita acara bongkar berhasil dibuat.",
      no_activity_history_yet: "Belum ada riwayat aktivitas.",
      no_land_activities_yet: "Belum ada aktivitas lahan.",
      there_have_been_no_recent_logs_in_3_months: "Belum ada log terbaru dalam 3 bulan.",
      log_search_not_found: "Pencarian log tidak ditemukan",
      please_check_the_filter_and_try_again: "Silahkan periksa filter dan coba lagi.",
      reason_not_found: "Alasan tidak ditemukan.",
      success_remove_assign_farmer: "Petani dihapus dari lahan. Data lahan berhasil diperbarui",
      success_reject_area: "Lahan berhasil ditolak",
      success_verify_area: "Lahan berhasil diverifikasi",
      empty_area_farmer: "Petani Belum Memiliki Data Lahan",
      lead_farmer_data_added_successfully: "Data calon petani berhasil ditambah",
      field_officer_successfully_deactivated: "Field Officer berhasil dinonaktifkan.",
      assign_fo_mitra_success: "FO berhasil diassign ke mitra",
      fo_assign_success: "Assign Mitra berhasil.",
      sync_pesticide_successfully: "Sync Pestisida Berhasil",
      successfully_added_reason: "Reason berhasil di tambahkan",
      no_item_yet: "Belum ada Item.",
      successfully_removed_reason: "Reason berhasil dihapus",
      successfully_updated_reason: "Reason berhasil diperbaharui",
      cms_user_successfully_deactivate: "user CMS berhasil dinonaktifkan.",
      fo_data_updated_successfully: "Data FO berhasil diperbarui",
      fo_data_successfully_created: "Data FO berhasil dibuat",
      successfully_declared_a_crop_failure: "Lahan berhasil dinyatakan gagal panen.",
      tutorial_video_deleted: "video Tutorial berhasil dihapus",
      inactive_package_success: "Paket berhasil dinonaktifkan",
      active_package_success: "Paket berhasil diaktifkan",
      edit_cms_role_success: "Data user CMS berhasil diperbarui",
      add_seed_success: "Benih berhasil ditambahkan.",
      treatment_empty: "Belum ada Treatment",
      update_package_data_success: "Pembaruan data paket berhasil",
      add_package_data_success: "Data paket berhasil ditambah.",
      new_password_success: "Password Baru dari User",
      update_access_right_success: "Hak akses berhasil diedit.",
      edit_video_tutorial_success: "video Tutorial berhasil diedit",
      add_video_tutorial_success: "video Tutorial berhasil ditambahkan",
    }
  },
  profile: {
    label_profile: "Profil Pengguna",
    label_language: "Pilih Bahasa"
  },
  sales_order: {
    title: "Sales Order",
    table_label_order_id: "ID Order",
    table_label_area_name: "Nama Area",
    table_label_farmer: "Petani",
    table_label_activity_type: "Jenis Aktivitas",
    table_label_created_date: "Tanggal di buat",
    table_label_no_spk: "No SPK",
    order_information: "INFORMASI ORDER",
    title_detail_spk: "DETAIL SURAT PERINTAH KERJA"
  },
  table_header: {
    area_name: "Nama Lahan",
    block_name: "Nama Blok",
    activity_type: "Jenis Aktivitas",
    created_date: "Tanggal Dibuat",
    farmer: "Petani",
    mitra: "Mitra",
    planting_type: "Jenis Tanaman",
    status: "Status",
    request_id: "ID PENGAJUAN",
    user_mac: "Pengguna MAC ",
    land_area: "LUAS LAHAN",
    full_address_mac: "Alamat Pengguna MAC",
    plant_type: "Jenis Tanaman",
    plant_age: "Umur Tanaman",
    request_date: "TANGGAL PENGAJUAN",
    activity_date: "Tanggal Aktivitas",
    overdue_date: "HARI TERLEWAT",
    plant_name: "Nama Tanaman",
    user: "Pengguna",
    canceled_date: "TANGGAL DIBATALKAN",
    reject_date: "Tanggal Ditolak",
    profile_mac: "PROFIL MAC",
    scheduled_by: "Dijadwalkan oleh",
    scheduled_date: "Tanggal Dijadwalkan",
    submit_by: "Diajukan Oleh",
    canceled_by: "Dibatalkan oleh",
    reject_by: "Ditolak oleh",
    regional_id: "ID regional",
    province: "Provinsi",
    sub_regional_amount: "Jumlah sub regional",
    land_area_only: "Luas",
    varietas: "Varietas",
    plant_variety: "varietas tanaman",
    female_date: "Rencana Tanam Betina",
    submission_date: "Tanggal Diajukan",
    submitted_by: "Diajukan Oleh",
    female_planting_date: "Tanggal Tanam Betina",
    created_by: "Dibuat Oleh",
    received_by: "Diterima Oleh",
    received_date: "Tanggal Diterima",
    harvest_date: "Tanggal Panen",
    action: "Action",
    planting_date: "Tanggal Tanam",
    variety_name: "Nama Varietas",
    all: "Semua",
    area_lahan: "Lahan",
    kur_area: "Lahan KUR",
    status_area: "Status Lahan",
    planting_status: "Status Tanam",
    submitted_date: "Tanggal Submit",
    reject_reason: "Alasan Penolakan",
    // reject_date: "Tanggal Reject",
    area_address: "Alamat Lahan",
    submitted_by_area: "Disubmit Oleh",
    name: "Nama",
    address: "Alamat",
    farmer_id: "Id Petani",
    machine_name: "Nama Mesin",
    machine_type: "Tipe Mesin",
    engine_number: "No Mesin",
    frame_number: "No Rangka",
    machine_owner: "Pemilik Mesin",
    variant_name: "Nama Varian",
    pesticide_form: "Bentuk Pestisida",
    mitra_total: "Jumlah Mitra",
    category_name: "Nama Kategori",
    reason_total: "Jumlah Reason",
    role_name: "Nama Role",
    email: "Email",
    regional: "Regional",
    role: "Role",
    headline: "Headline",
    receiver: "Receiver",
    submitted_time: "Waktu Diajukan",
    package_id: "ID Paket",
    package_name: "Nama Paket",
    total_treatment: "Jumlah Treatment",
  },
  dropdown: {
    remove_farmer: "Remove Petani",
    confirm_the_planting_season: "Konfirmasi Musim Tanam",
    cancel_planting_season: "Batal Musim Tanam"

  },
  card_title: {
    planting_season_information: "Informasi Musim Tanam",
    area_location: "Lokasi Lahan",
    reason_of_rejection: "Alasan Penolakan",
    account_information: "Informasi Rekening",
    person_in_charge: "Penanggung Jawab",
    status_change_reason: "Alasan Perubahan Status",
    farmer_data: "Data Petani",
    farmer_bank_account: "Akun Bank Petani",
    ktp_selfie_farmer: "KTP & SWAFOTO DENGAN KTP PETANI",
    ktp_selfie: "KTP & SWAFOTO DENGAN KTP",
    ktp_selfie_mitra: "KTP & SWAFOTO MITRA",
    pesticide_information: "Informasi Pestisida",
    seed_information: "Informasi Benih",
    review_farmer_data: "Review Data Petani",
    fertilizer_information: "Informasi Pupuk",
    fertilizer_form: "Bentuk Pupuk",
    sub_district: "Kelurahan",
    farmer: "Petani",
    photo_profile: "Foto Profile",
    mitra_list: "Daftar Mitra",
    harvest_estimation_activity: "Aktivitas Estimasi Panen",
    edit_user_cms: "Edit User CMS",
    add_user_cms: "Tambah User CMS",
  },
  selected_area_list: "Daftar Lahan Terpilih",
  event_report: "Berita Acara"
}
