import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import React, { useEffect, useState } from "react"
import { useParams } from "react-router"
import { useHistory } from "react-router-dom"
import { Card, OverlayTrigger, Tooltip } from "react-bootstrap"
import { RoutesConstants } from "../../../../../../constants/RoutesConstants.js"
import { DetailSvgIcon } from "../../../../../../component/atoms/Icons/SVG/index.jsx"
import { CardBody } from "../../../../../../_metronic/_partials/controls/index.js"
import { EmptyCard, TableSkeleton, TableView } from "../../../../../../component/index.jsx"
import { formatterRowComponent } from "../../../../../../component/atoms/Tabel/index.jsx"
import { sortCaret } from "../../../../../../_metronic/_helpers/index.js"
import { checkValue, toHectare, toMeter } from "../../../../../helpers/TextHelper.js"
import { timeStampToDate } from "../../../../../helpers/DateHelper.js"
import {
  ICON_CONSTANS,
  ILLUSTRATION_ASSETS_V2
} from "../../../../../../constants/ImageConstants.js"
import { FloatingSelectArea } from "../../../../../../component/atoms/FloatingSelectArea/index.jsx"
import { CloudDownloadOutlined } from "@material-ui/icons"
import {
  clearDEtailDownloadSubmission,
  getDetailDownloadSubmission
} from "../../../../../../redux/actions/SubmissionAction.jsx"
import { toast } from "react-toastify"
import SVG from "react-inlinesvg"
import { ApplicationSeedCertificationFormDownload } from "../../../../../../component/molecule/Print/ApplicationSeedCertificationFormDownload.jsx"
import { DATE_TYPE_ENUM, ROUTING_STATUS } from "../../../../../../constants/StringConstant.js"
import { PRIVILEGE_ENUM } from "../../../../../../constants/PrivilegeConstants.js"

export const CertificationBlock = ({ changePage, data, activeFilter, privilege }) => {
  const { t } = useTranslation()
  const params = useParams()
  const history = useHistory()
  const dispatch = useDispatch()
  const { General, Submission } = useSelector((state) => state)
  const [selectedRows, setSelectedRows] = useState([])
  const [index, setIndex] = useState(0)
  const [createdBy, setCreatedBy] = useState(null)
  const [createdDate, setCreatedDate] = useState(null)

  useEffect(() => {
    dispatch(clearDEtailDownloadSubmission())
  }, [])

  useEffect(() => {
    const prevTitle = document.title
    if (Submission?.detailDownloadSubmission?.length > 0) {
      Submission.detailDownloadSubmission?.map((item, index) => {
        setTimeout(() => {
          setIndex(index)
          document.title = `${item.block_code}`
          window.print()
          setTimeout(() => {
            toast(t("messages.response.success_download_submission"), {
              position: "top-center",
              autoClose: 5000,
              style: {
                background: "#353535"
              },
              icon: <SVG src={ICON_CONSTANS.IC_BULLET_TICK} />,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              theme: "dark"
            })
            dispatch(clearDEtailDownloadSubmission())
          }, 1000)
        }, 5000)
      })
    }
    return () => {
      document.title = prevTitle
    }
  }, [Submission?.detailDownloadSubmission])

  useEffect(() => {
    if (params?.tab === ROUTING_STATUS.CERTIFICATION_STATUS_STRING.SUBMITTED) {
      return (
        setCreatedBy(t("table_header.created_by")), setCreatedDate(t("table_header.created_date"))
      )
    } else if (params?.tab === ROUTING_STATUS.CERTIFICATION_STATUS_STRING.REJECTED) {
      return (
        setCreatedBy(t("table_header.reject_by")), setCreatedDate(t("table_header.reject_date"))
      )
    } else {
      return (
        setCreatedBy(t("table_header.received_by")), setCreatedDate(t("table_header.received_date"))
      )
    }
  }, [params?.tab])

  const handleBulkDownloadSubmission = () => {
    if (selectedRows.length) {
      const body = {
        block_ids: selectedRows.map((e) => e.block_id)
      }
      dispatch(getDetailDownloadSubmission(body))
    }
  }

  function uiCode(e) {
    return (
      <>
        <div className={"text-dark font-weight-bolder  font-size-lg"}>
          {checkValue(e?.block_code)}
        </div>
        <div className={"d-flex"}>
          <div>{checkValue(e?.sub_district_name)}</div>
        </div>
      </>
    )
  }

  function uiMitra(e) {
    return (
      <div>
        <div className={"text-dark font-weight-bolder  font-size-lg mb-1"}>
          {checkValue(e?.mitra_name)}
        </div>
        <div>{checkValue(e?.mitra_code)}</div>
      </div>
    )
  }

  function uiArea(e) {
    return (
      <div>
        <div className={"text-dark font-weight-bolder  font-size-lg mb-1"}>
          {toMeter(checkValue(e?.land_area_meter))}
        </div>
        <div>{toHectare(checkValue(e?.land_area_ha))}</div>
      </div>
    )
  }

  function uiVarietas(e) {
    return (
      <>
        <div>{checkValue(e?.variety_name)}</div>
      </>
    )
  }

  function uiPlantingDate(e) {
    return (
      <>
        <div>{checkValue(timeStampToDate(e?.planting_date, DATE_TYPE_ENUM.ISO_DATE_STRAP))}</div>
      </>
    )
  }

  function uiDate(e) {
    return (
      <div>
        <div>{checkValue(timeStampToDate(e?.submitted_date, DATE_TYPE_ENUM.ISO_DATE_STRAP))}</div>
        <div>{checkValue(timeStampToDate(e?.submitted_date, DATE_TYPE_ENUM.ISO_TIME_ID))}</div>
      </div>
    )
  }

  function uiCreateBy(e) {
    return (
      <div>
        <div className={"text-dark font-weight-bolder font-size-lg mb-1"}>
          {checkValue(e?.submitted_by)}
        </div>
        <div>{checkValue(e?.submitted_role)}</div>
      </div>
    )
  }

  const uiAction = (e) => {
    return (
      <div>
        <OverlayTrigger overlay={<Tooltip id="products-detail-tooltip">Detail</Tooltip>}>
          <div
            onClick={() => {
              history.push(
                RoutesConstants.SUBMISSION.DETAIL_BLOCK_CERTIFICATION(params?.tab, e?.block_id)
              )
            }}
            className={"btn btn-icon btn-list btn-hover-secondary btn-sm mx-3"}>
            <DetailSvgIcon />
          </div>
        </OverlayTrigger>
      </div>
    )
  }

  const columns = [
    {
      dataField: "code",
      text: t("table_header.block_name"),
      sort: true,
      formatter: formatterRowComponent,
      formatExtraData: {
        externalStyle: uiCode
      },
      headerStyle: () => {
        return { width: "15%" }
      },
      sortCaret: sortCaret
    },
    {
      dataField: "mitra",
      text: t("table_header.mitra"),
      sort: false,
      formatter: formatterRowComponent,
      formatExtraData: {
        externalStyle: uiMitra
      }
    },
    {
      dataField: "totalAreaLand",
      text: t("table_header.land_area_only"),
      sort: true,
      formatter: formatterRowComponent,
      formatExtraData: {
        externalStyle: uiArea
      },
      headerStyle: () => {
        return { width: "15%" }
      },
      sortCaret: sortCaret
    },
    {
      dataField: "varietas",
      text: t("table_header.varietas"),
      sort: false,
      formatter: formatterRowComponent,
      formatExtraData: {
        externalStyle: uiVarietas
      }
    },
    {
      dataField: "AC.activityDate",
      text: t("table_header.planting_date"),
      sort: true,
      formatter: formatterRowComponent,
      formatExtraData: {
        externalStyle: uiPlantingDate
      },
      headerStyle: () => {
        return { width: "15%" }
      },
      sortCaret: sortCaret
    },
    {
      dataField: "createdDate",
      text: createdDate,
      sort: true,
      formatter: formatterRowComponent,
      formatExtraData: {
        externalStyle: uiDate
      },
      headerStyle: () => {
        return { width: "10%" }
      },
      sortCaret: sortCaret
    },
    {
      dataField: "submitted_by",
      text: createdBy,
      sort: false,
      formatter: formatterRowComponent,
      formatExtraData: {
        externalStyle: uiCreateBy
      },
      headerStyle: () => {
        return { width: "10%" }
      }
    },
    privilege.indexOf(PRIVILEGE_ENUM.CERTIFICATION_DETAIL_SUBMISSION) > -1 && {
      dataField: "action",
      text: "ACTION",
      sort: false,
      formatter: formatterRowComponent,
      formatExtraData: {
        externalStyle: uiAction
      }
    }
  ]

  return (
    <>
      {Submission?.detailDownloadSubmission?.length > 0 && (
        <ApplicationSeedCertificationFormDownload
          data={Submission?.detailDownloadSubmission[index]}
        />
      )}
      <div className={"d-print-none"}>
        {selectedRows.length > 0 && (
          <FloatingSelectArea
            totalArea={selectedRows.length}
            buttonlabel={t("label.submission_download")}
            buttonColor={"primary"}
            label={t("label.block_selected")}
            frontIcon={<CloudDownloadOutlined className={"mr-4"} />}
            handleClick={() => {
              handleBulkDownloadSubmission()
            }}
          />
        )}
        <Card>
          <CardBody>
            {General.loading ? (
              <TableSkeleton column={8} row={10} />
            ) : data?.pageData?.length === 0 ? (
              <EmptyCard
                image={
                  activeFilter
                    ? ILLUSTRATION_ASSETS_V2.IL_NOT_FOUND_DATA
                    : ILLUSTRATION_ASSETS_V2.IL_EMPTY_STATE_DATA
                }
                body={
                  activeFilter
                    ? t("messages.response.response_not_found_data")
                    : t("messages.response.empty_state", { name: "blok" })
                }
                subtitle={activeFilter ? t("messages.response.sub_response_not_found_data") : ""}
              />
            ) : (
              ""
            )}
            <TableView
              dataTable={data?.pageData ?? []}
              loadingGetData={false}
              callbackAfterPageChange={changePage}
              columnProperties={columns}
              currentPage={data?.page?.currentPage}
              currentElement={data?.page?.currentElement}
              totalCount={data?.page?.totalCount}
              rowClasses={() => {
                return "cursor-pointer"
              }}
              keyField={"block_id"}
              selectable={privilege.indexOf(PRIVILEGE_ENUM.CERTIFICATION_DOWNLOAD_SUBMISSION) > -1}
              onSelect={(row, isSelected) => {
                let rows = JSON.parse(JSON.stringify(selectedRows))
                if (isSelected) {
                  rows.push(row)
                } else {
                  rows = rows.filter((r) => r.block_id !== row.block_id)
                }
                setSelectedRows(rows)
              }}
              onSelectAll={(isSelect, rows) => {
                let rowses = JSON.parse(JSON.stringify(selectedRows))
                if (isSelect) {
                  if (rows?.length > 0) {
                    rows?.map((e) => {
                      rowses.push(e)
                    })
                  }
                } else {
                  if (rows?.length) {
                    let rowsesNew = rowses
                    rows?.map((row) => {
                      const removeIndex = rowses.findIndex((e) => e?.block_id === row?.block_id)
                      rowsesNew.splice(removeIndex, 1)
                    })
                    rowses = rowsesNew
                  }
                }
                setSelectedRows(rowses)
              }}
            />
          </CardBody>
        </Card>
      </div>
    </>
  )
}
