import { useDispatch, useSelector } from "react-redux"
import { Card, CardBody, CardHeader } from "../../../../../_metronic/_partials/controls"
import React, { useEffect, useState } from "react"
import { useFormikContext } from "formik"
import { RowModule } from "../../../../../component/atoms/RowModule/RowModule.jsx"
import { SelectInput, TextInput } from "../../../../../component/index.jsx"
import { fetchBanks } from "../../../../../redux/actions/MasterDataAction.jsx"
import { useTranslation } from "react-i18next"

export const AccountBankGov = ({ isReadOnly }) => {
  const { values: formValues } = useFormikContext()

  const { MasterData } = useSelector((state) => state)
  const dispatch = useDispatch()
  const [bankId, setBankId] = useState(formValues["bank_id"])
  const [bankList, setBankList] = useState([])
  const {t} = useTranslation()

  useEffect(() => {
    dispatch(fetchBanks())
  }, [])

  useEffect(() => {
    if (formValues.hasOwnProperty("bank_id")) {
      setBankId(formValues["bank_id"])
    }
  }, [formValues["bank_id"]])

  useEffect(() => {
    if (MasterData.bankList.length !== 0) {
      setBankList(
        MasterData.bankList.map((value) => ({
          label: value.name,
          value: value.id
        }))
      )
    }
  }, [MasterData.bankList])

  function onChangeBank(e) {
    setBankId(e)
  }

  return (
    <Card>
      <CardHeader title={t("label.organization_bank_account").toUpperCase()} />
      <CardBody>
        <RowModule>
          <>
            <SelectInput
              name="bank_id"
              onChange={onChangeBank}
              editable={!isReadOnly}
              required
              label={t("label.bank_name")}
              placeholder={t("placeholder.input_bank_name")}
              value={bankId}
              options={bankList}
            />
          </>

          <>
            <TextInput
              editable={!isReadOnly}
              required
              label={t("label.account_number")}
              name="bank_account_number"
              placeholder={t("placeholder.input_bank_number")}
              value={!formValues?.bank_id && ""}
            />
          </>
        </RowModule>

        <RowModule>
          <>
            <TextInput
              editable={!isReadOnly}
              label={t("label.account_name")}
              required
              name="bank_account_name"
              placeholder={t("placeholder.input_account_name")}
              value={!formValues?.bank_id && ""}
            />
          </>
        </RowModule>
      </CardBody>
    </Card>
  )
}