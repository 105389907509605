import React from "react"
import { AREA_ACTIVITY_TYPE } from "../../../constants/InitTypeConstants"
import { checkValue } from "../../../app/helpers/TextHelper.js"
import { useTranslation } from "react-i18next"

export function ErpNumber({ noOrderErp, noOrderSpk, noOrderSj, noFaktur, status }) {
  const {t} = useTranslation()
  return (
    <div className={"mt-2 d-flex"} style={{ gap: 12 }}>
      <div className={""}>
        <span className={"text-black-50"}>{t("label.no_order")} : </span>
        {checkValue(noOrderErp)}
      </div>

      <div className={"border-right w-1"}></div>

      <div>
        <span className={"text-black-50"}>{t("label.no_spk")} : </span>
        {checkValue(noOrderSpk)}
      </div>
      <div className={"border-right w-1"}></div>
      {(status === AREA_ACTIVITY_TYPE.SPRAYING || status === AREA_ACTIVITY_TYPE.PRE_PLANTING) && (
        <>
          <div>
            <span className={"text-black-50"}>{t("label.no_sj")} : </span>
            {checkValue(noOrderSj)}
          </div>
          <div className={"border-right w-1"}></div>
        </>
      )}
      <div>
        <span className={"text-black-50"}>{t("label.no_faktur")} : </span>
        {checkValue(noFaktur)}
      </div>
    </div>
  )
}
