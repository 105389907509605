import { useDispatch, useSelector } from "react-redux"
import { useFormikContext } from "formik"
import React, { useEffect, useState } from "react"
import {
  getPestTypeList,
  getReasonFailedHarvest,
  resetUrlResponse,
  uploadAction,
  uploadImageListAction
} from "../../../../../redux/actions/MasterDataAction"
import { Card, CardBody } from "../../../../../_metronic/_partials/controls"
import { RowModule } from "../../../../../component/atoms/RowModule/RowModule.jsx"
import { SelectInput, TextAreaInput, UploadComponent } from "../../../../../component"
import { handleSpace } from "../../../../helpers/TextHelper"
import { SelectMultipleChip } from "../../../../../component/molecule/SelectMultipleChip/SelectMultipleChip"
import { VARIABLE_CONSTANT } from "../../../../../constants/VariableConstant"
import { AddImage, UploadIcon } from "../../../../../component/atoms/Icons/SVG/upload"
import { UPLOAD } from "../../../../../constants/InitTypeConstants"
import { REASON_FAILURE_ENUM } from "../../../../../constants/StringConstant.js"
import { useTranslation } from "react-i18next"

export const FormViewFailed = ({ isReadOnly, setBtnDisable }) => {
  const dispatch = useDispatch()
  const { MasterData, General } = useSelector(state => state)
  const { values: formValues } = useFormikContext()
  const {t} = useTranslation()
  const [reason, setReason] = useState()
  const [stateReason, setStateReason] = useState([])
  const [pestTypeList, setPestTypeList] = useState([])
  const [fileAttachments, setFileAttachments] = useState([])
  const [sizeFiles, setSizeFiles] = useState(0)
  const [imageList, setImageList] = useState([])

  useEffect(() => {
    dispatch(getReasonFailedHarvest())
    dispatch(getPestTypeList())
  }, [])

  useEffect(() => {
    if (MasterData.reasonList) {
      setStateReason(
        MasterData.reasonList.map(value => ({
          label: value.enum_string,
          value: value.enum_value
        }))
      )
    }
  }, [MasterData.reasonList])

  useEffect(() => {
    if (MasterData.pestTypeList) {
      setPestTypeList(
        MasterData.pestTypeList.map(value => ({
          label: value.name,
          value: value.id,
          checked: false
        }))
      )
    }
  }, [MasterData.pestTypeList])

  useEffect(() => {
    if (formValues.reason_failure_enum && formValues.document_name && imageList.length) {
      if (formValues.reason_failure_enum === REASON_FAILURE_ENUM.PEST_ATTACK) {
        if (formValues.pest_type.length > 0) {
          setBtnDisable(false)
        } else {
          setBtnDisable(true)
        }
      } else if (formValues.reason_failure_enum === REASON_FAILURE_ENUM.OTHER) {
        if (formValues.other_reason.length >= 5) {
          setBtnDisable(false)
        } else {
          setBtnDisable(true)
        }
      } else {
        setBtnDisable(false)
      }
    } else {
      setBtnDisable(true)
    }
  }, [formValues, imageList, fileAttachments])

  useEffect(() => {
    if (MasterData.urlResponseUpload) {
      if (!General.loading) {
        if (MasterData.uploadType === UPLOAD.BUSINESS_DOCUMENT) {
          formValues.document_url = MasterData.urlResponseUpload
          formValues.document_size = sizeFiles
        }
        if (MasterData.uploadType === UPLOAD.AVATAR) {
          setImageList([...imageList, ...MasterData.urlResponseUpload])
        }
      }

      setTimeout(() => {
        dispatch(resetUrlResponse())
      }, 200)
    }
  }, [MasterData.urlResponseUpload, General.loading, MasterData.uploadType])

  useEffect(() => {
    formValues.image_url = imageList
  }, [imageList])

  const onChangerReason = e => {
    setReason(e)
  }

  const handleSetPestType = e => {
    formValues.pest_type = e
  }

  const onChangeFileUpload = e => {
    setFileAttachments([e])
    setSizeFiles(e.size)
    dispatch(uploadAction({ data: e, type: UPLOAD.BUSINESS_DOCUMENT }))
  }
  const onRemove = () => {
    setFileAttachments([])
    formValues.document_url = ""
    formValues.document_name = ""
  }

  const onChangeImageUpload = e => {
    dispatch(uploadImageListAction({ data: e, type: UPLOAD.AVATAR }))
  }

  const onRemoveImage = i => {
    const imgList = imageList
    imgList.splice(i, 1)
    setImageList([...imgList])
  }

  return (
    <>
      <Card className="card-custom card-shadowless">
        <CardBody>
          <h4 className="card-label mb-12">GAGAL PANEN</h4>
          <RowModule customColumnCss="mb-5 col-lg-12 col-sm-12 col-md-12">
            <SelectInput
              name="reason_failure_enum"
              onChange={onChangerReason}
              editable={true}
              label={t("label.reasons_for_harvest_failure")}
              placeholder={t("placeholder.choose_one")}
              options={stateReason}
              withoutFeedback={false}
              clearNow={true}
              value={reason}
              required={true}
            />
          </RowModule>
          {formValues.reason_failure_enum === REASON_FAILURE_ENUM.PEST_ATTACK && (
            <RowModule customColumnCss="mb-5 col-lg-12 col-sm-12 col-md-12">
              <SelectMultipleChip
                name={"pest_type"}
                label={t("label.pest")}
                labelButton={t("button.add_pest_type")}
                titleModal={t("label.choose_pest_type")}
                required={true}
                formValue={formValues.pest_type}
                editable={true}
                options={pestTypeList}
                setPestType={e => handleSetPestType(e)}
              />
            </RowModule>
          )}
          {formValues.reason_failure_enum === REASON_FAILURE_ENUM.OTHER && (
            <RowModule customColumnCss="mb-5 col-lg-12 col-sm-12 col-md-12">
              <TextAreaInput
                editable={isReadOnly}
                label=""
                name="other_reason"
                placeholder={t("placeholder.input_description")}
                type={"text"}
                onKeyDown={handleSpace}
              />
            </RowModule>
          )}
          <RowModule customColumnCss="mb-5 col-lg-12 col-sm-12 col-md-12">
            <UploadComponent
              label={t("label.event_news_document")}
              required={true}
              name="document_name"
              onDeleteFileList={onRemove}
              fileList={fileAttachments}
              typeUpload={"pdf"}
              editable={true}
              onChange={onChangeFileUpload}
              placeholder={<UploadIcon />}
              maxSize={VARIABLE_CONSTANT.MAX_FILE_UPLOAD_PDF}
              maxSizeText={VARIABLE_CONSTANT.MAX_SIZE_PDF}
            />
          </RowModule>
          <RowModule customColumnCss="mb-5 col-lg-12 col-sm-12 col-md-12">
            <UploadComponent
              label={t("label.photo_evidence")}
              subLabel={`(${t("label.max_8_photos_sub_label")})`}
              required={true}
              name="image"
              onDeleteFileList={onRemoveImage}
              fileList={imageList}
              typeUpload={"image-list"}
              editable={true}
              onChange={onChangeImageUpload}
              placeholder={<AddImage />}
              maxFile={8}
            />
          </RowModule>
        </CardBody>
      </Card>
    </>
  )
}
