import {
  FlatIconAndText2Row,
  RowText,
  SelectInput,
  TextInput,
  UploadComponent
} from "../../../../../../component/index.jsx"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import {
  getListSeedClass,
  getVarietyFilterCertification
} from "../../../../../../redux/actions/SubmissionAction.jsx"
import { ICON_CONSTANS } from "../../../../../../constants/ImageConstants.js"
import { timeStampToDate } from "../../../../../helpers/DateHelper.js"
import { Button } from "react-bootstrap"
import { AlertInfo } from "../../../../../../component/atoms/AlertInfo/index.jsx"
import { checkValue, convertDigits, toHectare, toMeter } from "../../../../../helpers/TextHelper.js"
import { ModalMap } from "../../../../../../component/atoms/ModalMap/index.jsx"
import { Card } from "react-bootstrap"
import { RowModule } from "../../../../../../component/atoms/RowModule/RowModule.jsx"
import { FormikProvider } from "formik"
import { AddImage, UploadIcon } from "../../../../../../component/atoms/Icons/SVG/upload.jsx"
import { UPLOAD } from "../../../../../../constants/InitTypeConstants.js"
import {
  resetUrlResponse,
  uploadAction,
  uploadImageListAction
} from "../../../../../../redux/actions/MasterDataAction.jsx"
import { DATE_TYPE_ENUM, GENDER_ENUM } from "../../../../../../constants/StringConstant.js"

export const FormulirSertification = ({ formik, activeStep, setActiveStep, dataInit }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { Submission, MasterData, General } = useSelector((state) => state)

  const [openMap, setOpenMap] = useState(false)
  const [varietasList, setVarietasList] = useState([])
  const [seedClassList, setSeedClassList] = useState([])
  const [imageListFemale, setImageListFemale] = useState([])
  const [uploadType, setUploadType] = useState("")
  const [imageListMale, setImageListMale] = useState([])
  const [urlLocation, setUrlLocation] = useState("")
  const [loadingFemaleImage, setLoadingFemaleImage] = useState(false)
  const [loadingMaleImage, setLoadingMaleImage] = useState(false)
  const [loadingLocation, setLoadingLocation] = useState(false)

  useEffect(() => {
    dispatch(getVarietyFilterCertification())
    dispatch(getListSeedClass())
  }, [])

  useEffect(() => {
    if (formik.values.variety_id) {
      dataInit?.variety_list.map((item) => {
        if (
          item.variety_id === formik.values.variety_id &&
          formik.values.female_seed_total === 0 &&
          formik.values.male_seed_total === 0
        ) {
          formik.setFieldValue(
            "female_seed_total",
            Math.ceil((item.female_total_seed * dataInit?.total_land_area_ha) / 4) * 4
          )
          formik.setFieldValue(
            "male_seed_total",
            convertDigits(item.male_total_seed * dataInit?.total_land_area_ha, 0)
          )
        }
      })
    }
  }, [formik.values.variety_id, dataInit])

  useEffect(() => {
    if (Submission.filterVarietas.length) {
      setVarietasList(
        Submission.filterVarietas.map((value) => ({
          label: value.name,
          value: value.id
        }))
      )
    }
    if (Submission.listSeedClass.length) {
      setSeedClassList(
        Submission.listSeedClass.map((value) => ({
          label: value.name,
          value: value.id
        }))
      )
    }
  }, [Submission])

  useEffect(() => {
    if (MasterData.urlResponseUpload) {
      if (!General.loading) {
        if (MasterData.uploadType === UPLOAD.AVATAR) {
          if (uploadType === GENDER_ENUM.FEMALE) {
            setImageListFemale([...imageListFemale, ...MasterData.urlResponseUpload])
            setLoadingFemaleImage(false)
          } else if (uploadType === GENDER_ENUM.MALE) {
            setImageListMale([...imageListMale, ...MasterData.urlResponseUpload])
            setLoadingMaleImage(false)
          } else {
            setUrlLocation(MasterData.urlResponseUpload)
            setLoadingLocation(false)
          }
        }
      }
      setTimeout(() => {
        dispatch(resetUrlResponse())
      }, 500)
    }
  }, [MasterData.urlResponseUpload, General.loading, MasterData.uploadType])

  useEffect(() => {
    setImageListFemale(formik.values.female_label_images)
    setImageListMale(formik.values.male_label_images)
    setUrlLocation(formik.values.location_image)
  }, [])

  useEffect(() => {
    formik.setFieldValue("female_label_images", imageListFemale)
  }, [imageListFemale])

  useEffect(() => {
    formik.setFieldValue("male_label_images", imageListMale)
  }, [imageListMale])

  useEffect(() => {
    formik.setFieldValue("location_image", urlLocation)
  }, [urlLocation])

  useEffect(() => {
    if (formik.values.variety_id === "" && dataInit?.variety_id) {
      formik.setFieldValue("variety_id", dataInit?.variety_id)
    }
  }, [dataInit?.variety_id])

  useEffect(() => {
    if (formik.values.female_planting_date === "" && dataInit?.female_planting_start_date) {
      formik.setFieldValue(
        "female_planting_date",
        timeStampToDate(dataInit?.female_planting_start_date, DATE_TYPE_ENUM.ISO_DATE_EURO)
      )
    }
    if (formik.values.male_planting_date === "" && dataInit?.male_planting_date) {
      formik.setFieldValue(
        "male_planting_date",
        timeStampToDate(dataInit?.male_planting_date, DATE_TYPE_ENUM.ISO_DATE_EURO)
      )
    }
  }, [dataInit?.female_planting_start_date, dataInit?.male_planting_date])

  const onRemoveImageFemale = (i) => {
    const imgList = imageListFemale
    imgList.splice(i, 1)
    setImageListFemale([...imgList])
  }

  const onRemoveImageMale = (i) => {
    const imgList = imageListMale
    imgList.splice(i, 1)
    formik.setFieldValue("male_label_images", [...imgList])
  }

  const onChangeImageUploadFemale = (e) => {
    setLoadingFemaleImage(true)
    setUploadType(GENDER_ENUM.FEMALE)
    dispatch(uploadImageListAction({ data: e, type: UPLOAD.AVATAR }))
  }
  const onChangeImageUploadMale = (e) => {
    setLoadingMaleImage(true)
    setUploadType(GENDER_ENUM.MALE)
    dispatch(uploadImageListAction({ data: e, type: UPLOAD.AVATAR }))
  }

  const onChangeImageLocation = (e) => {
    setLoadingLocation(true)
    setUploadType("LOCATION")
    dispatch(uploadAction({ data: e, type: UPLOAD.AVATAR }))
  }

  const dataArea = (
    <div className={"d-flex"}>
      {dataInit?.total_area} lahan{" "}
      <span className={"font-weight-normal ml-1 color-gray"}>
        <div className={"d-flex align-items-center"}>
          ({toMeter(dataInit?.total_land_area_meter)} / {toHectare(dataInit?.total_land_area_ha)})
        </div>
      </span>
    </div>
  )

  const overviewLahan = (
    <div className={"d-flex align-items-center bg-white rounded py-4 px-8 mb-4"}>
      <FlatIconAndText2Row
        rounded={true}
        title={t("label.total_area")}
        description={dataArea}
        svg={ICON_CONSTANS.IC_SUB_REGIONAL}
      />
      <FlatIconAndText2Row
        rounded={true}
        title={t("label.mitra")}
        description={dataInit?.mitra_name}
        svg={ICON_CONSTANS.IC_MITRA_2}
      />
      <FlatIconAndText2Row
        rounded={true}
        title={t("label.female_planting_date")}
        description={
          timeStampToDate(dataInit?.female_planting_start_date, DATE_TYPE_ENUM.ISO_DATE_ID) +
          " - " +
          timeStampToDate(dataInit?.female_planting_end_date, DATE_TYPE_ENUM.ISO_DATE_ID)
        }
        svg={ICON_CONSTANS.IC_CALENDER_BIG}
      />
      <Button
        onClick={() => setOpenMap(true)}
        className={
          "d-flex align-items-center color-blue underline text-capitalize font-size-lg font-weight-bolder"
        }
        variant={"text"}>
        {t("label.see_map")}
      </Button>
    </div>
  )

  const previousPlant = (
    <div className={"bg-white rounded p-8 mb-4"}>
      <h4 className={"mb-6 text-uppercase"}>{t("label.data_previous_plant")}</h4>
      <AlertInfo
        variant={"gray"}
        icon={ICON_CONSTANS.IC_INFO}
        label={t("label.data_from_area_bigger")}
        className={"mb-6"}
      />
      <RowModule customColumnCss={"col-md-6 mb-2"}>
        <RowText
          label={t("label.land_history")}
          value={checkValue(dataInit?.last_planting_data?.land_history)}
        />
        <RowText
          label={t("label.harvest_date")}
          value={checkValue(
            timeStampToDate(dataInit?.last_planting_data?.harvest_date, DATE_TYPE_ENUM.ISO_DATE_ID)
          )}
        />
        <RowText
          label={t("label.former_bero")}
          value={
            dataInit?.last_planting_data?.bero
              ? checkValue(dataInit?.last_planting_data?.bero) + " Bulan"
              : "-"
          }
        />
        <RowText
          label={t("label.disertifikasi")}
          value={dataInit?.last_planting_data?.certificate ? "Ya" : "Tidak"}
        />
        <RowText
          label={t("label.plant_type")}
          value={checkValue(dataInit?.last_planting_data?.plant_type)}
        />
        <RowText
          label={t("label.seed_class")}
          value={checkValue(dataInit?.last_planting_data?.seed_class)}
        />
        <RowText
          label={t("label.varietas")}
          value={checkValue(dataInit?.last_planting_data?.variety_name)}
        />
        <RowText
          label={t("label.field_inspection")}
          value={checkValue(dataInit?.last_planting_data?.field_check)}
        />
      </RowModule>
    </div>
  )

  const detailSeed = (
    <Card className={"border-0 mb-10"}>
      <Card.Header className={"font-weight-bolder font-size-lg"}>1. {t("label.detail_seed")}</Card.Header>
      <Card.Body>
        <RowModule>
          <TextInput
            name={"plant_type"}
            required
            editable={false}
            label={t("label.plant_type")}
            value={dataInit?.plant_type_name}
          />
        </RowModule>
        <RowModule>
          <SelectInput
            name="variety_id"
            required
            onChange={(value) => formik.setFieldValue("variety_id", value)}
            editable={true}
            label={t("label.varietas")}
            placeholder={t("placeholder.choose_variety")}
            value={formik.values.variety_id}
            options={varietasList}
          />
        </RowModule>
        <RowModule>
          <SelectInput
            name="seed_class"
            required
            onChange={(value) => formik.setFieldValue("seed_class", value)}
            editable={true}
            label={t("label.seed_class")}
            placeholder={t("placeholder.select_seed_class")}
            value={formik.values.seed_class}
            options={seedClassList}
          />
        </RowModule>
      </Card.Body>
    </Card>
  )

  const plantingDate = (
    <Card className={"border-0 mb-10"}>
      <Card.Header className={"font-weight-bolder font-size-lg"}>2. {t("label.planting_date")}</Card.Header>
      <Card.Body>
        <RowModule>
          <TextInput
            required
            editable={true}
            label={t("label.planting_male_date")}
            name="male_planting_date"
            type="date"
            placeholder={t("placeholder.select_planting_date")}
          />
        </RowModule>
        <RowModule>
          <TextInput
            required
            editable={true}
            label={t("label.planting_male_date")}
            name="female_planting_date"
            type="date"
            placeholder={t("placeholder.select_planting_date")}
          />
        </RowModule>
      </Card.Body>
    </Card>
  )

  const seedFrom = (
    <Card className={"border-0 mb-10"}>
      <Card.Header className={"font-weight-bolder font-size-lg"}>3. {t("label.seed_source")}</Card.Header>
      <Card.Body>
        <div className={"font-weight-bolder mb-6 mt-8"}>{t("label.seed_information")}</div>
        <RowModule>
          <TextInput
            type={"text"}
            name={"seed_base"}
            required
            editable={false}
            label={t("label.seed_source_origin")}
            value={
              dataInit?.variety_list?.find((item) => item.variety_id === formik.values.variety_id)
                ?.variety_source
            }
          />
        </RowModule>
        <RowModule>
          <SelectInput
            name="source_seed_class"
            required
            onChange={(value) => formik.setFieldValue("source_seed_class", value)}
            editable={true}
            label={t("label.seed_source_origin")}
            placeholder={t("placeholder.select_seed_source_origin")}
            value={formik.values.source_seed_class}
            options={seedClassList}
          />
        </RowModule>
        <div className={"font-weight-bolder mb-6 mt-8"}>Benih Betina</div>
        <RowModule>
          <RowModule>
            <TextInput
              name={"female_base_name"}
              required
              editable={false}
              label={t("base_seed_name")}
              value={
                dataInit?.variety_list?.find((item) => item.variety_id === formik.values.variety_id)
                  ?.female_base_name
              }
            />
          </RowModule>
          <RowModule>
            <TextInput
              name={"female_seed_total"}
              required
              editable={true}
              label={t("count_seed")}
              value={formik.values.female_seed_total}
              unit={"Kg"}
              placeholder={t("placeholder.input_count_seed")}
            />
          </RowModule>
        </RowModule>
        <RowModule>
          <TextInput
            name={"female_lot_number"}
            type={"text"}
            required
            editable={true}
            label={t("label.lot_number")}
            value={formik.values.female_lot_number}
            placeholder={t("placeholder.input_lot_number")}
          />
        </RowModule>
        <RowModule>
          <TextInput
            name={"female_seri_label"}
            type={"text"}
            required
            editable={true}
            label={t("label.label_number")}
            value={formik.values.female_seri_label}
            placeholder={t("placeholder.input_label_number")}
          />
        </RowModule>
        <RowModule>
          <UploadComponent
            label={t("label.label_images")}
            subLabel={"(maks. 25 foto)"}
            required={true}
            name="image"
            onDeleteFileList={onRemoveImageFemale}
            fileList={imageListFemale}
            typeUpload={"image-list"}
            editable={true}
            onChange={onChangeImageUploadFemale}
            placeholder={<AddImage />}
            maxFile={25}
            isLoading={loadingFemaleImage}
          />
        </RowModule>

        <div className={"font-weight-bolder mb-6 mt-8"}>{t("label.male_seed")}</div>
        <RowModule>
          <RowModule>
            <TextInput
              name={"male_base_name"}
              required
              editable={false}
              label={t("label.base_seed_name")}
              value={
                dataInit?.variety_list?.find((item) => item.variety_id === formik.values.variety_id)
                  ?.male_base_name
              }
            />
          </RowModule>
          <RowModule>
            <TextInput
              name={"male_seed_total"}
              required
              editable={true}
              label={t("label.count_seed")}
              value={formik.values.male_seed_total}
              unit={"Kg"}
              placeholder={t("placeholder.input_count_seed")}
            />
          </RowModule>
        </RowModule>
        <RowModule>
          <TextInput
            name={"male_lot_number"}
            type={"text"}
            required
            editable={true}
            label={t("label.no_lot")}
            value={formik.values.male_lot_number}
            placeholder={t("placeholder.input_label_number")}
          />
        </RowModule>
        <RowModule>
          <TextInput
            name={"male_seri_label"}
            type={"text"}
            required
            editable={true}
            label={t("label.label_number")}
            value={formik.values.male_seri_label}
            placeholder={t("placeholder.input_label_number")}
          />
        </RowModule>
        <RowModule>
          <UploadComponent
            label={t("label_images")}
            subLabel={"(maks. 25 foto)"}
            required={true}
            name="image"
            onDeleteFileList={onRemoveImageMale}
            fileList={imageListMale}
            typeUpload={"image-list"}
            editable={true}
            onChange={onChangeImageUploadMale}
            placeholder={<AddImage />}
            maxFile={25}
            isLoading={loadingMaleImage}
          />
        </RowModule>

        <div className={"font-weight-bolder mb-6 mt-8"}>{t("label.total_count_seed")}</div>
        <RowModule>
          <TextInput
            name={"total_seed"}
            required
            editable={false}
            label={t("label.count_seed")}
            value={
              parseInt(formik.values.female_seed_total) + parseInt(formik.values.male_seed_total)
            }
          />
        </RowModule>
      </Card.Body>
    </Card>
  )

  const imageLocation = (
    <Card className={"border-0 mb-10"}>
      <Card.Header className={"font-weight-bolder font-size-lg"}>4. {t("label.location_image")}</Card.Header>
      <Card.Body>
        <RowModule>
          <div className={"position-relative"}>
            <UploadComponent
              required
              typeUpload={"image"}
              urlParsing={urlLocation}
              name="location_image"
              label={t("label.location_image")}
              editable={true}
              onChange={onChangeImageLocation}
              placeholder={<UploadIcon />}
              isLoading={loadingLocation}
            />
          </div>
        </RowModule>
      </Card.Body>
    </Card>
  )

  return (
    <div className="d-flex justify-content-center w-full py-6">
      {dataInit?.areas.length > 0 && openMap && (
        <ModalMap
          open={openMap}
          setOpenMap={setOpenMap}
          areas={dataInit?.areas}
          centroid={dataInit?.centroid}
        />
      )}

      <div className={"w-80vw"}>
        {overviewLahan}
        {previousPlant}
        <div className={"mt-20"}>
          <h4 className={"mb-10"}>{t("label.certification_form")}</h4>
          <FormikProvider value={formik}>
            {detailSeed}
            {plantingDate}
            {seedFrom}
            {imageLocation}
          </FormikProvider>
        </div>
        <div className={"d-flex justify-content-end align-items-center"}>
          <Button
            variant={"secondary"}
            onClick={() => setActiveStep(activeStep - 1)}
            className={"d-flex align-items-center color-primary mr-8"}>
            Kembali
          </Button>
          <Button
            disabled={
              formik.values.variety_id &&
              formik.values.seed_class &&
              formik.values.male_planting_date &&
              formik.values.female_planting_date &&
              formik.values.female_seed_total &&
              formik.values.female_lot_number &&
              formik.values.female_seri_label &&
              formik.values.female_label_images.length > 0 &&
              formik.values.male_seed_total &&
              formik.values.male_lot_number &&
              formik.values.male_seri_label &&
              formik.values.male_label_images.length > 0 &&
              formik.values.location_image &&
              !loadingLocation &&
              !loadingFemaleImage &&
              !loadingMaleImage &&
              formik.isValid
                ? false
                : true
            }
            variant={"primary"}
            onClick={() => setActiveStep(activeStep + 1)}
            className={"d-flex align-items-center color-white"}>
            {t("button.submission_review")}
            <img src={ICON_CONSTANS.IC_ARROW_RIGHT_WHITE} className={"ml-2"} />
          </Button>
        </div>
      </div>
    </div>
  )
}
