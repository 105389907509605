import React, { useEffect, useState } from "react"
import { SelectInput } from "../../atoms"
import SVG from "react-inlinesvg"
import { toAbsoluteUrl } from "../../../_metronic/_helpers"
import { ICON_CONSTANS } from "../../../constants/ImageConstants"
import { RowModule } from "../../atoms/RowModule/RowModule.jsx"
import { useFormikContext } from "formik"
import { getRegionOrder } from "../../../redux/actions/OrderAction"
import { useDispatch, useSelector } from "react-redux"
import { useTranslation } from "react-i18next"

export const RegionSelect = ({ setIsValid, setRegionalValue }) => {
  const { values: formValues } = useFormikContext()
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const { Order } = useSelector((state) => state)
  const [listRegion, setListRegion] = useState([])
  const [selectedRegion, setSelectedRegion] = useState("")

  useEffect(() => {
    dispatch(getRegionOrder())
  }, [])

  useEffect(() => {
    if (formValues.hasOwnProperty("regional_id")) {
      setSelectedRegion(formValues["regional_id"])
    }
  }, [formValues["regional_id"]])

  useEffect(() => {
    if (Order?.region) {
      setListRegion(Order?.region.map((value) => ({ label: value.name, value: value.id })))
    }
  }, [Order.region])

  useEffect(() => {
    if (formValues["regional_id"]) {
      setIsValid(true)
    } else {
      setIsValid(false)
    }
    setRegionalValue(formValues["regional_id"])
  }, [formValues])
  return (
    <RowModule customColumnCss="mb-2 col-12 col-md-12">
      <div>
        <SelectInput
          name="regional_id"
          label={t("label.regional")}
          required={true}
          placeholder={t("placeholder.select_reporting_region")}
          clearNow={true}
          options={listRegion}
          value={selectedRegion}
        />
        <div className="mb-6 d-flex mt-2 align-items-center">
          <SVG src={toAbsoluteUrl(ICON_CONSTANS.IC_INFO_REGION)} />
          <span className={"ml-2 mb-0"}>{t("label.select_reporting_region_desc")}</span>
        </div>
      </div>
    </RowModule>
  )
}
