import { Card, CardBody, CardHeader } from "../../../_metronic/_partials/controls"
import { RowModule } from "../../atoms/RowModule/RowModule.jsx"
import { useDispatch, useSelector } from "react-redux"
import React, { useEffect, useState } from "react"
import {
  clearFetchRegencyDistrictSubDistrik,
  fetchDistrictByRegencyId,
  fetchProvince,
  fetchProvinceByRegional,
  fetchRegencyByProvinceId,
  fetchSubDistrictByDistrictId
} from "../../../redux/actions/MasterDataAction"
import { TextAreaInput, TextInput } from "../../atoms/TextInput"
import { SelectInput } from "../../atoms/SelectInput"
import { useFormikContext } from "formik"
import { renderLabelRequired } from "../../../app/helpers/FormikHelper"
import { useTranslation } from "react-i18next"

export const AddressMolecule = ({
  name,
  addressTitle,
  isReadOnly,
  removeHorizontalPadding = false,
  removeTitleSeparator = false,
  withoutAddress = false,
  byRegional = false,
  regionalId,
  isChangeRegional
}) => {
  const { values: formValues } = useFormikContext()

  const dispatch = useDispatch()
  const { MasterData } = useSelector((state) => state)
  const {t} = useTranslation()
  const [provinceList, setProvinceList] = useState([])
  const [regencyList, setRegencyList] = useState([])
  const [districtList, setDistrictList] = useState([])
  const [subDistrictList, setSubDistrictList] = useState([])
  const [masterSubDistrictList, setMasterSubDistrictList] = useState([])
  const [province, setProvince] = useState(formValues[name + "Province"])
  const [regency, setRegency] = useState(formValues[name + "Regency"])
  const [district, setDistrict] = useState(formValues[name + "District"])
  const [subDistrict, setSubDistrict] = useState(formValues[name + "SubDistrict"])
  const [postalCode, setPostalCode] = useState(formValues[name + "PostalCode"])
  const [isChangeProvince, setIsChangeProvince] = useState(false)
  const [isRegency, setIsRegency] = useState(false)
  const [isDistrict, setIsDistrict] = useState(false)
  const [provinceDisable, setProvinceDisable] = useState(false)

  useEffect(() => {
    if (formValues.hasOwnProperty(name + "Province")) {
      setProvince(formValues[name + "Province"])
      if (province) dispatch(fetchRegencyByProvinceId(province))
    }
    if (formValues.hasOwnProperty(name + "Regency")) {
      setRegency(formValues[name + "Regency"])
      if (regency) dispatch(fetchDistrictByRegencyId(regency))
    }
    if (formValues.hasOwnProperty(name + "District")) {
      setDistrict(formValues[name + "District"])
      if (district) dispatch(fetchSubDistrictByDistrictId(district))
    }
    if (formValues.hasOwnProperty(name + "SubDistrict")) {
      setSubDistrict(formValues[name + "SubDistrict"])
    }
  }, [
    formValues[name + "Province"],
    formValues[name + "Regency"],
    formValues[name + "District"],
    formValues[name + "SubDistrict"],
    name,
    dispatch,
    province,
    regency,
    district
  ])

  useEffect(() => {
    if (formValues.hasOwnProperty(name + "PostalCode")) {
      setPostalCode(formValues[name + "PostalCode"])
    }
  }, [formValues[name + "PostalCode"]])

  useEffect(() => {
    return () => {
      dispatch(clearFetchRegencyDistrictSubDistrik())
    }
  }, [name, dispatch])

  useEffect(() => {
    if (byRegional && formValues["regionalId"]) {
      dispatch(fetchProvinceByRegional(formValues["regionalId"]))
    } else {
      dispatch(fetchProvince())
    }

    if (regionalId && isChangeRegional) {
      resetAll()
    }
  }, [byRegional, formValues["regionalId"], regionalId, isChangeRegional])

  useEffect(() => {
    if (MasterData.provinceList) {
      setProvinceList(
        MasterData.provinceList.map((value) => ({
          label: value.name,
          value: value.id
        }))
      )
      if (isChangeProvince) {
        resetAll()
      }
    }
  }, [MasterData.provinceList])

  useEffect(() => {
    setRegencyList(
      MasterData.regencyList.map((value) => ({
        label: value.name,
        value: value.id
      }))
    )
    if (isChangeProvince) {
      setRegency("")
      setDistrictList([])
      setDistrict("")
      setSubDistrictList([])
      setSubDistrict("")
      setPostalCode("")
      setIsChangeProvince(false)
    }
  }, [MasterData.regencyList])

  useEffect(() => {
    setDistrictList(
      MasterData.districtList.map((value) => ({
        label: value.name,
        value: value.id
      }))
    )
    if (isRegency) {
      setDistrict("")
      setSubDistrictList([])
      setSubDistrict("")
      setPostalCode("")
      setIsRegency(false)
    }
  }, [MasterData.districtList])

  useEffect(() => {
    setMasterSubDistrictList(MasterData.subDistrictList)
    setSubDistrictList(
      MasterData.subDistrictList.map((value) => ({
        label: value.name,
        value: value.id
      }))
    )
    if (isDistrict) {
      setSubDistrict("")
      setPostalCode("")
      setIsDistrict(false)
    }
  }, [MasterData.subDistrictList])

  function onChangeProvince(e) {
    if (e) {
      dispatch(fetchRegencyByProvinceId(e))
      setProvince(e)
    }
    setIsChangeProvince(true)
    setRegency("")
    setDistrict("")
    setSubDistrict("")
  }

  function onChangeRegency(e) {
    if (e) {
      dispatch(fetchDistrictByRegencyId(e))
      setRegency(e)
    }
    setIsRegency(true)
    setDistrict("")
    setSubDistrict("")
  }

  function onChangeDistrict(e) {
    if (e) {
      dispatch(fetchSubDistrictByDistrictId(e))
      setDistrict(e)
    }
    setIsDistrict(true)
    setSubDistrict("")
  }

  function onChangeSubDistrict(e) {
    if (e) {
      const selectSubdistrict = masterSubDistrictList.find((el) => el.id === e)
      if (selectSubdistrict) {
        setSubDistrict(e)
        setPostalCode(selectSubdistrict.postal_code)
      }
    }
  }

  const isRegencyEnabled = () => {
    if (!isReadOnly) {
      return regencyList.length !== 0
    }
    return !isReadOnly
  }
  const isDistrictEnabled = () => {
    if (!isReadOnly) {
      return districtList.length !== 0
    }
    return !isReadOnly
  }
  const isSubDistrictEnabled = () => {
    if (!isReadOnly) {
      return subDistrictList.length !== 0
    }
    return !isReadOnly
  }

  useEffect(() => {
    if (byRegional) {
      if (formValues["regionalId"]) {
        setProvinceDisable(true)
      } else {
        setProvinceDisable(false)
      }
    } else {
      setProvinceDisable(!isReadOnly)
    }
  }, [byRegional, regionalId])

  const resetAll = () => {
    setProvince("")
    setDistrict("")
    setRegency("")
    setSubDistrict("")
    setRegencyList([])
    setDistrictList([])
    setSubDistrictList([])
    setPostalCode("")
    setIsChangeProvince(false)
    setIsRegency(false)
    setIsDistrict(false)
  }

  return (
    <>
      <Card className="card-custom card-shadowless">
        <CardHeader
          title={addressTitle}
          className={[
            removeHorizontalPadding ? "px-0" : "",
            removeTitleSeparator ? "border-bottom-0" : ""
          ].join(" ")}
        />
        <CardBody className={removeHorizontalPadding ? "px-0" : ""}>
          <RowModule>
            <SelectInput
              name={name + "Province"}
              onChange={onChangeProvince}
              label={renderLabelRequired(t("label.province"))}
              placeholder={t("placeholder.choose_province")}
              value={province}
              editable={provinceDisable}
              options={provinceList}
            />

            <SelectInput
              name={name + "Regency"}
              onChange={onChangeRegency}
              label={renderLabelRequired(t("label.city"))}
              editable={isRegencyEnabled()}
              placeholder={t("placeholder.choose_city")}
              value={regency}
              options={regencyList}
            />
          </RowModule>
          <RowModule>
            <SelectInput
              name={name + "District"}
              onChange={onChangeDistrict}
              editable={isDistrictEnabled()}
              label={renderLabelRequired(t("label.district"))}
              placeholder={t("placeholder.choose_district")}
              value={district}
              options={districtList}
            />

            <SelectInput
              name={name + "SubDistrict"}
              onChange={onChangeSubDistrict}
              editable={isSubDistrictEnabled()}
              label={renderLabelRequired(t("label.subdistrict"))}
              placeholder={t("placeholder.choose_subdistrict")}
              value={subDistrict}
              options={subDistrictList}
            />
          </RowModule>
          <RowModule customColumnCss=" col-lg-6 col-sm-6 col-md-6">
            <TextInput
              editable={false}
              label={t("label.postal_code")}
              required
              name={name + "PostalCode"}
              value={postalCode}
              placeholder={t("label.postal_code")}
            />
          </RowModule>
          {!withoutAddress && (
            <RowModule>
              <TextAreaInput
                editable={isReadOnly}
                label={renderLabelRequired(t("label.full_address"))}
                name={name + "Address"}
                placeholder={t("label.full_address")}
              />
            </RowModule>
          )}
        </CardBody>
      </Card>
    </>
  )
}

export * from "./addressTab"
