import React, { Fragment } from "react"
import { Badge, Card } from "react-bootstrap"
import { CardBody, CardHeader } from "../../../../../_metronic/_partials/controls/index.js"
import { CardDetail, DetailText } from "../../../../../component/index.jsx"
import { RowModule } from "../../../../../component/atoms/RowModule/RowModule.jsx"

import { GoogleMap, Polygon, useLoadScript } from "@react-google-maps/api"
import { coordinateCenterPolygon, GoogleMapKey } from "../../../../helpers/MapHelper.js"
import { useSelector } from "react-redux"
import { Skeleton } from "@material-ui/lab"
import { checkValue } from "../../../../helpers/TextHelper.js"
import { useTranslation } from "react-i18next"

export const AreaTab = ({
  titleCardDetail,
  descriptionLeft,
  descriptionRight,
  flatIconAndText2Rows,
  documentLahan,
  coordinate,
  detail,
  zoom,
  titleImage = null,
  withDocument = true,
  rejectReason,
  reason,
  reasonTitle = null,
  setIdDocument,
  mapAreas = [],
  status,
  loadingMap
}) => {
  const { isLoaded } = useLoadScript({
    // Enter your own Google Maps API key
    googleMapsApiKey: GoogleMapKey()
  })
  const { General } = useSelector((state) => state)
  const { t } = useTranslation()

  const poligonStyle = {
    fillColor: "lightblue",
    fillOpacity: 0.7,
    strokeColor: "white",
    strokeOpacity: 0.6,
    strokeWeight: 1,
    clickable: false,
    draggable: false,
    editable: false,
    geodesic: false,
    zIndex: 1
  }

  // map
  const containerStyle = {
    width: "100%",
    height: "380px"
  }

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
      slidesToSlide: 1 // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
      slidesToSlide: 1 // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1 // optional, default to 1.
    }
  }

  const plantingSeasonInformationData = [
    {
      title: "Jenis Tanaman",
      value: detail?.plant_varieties_name
        ? `${detail?.plant_name} (${detail?.plant_varieties_name})`
        : detail?.plant_name
    },
    {
      title: "Tgl. Tanam",
      value: detail?.planting_date ? detail?.planting_date : "-"
    },
    {
      title: detail?.is_harvest ? "Tgl. Panen" : "Prediksi Panen",
      value: detail?.harvest_date ? detail?.harvest_date : "-"
    },
    { title: "HST", value: detail?.hst ? detail?.hst : "-" },
    {
      title: "Paket Penyemprotan",
      value: detail?.package_name ? detail?.package_name : "Tanpa paket"
    }
  ]

  const poligonStyleNew = (color) => {
    return {
      fillColor: "#" + color,
      fillOpacity: color === "FFFFFF" ? 0.5 : 0.7,
      strokeColor: "white",
      strokeOpacity: 1,
      strokeWeight: 0.4,
      clickable: false,
      draggable: false,
      editable: false,
      geodesic: false,
      zIndex: 1
    }
  }

  const renderMap = () => {
    return (
      <>
        {rejectReason && (
          <RowModule key={"card-rejectReason"}>
            <Card className={"reject-list-card"}>
              <CardHeader title={t("card_title.reason_of_rejection")} className="mb-0 pb-2" />
              <CardBody>
                {!rejectReason ? null : rejectReason?.length === 0 ? (
                  <span>{t("messages.response.reason_not_found")}</span>
                ) : (
                  <ul>
                    {rejectReason?.map((e, i) => (
                      <li key={i}>{e.enum_value}</li>
                    ))}
                  </ul>
                )}
              </CardBody>
            </Card>
          </RowModule>
        )}
        {reason && (
          <RowModule key={"card-reasonList"}>
            <Card className={"reason-list-card"}>
              <CardHeader title={reasonTitle} className="mb-0 pb-2" />
              <CardBody>
                {!reason ? null : Array.isArray(reason) && reason?.length > 0 ? (
                  <ul>
                    {reason?.map((e, i) => (
                      <li key={i}>{e.enum_value}</li>
                    ))}
                  </ul>
                ) : (
                  <ul>
                    <li>{reason}</li>
                  </ul>
                )}
              </CardBody>
            </Card>
          </RowModule>
        )}
        <RowModule key={"card-list"}>
          <CardDetail
            titleCardDetail={titleCardDetail}
            descriptionLeft={descriptionLeft}
            descriptionRight={descriptionRight}
            flatIconAndText2Rows={flatIconAndText2Rows}
            titleImage={titleImage}
            kurBNI={detail?.has_kur}
            kurBNIImageurl={detail?.bank_image}
          />
        </RowModule>

        {detail?.has_planting_season && (
          <RowModule key={"card-plantingSeason"}>
            <Card>
              <CardBody>
                <div className={"d-flex mb-5 pb-5 col justify-content-between pl-0"}>
                  <h1>{t("messages.title.planting_season_information")}</h1>
                  {detail?.block_name && detail?.block_activity && (
                    <div className={"d-flex align-items-center"}>
                      <div className={"pr-2 font-weight-bold font-size-h2"}>
                        {checkValue(detail?.block_activity)}{" "}
                      </div>
                      <Badge variant={detail?.block_activity_status ? "primary" : "danger"}>
                        {detail?.block_activity_status ? t("label.pass") : t("label.not_pass")}
                      </Badge>
                    </div>
                  )}
                </div>
                <RowModule customColumnCss={"col"}>
                  {General.loading
                    ? Array.from({ length: 5 }).map((item, index) => {
                        return (
                          <div key={index}>
                            <Skeleton animation="wave" variant={"text"} />
                            <Skeleton animation="wave" variant={"text"} />
                          </div>
                        )
                      })
                    : plantingSeasonInformationData.map((item, i) => (
                        <div key={i}>
                          <div>{item?.title}</div>
                          <div className={"font-weight-bolder"}>{item?.value ?? "-"}</div>
                        </div>
                      ))}
                </RowModule>
              </CardBody>
            </Card>
          </RowModule>
        )}

        <RowModule key={"card-lokasiLahan"}>
          <Card className="card-custom">
            <CardHeader title={t("card_title.area_location").toUpperCase()} />
            <CardBody>
              {loadingMap ? (
                <div
                  className={"d-flex justify-content-center align-items-center"}
                  style={containerStyle}>
                  <div className="spinner spinner-primary" />
                </div>
              ) : (
                <Fragment googleMapsApiKey="AIzaSyDWuPp3biF_NddtL2Q7Zh5ewx4qERnUvkE">
                  <GoogleMap
                    mapContainerStyle={containerStyle}
                    center={coordinateCenterPolygon(coordinate)}
                    zoom={zoom}
                    mapTypeId={"hybrid"}>
                    {status === "active" || status === "pending" || status === "reject" ? (
                      status === "pending" && reason ? (
                        <Polygon paths={coordinate} options={poligonStyle} />
                      ) : (
                        mapAreas.map((place, index) => {
                          return (
                            <Polygon
                              key={index}
                              paths={place?.coordinates}
                              options={poligonStyleNew(place?.color)}
                            />
                          )
                        })
                      )
                    ) : (
                      <Polygon paths={coordinate} options={poligonStyle} />
                    )}
                  </GoogleMap>
                </Fragment>
              )}
            </CardBody>
          </Card>
          {withDocument && (
            <DetailText
              title={t("label.land_ownership_proof").toUpperCase()}
              type={"carousel"}
              value={documentLahan ? documentLahan.filter((item) => (item ? item : null)) : null}
              menu="AREA_DOCUMENT"
              responsiveCarousel={responsive}
              setIdDocument={setIdDocument}
            />
          )}
        </RowModule>
      </>
    )
  }

  return isLoaded ? renderMap() : null
}
