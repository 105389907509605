import React, { useEffect, useState } from "react"
import { resetUrlResponse, uploadAction } from "../../../../../redux/actions/MasterDataAction"
import { useDispatch, useSelector } from "react-redux"
import { UPLOAD } from "../../../../../constants/InitTypeConstants"
import {
  Card,
  CardBody,
  CardHeaderTitle
} from "../../../../../_metronic/_partials/controls/index.js"
import { TextInput, UploadComponent } from "../../../../../component/index.jsx"
import { RowModule } from "../../../../../component/atoms/RowModule/RowModule.jsx"
import { UploadIcon } from "../../../../../component/atoms/Icons/SVG/upload.jsx"
import { useTranslation } from "react-i18next"

export const DocumentInstansiGov = ({ isReadOnly }) => {
  const { MasterData } = useSelector((state) => state)
  const dispatch = useDispatch()
  const {t} = useTranslation()

  const [swafoto, setSwafoto] = useState("")
  const [kk, setKk] = useState("")
  const [npwp, setNpwp] = useState("")

  useEffect(() => {
    if (MasterData.urlResponseUpload) {
      if (MasterData.uploadType === UPLOAD.SWAFOTO) {
        setSwafoto(MasterData.urlResponseUpload)
      } else if (MasterData.uploadType === UPLOAD.BUSINESS_DOCUMENT) {
        setKk(MasterData.urlResponseUpload)
      } else if (MasterData.uploadType === UPLOAD.OTHER_IMAGE) {
        setNpwp(MasterData.urlResponseUpload)
      }
    }
    return () => {
      dispatch(resetUrlResponse())
    }
  }, [MasterData.urlResponseUpload, MasterData.uploadType])

  const placeholder = <UploadIcon />

  function onChangeFileSwafoto(e) {
    dispatch(uploadAction({ data: e, type: UPLOAD.SWAFOTO }))
  }

  function onChangeFileKK(e) {
    dispatch(uploadAction({ data: e, type: UPLOAD.BUSINESS_DOCUMENT }))
  }

  function onChangeFileNpwp(e) {
    dispatch(uploadAction({ data: e, type: UPLOAD.OTHER_IMAGE }))
  }

  return (
    <>
      <Card className="card-custom card-shadowless">
        <CardBody>
          <RowModule>
            <CardHeaderTitle className={"my-12"}>
              {t("label.organization_document").toUpperCase()}
            </CardHeaderTitle>
          </RowModule>
          <RowModule>
            <UploadComponent
              urlParsing={swafoto}
              name="ktp_selfie_pic_url"
              label={t("label.photo_of_work_assignment_letter")}
              editable={!isReadOnly}
              onChange={onChangeFileSwafoto}
              maxSize={2048000}
              required
              maxSizeText="2 MB"
              placeholder={placeholder}
            />
          </RowModule>
          <RowModule>
            <UploadComponent
              urlParsing={kk}
              name="business_document_pic_url"
              label={t("label.organization_structure_photo")}
              editable={!isReadOnly}
              onChange={onChangeFileKK}
              maxSize={2048000}
              maxSizeText="2 MB"
              placeholder={placeholder}
            />
          </RowModule>

          <RowModule>
            <TextInput
              editable={!isReadOnly}
              label={t("label.npwp")}
              type="number"
              name="npwp_no"
              placeholder={t("placeholder.input_npwp_number")}
            />
          </RowModule>
          <RowModule>
            <UploadComponent
              urlParsing={npwp}
              name="other_image"
              label={t("label.npwp_picture")}
              editable={!isReadOnly}
              onChange={onChangeFileNpwp}
              maxSize={2048000}
              maxSizeText="2 MB"
              placeholder={placeholder}
            />
          </RowModule>
        </CardBody>
      </Card>
    </>
  )
}