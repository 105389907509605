import { Form, Formik } from "formik"
import React, { useEffect, useRef, useState } from "react"
import { Card } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import swal from "sweetalert"
import * as Yup from "yup"
import { CardBody } from "../../../../../_metronic/_partials/controls/index.js"
import { useSubheader } from "../../../../../_metronic/layout/index.js"
import { ErpNumber } from "../../../../../component/atoms/ErpNumber/ErpNumber.jsx"
import { Popup, PopupQuestion } from "../../../../../component/atoms/Popup/index.jsx"
import { RowModule } from "../../../../../component/atoms/RowModule/RowModule.jsx"
import { DetailText, InlineText, RegionSelect, RowText } from "../../../../../component/index.jsx"
import { ActivityDetailHeader } from "../../../../../component/molecule/ActivityDetailHeader/ActivityDetailHeader.jsx"
import { ACTIVITY_ICONS, ILUSTRATION_CONSTANS } from "../../../../../constants/ImageConstants.js"
import { AREA } from "../../../../../constants/InitTypeConstants.js"
import { RoutesConstants } from "../../../../../constants/RoutesConstants.js"
import {
  activitySendToErp,
  getDetailTillage,
  updateAreaActivityImage
} from "../../../../../redux/actions/AreaAction.jsx"
import { timeStampToDate } from "../../../../helpers/DateHelper.js"
import { FORM_NEED, getStringErrorMassage } from "../../../../helpers/StringCollection.js"
import { checkValue, toMeter } from "../../../../helpers/TextHelper.js"

export const TillageDetail = ({
  match: {
    params: { status, id, activity_id }
  }
}) => {
  const subHeader = useSubheader()
  const formRef = useRef()
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { MasterData, General, Area } = useSelector((state) => state)
  const [detail, setDetail] = useState({})
  const [showPopup, setShowPopup] = useState(false)
  const [popupContent, setPopupContent] = useState({})
  const [idDocument, setIdDocument] = useState(null)
  const [showModalDialog, setShowModalDialog] = useState(false)
  const [showModalDialogRegion, setShowModalDialogRegion] = useState(false)
  const [initialValue] = useState({
    regional_id: ""
  })
  const [isValid, setIsValid] = useState(false)
  const [regionalValue, setRegionalValue] = useState("")

  const validationSchema = Yup.object().shape({
    regional_id: Yup.string().required(getStringErrorMassage("Regional ", FORM_NEED.harusDiIsi))
  })

  useEffect(() => {
    dispatch(getDetailTillage(activity_id))
  }, [])

  useEffect(() => {
    if (Area.data) {
      setDetail(Area.data)
    }
  }, [Area])

  useEffect(() => {
    if (detail) {
      let newStatus
      if (status === "active") {
        newStatus = "teregistrasi"
      }

      subHeader.setButton(null)
      subHeader.setBreadcrumbs([
        {
          pathname: RoutesConstants.AREA.LIST_AREA_TEREGISTRASI("all"),
          title: "Lahan"
        },
        {
          pathname: RoutesConstants.AREA.LIST_AREA_TEREGISTRASI("all"),
          title: newStatus
        },
        {
          pathname: RoutesConstants.AREA.DETAIL_AREA(status, id),
          title: detail?.activity_detail_response?.area_name
        },
        {
          title: "Olah Tanah"
        }
      ])
    }
  }, [detail])

  useEffect(() => {
    if (General.isFinishUpdateOrDelete) {
      if (General.actionInitType === GENERAL.UPLOAD) {
        const data = {
          id: detail?.activity_detail_response?.image_and_id_activity[idDocument]?.id,
          url: MasterData.urlResponseUpload
        }

        dispatch(updateAreaActivityImage(data))
      } else if (General.actionInitType === AREA.UPDATE_ACTIVITY_IMAGE) {
        dispatch(getDetailTillage(activity_id))
      } else if (
        General.loading === false &&
        General.actionInitType === AREA.APPROVE_ACTIVITY_TO_ERP
      ) {
        setShowModalDialog(false)
        swal({
          icon: ILUSTRATION_CONSTANS.SUCCESS_FORM,
          text: t("messages.response.activity_approve_to_erp_success")
        })
        dispatch(getDetailTillage(activity_id))
      }
    }
  }, [General])

  const titleActivity = (
    <ActivityDetailHeader
      icon={ACTIVITY_ICONS.IC_OLAH_TANAH}
      title={` AKTIVITAS Olah Tanah KE-${detail?.activity_detail_response?.seq}`}
      bottomContent={
        <ErpNumber
          noFaktur={detail?.no_faktur}
          noOrderSj={detail?.no_order_sj}
          noOrderSpk={detail?.no_order_spk}
          noOrderErp={detail?.no_order_erp}
          status={detail?.activity_type_enum}
        />
      }
    />
  )

  const openPopup = (content) => {
    setPopupContent(content)
    setShowPopup(true)
  }

  const bodyPopup = (
    <RowModule customColumnCss={"col-md-12 mb-2"}>
      <RowModule>
        <InlineText
          type={"between"}
          title={"ID Alsintan"}
          value={popupContent?.machine_code ? popupContent?.machine_code : "-"}
        />
      </RowModule>
      <RowModule>
        <InlineText
          type={"between"}
          title={"Nama Pemilik"}
          value={popupContent?.owner_name ? popupContent?.owner_name : "-"}
        />
      </RowModule>
      <RowModule>
        <InlineText
          type={"between"}
          title={"Pemilik Alsintan"}
          value={
            popupContent?.supplier_enum === "THIRD_PARTY" ||
            popupContent?.supplier_enum === "OTHER_FARMER" ||
            popupContent?.supplier_enum === "OTHER_MITRA" ||
            popupContent?.supplier_enum === "OTHER_VENDOR"
              ? "Pihak ke-3" + " - " + popupContent?.supplier_third_party_string
              : popupContent?.supplier_name ?? "-"
          }
        />
      </RowModule>
      <RowModule>
        <InlineText
          type={"between"}
          title={"Nama Alsintan"}
          value={popupContent?.machine_name ? popupContent?.machine_name : "-"}
        />
      </RowModule>
      <RowModule>
        <InlineText
          type={"between"}
          title={"Nomor Rangka"}
          value={popupContent?.frame_number ? popupContent?.frame_number : "-"}
        />
      </RowModule>
      <RowModule>
        <InlineText
          type={"between"}
          title={"Nomor Mesin"}
          value={popupContent?.engine_number ? popupContent?.engine_number : "-"}
        />
      </RowModule>
    </RowModule>
  )
  const detailActivitas = (
    <>
      <Card>
        <CardBody>
          <h4 className={"mb-6 text-uppercase"}>INFORMASI LAHAN</h4>
          <RowModule customColumnCss={"col-md-6 mb-2"}>
            <RowModule>
              <RowText
                label={"Lahan"}
                value={
                  detail?.area_information?.area_name ? detail?.area_information?.area_name : "-"
                }
              />
              {/*<InlineText*/}
              {/*  title={"Lahan"}*/}
              {/*  value={list?.area_information?.area_name ?? "-"}*/}
              {/*/>*/}
            </RowModule>
            <RowModule>
              <RowText
                label={"Luas Lahan (Manual)"}
                value={
                  detail?.area_information?.area_land_manual
                    ? toMeter(detail?.area_information?.area_land_manual)
                    : "-"
                }
              />
            </RowModule>
            <RowModule>
              <RowText
                hasIcon={detail?.area_information?.has_kur}
                icon={detail?.area_information?.bank_image}
                label={"Petani"}
                value={
                  detail?.area_information?.farmer_name
                    ? `${detail?.area_information?.farmer_name} (${detail?.area_information?.farmer_code})`
                    : "-"
                }
              />
            </RowModule>
            <RowModule>
              <RowText
                label={"Luas Lahan (Polygon)"}
                value={
                  detail?.area_information?.area_land_polygon
                    ? toMeter(detail?.area_information?.area_land_polygon)
                    : "-"
                }
              />
            </RowModule>
            <RowModule>
              <RowText
                label={"Mitra"}
                value={
                  detail?.area_information?.mitra_name
                    ? `${detail?.area_information?.mitra_name} (${detail?.area_information?.mitra_code})`
                    : "-"
                }
              />
            </RowModule>
            <RowModule>
              <RowText
                label={"Jenis Tanaman"}
                value={
                  detail?.area_information?.plant_type_name
                    ? detail?.area_information?.plant_type_name
                    : "-"
                }
              />
            </RowModule>
            <RowModule>
              <RowText
                label={"Lokasi Lahan"}
                value={
                  detail?.area_information?.full_address
                    ? detail?.area_information?.full_address
                    : "-"
                }
              />
            </RowModule>
            <RowModule>
              <RowText
                label={"Varietas Tanaman"}
                value={
                  detail?.area_information?.variety_name
                    ? detail?.area_information?.variety_name
                    : "-"
                }
              />
            </RowModule>
          </RowModule>
        </CardBody>
      </Card>
      <Card>
        <CardBody>
          <h4 className={"mb-6 mt-6 text-uppercase"}>Detail Aktivitas</h4>
          <RowModule customColumnCss={"col-md-6 mb-2"}>
            <RowModule>
              <RowText
                label={"Submitted by"}
                value={
                  detail?.activity_detail_response?.submitted_by
                    ? detail?.activity_detail_response?.submitted_by
                    : "-"
                }
              />
            </RowModule>
            <RowModule>
              <RowText
                label={"Tanggal Aktivitas"}
                value={
                  detail?.activity_detail_response?.activity_date
                    ? timeStampToDate(detail?.activity_detail_response?.activity_date, "dd-MM-yyyy")
                    : "-"
                }
              />
            </RowModule>
            <RowModule>
              <RowText
                label={"Submitted time"}
                value={
                  detail?.activity_detail_response?.submitted_date
                    ? timeStampToDate(
                        detail?.activity_detail_response?.submitted_date,
                        "dd-MM-yyyy HH:mm:ss"
                      )
                    : "-"
                }
              />
            </RowModule>
            <RowModule>
              <RowText
                label={"HST"}
                value={checkValue(detail?.activity_detail_response?.hst, "HST")}
              />
            </RowModule>

            <RowModule>
              <RowText
                label={"Olah Tanah ke"}
                value={detail?.activity_detail_response?.seq ?? "-"}
              />
            </RowModule>
            <RowModule>
              <RowText
                label={"Metode Olah Tanah"}
                value={detail?.activity_detail_response?.activity_methode_string ?? "-"}
              />
            </RowModule>
            <RowModule>
              <RowText
                label={"Requested by"}
                value={detail?.activity_detail_response?.request_by_string ?? "-"}
              />
            </RowModule>
            {detail?.activity_detail_response?.activity_methode_enum !== "MANUAL" && (
              <RowModule>
                <RowText
                  label={"Luas Lahan Dikerjakan (Alsintan)"}
                  value={
                    detail?.activity_detail_response?.worked_area
                      ? toMeter(detail?.activity_detail_response?.worked_area)
                      : "-"
                  }
                />
              </RowModule>
            )}
          </RowModule>
          <div className={"d-flex flex-column w-100"} style={{ gap: 12 }}>
            {detail?.tools?.map((item, index) => {
              return (
                <Card bg={"secondary"} key={index}>
                  <CardBody>
                    <div className={""}>
                      <h4 className={"mb-6 text-capitalize"}>Alsintan {index + 1}</h4>
                      <RowModule customColumnCss={"col-md-6"}>
                        <RowModule customColumnCss={"col-md-12 mb-2"}>
                          <RowText
                            label={"Pemilik Alsintan"}
                            value={
                              item?.supplier_enum === "THIRD_PARTY" ||
                              item?.supplier_enum === "OTHER_FARMER" ||
                              item?.supplier_enum === "OTHER_MITRA" ||
                              item?.supplier_enum === "OTHER_VENDOR"
                                ? "Pihak ke-3" + " - " + item?.supplier_third_party_string
                                : item?.supplier_name ?? "-"
                            }
                          />
                        </RowModule>

                        <RowModule customColumnCss={"col-md-12 mb-2"}>
                          <RowText
                            label={"Operator"}
                            value={
                              item?.operators && item.operators.length
                                ? item.operators.map((e) => e.operator_name).join(", ")
                                : "-"
                            }
                          />
                        </RowModule>
                        <RowModule customColumnCss={"col-md-12 mb-2"}>
                          <RowText
                            label={"Nama Pemilik Alsintan"}
                            value={item?.owner_name ? item?.owner_name : "-"}
                          />
                        </RowModule>

                        {item?.use_implement && (
                          <RowModule customColumnCss={"col-md-12 mb-2"}>
                            <RowText label={"Implement"} value={item?.implement_name ?? "-"} />
                          </RowModule>
                        )}
                        <RowModule customColumnCss={"col-md-12 mb-2"}>
                          <RowText
                            label={"Alsintan"}
                            value={`${item?.machine_name ?? "-"}   (${
                              item?.frame_number ? item?.frame_number : "-"
                            })`}
                          />
                        </RowModule>
                      </RowModule>
                      <RowModule customColumnCss={"col-md-12 mb-2"}>
                        <a
                          className="hover"
                          style={{
                            textDecorationLine: "underline",
                            color: "#5EC8F2"
                          }}
                          onClick={() => {
                            openPopup(item)
                          }}>
                          Lihat Detail
                        </a>
                      </RowModule>
                    </div>
                  </CardBody>
                </Card>
              )
            })}
          </div>

          <div className={"my-10"}>
            <h4 className={"mb-6"}>CATATAN</h4>
            <div className={"row mb-4"}>
              {detail?.activity_detail_response?.image_and_id_activity &&
                detail?.activity_detail_response?.image_and_id_activity.map((item, index) => {
                  return (
                    <div className={"col-md-3"} key={index}>
                      <DetailText
                        type="image"
                        value={item.url}
                        indexKey={index}
                        menu={AREA.ACTIVITY_TILLAGE}
                        setIdDocument={setIdDocument}
                      />
                    </div>
                  )
                })}
            </div>
            <span className="font-weight-bold text-dark-50 font-size-sm">
              {detail?.activity_detail_response?.note
                ? detail?.activity_detail_response?.note
                : "-"}
            </span>
          </div>
        </CardBody>
      </Card>
    </>
  )

  return (
    <>
      <div className={"row justify-content-center "}>
        <div className={" d-flex flex-column"} style={{ gap: 14 }}>
          {titleActivity}
          {detailActivitas}
        </div>
      </div>
      <Popup
        show={showPopup}
        title={`DETAIL ALSINTAN ${popupContent?.supplier_name?.toUpperCase()}`}
        bodyCustom={bodyPopup}
        textButton="Oke"
        onClick={() => {
          setShowPopup((Popup) => {
            !Popup
          })
        }}
      />
      <PopupQuestion
        onCancel={() => setShowModalDialogRegion(false)}
        textCancel={t("button.cancel")}
        textOk={t("button.continue")}
        title={t("messages.title.activity_select_regional")}
        onOk={() => {
          setShowModalDialogRegion(false)
          setShowModalDialog(true)
        }}
        disable={!isValid}
        show={showModalDialogRegion}
        bodyCustom={
          <Formik
            enableReinitialize
            initialValues={initialValue}
            innerRef={formRef}
            validationSchema={validationSchema}>
            {() => (
              <Form id={"formRegion"} className={"form"}>
                <div className={"mt-4"}>
                  <RegionSelect setIsValid={setIsValid} setRegionalValue={setRegionalValue} />
                </div>
              </Form>
            )}
          </Formik>
        }
      />
      <PopupQuestion
        onCancel={() => setShowModalDialog(false)}
        textCancel={t("button.cancel")}
        textOk={t("button.continue")}
        title={t("messages.title.activity_send_to_erp")}
        onOk={() => {
          dispatch(
            activitySendToErp(activity_id, {
              regional_id: regionalValue
            })
          )
          setShowModalDialog(false)
        }}
        show={showModalDialog}
        bodyCustom={
          <RowModule>
            <p className={"swal-text p-4"}>
              {t("messages.confirmation.activity_approve_to_erp_confirmation")}
            </p>
          </RowModule>
        }
      />
    </>
  )
}
