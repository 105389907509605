import React, { useEffect, useRef, useState } from "react"
import { useSubheader } from "../../../../_metronic/layout"
import { useDispatch, useSelector } from "react-redux"
import { RoutesConstants } from "../../../../constants/RoutesConstants"
import {
  clearDataDetail,
  createFailedHarvest,
  getDetail
} from "../../../../redux/actions/AreaAction"
import * as Yup from "yup"
import { FORM_NEED, getStringErrorMassage } from "../../../helpers/StringCollection.js"
import { TabsInputComponent } from "../../../../component"
import { FormViewFailed } from "./step/FormViewFailed"
import { Button } from "react-bootstrap"
import { PopupQuestion } from "../../../../component/atoms/Popup"
import { toAbsoluteUrl } from "../../../../_metronic/_helpers"
import { ILUSTRATION_CONSTANS } from "../../../../constants/ImageConstants"
import { AREA, GENERAL } from "../../../../constants/InitTypeConstants"
import { ResetIsFinish } from "../../../../redux/actions/GeneraActions"
import SVG from "react-inlinesvg"
import { useTranslation } from "react-i18next"
import { AREA_REGISTERED_ENUM, REASON_FAILURE_ENUM } from "../../../../constants/StringConstant.js"

export const CreateFailedHarvest = ({
  history,
  match: {
    params: { id }
  }
}) => {
  const { General, Area } = useSelector((state) => state)
  const subHeader = useSubheader()
  const dispatch = useDispatch()
  const formRef = useRef()
  const {t} = useTranslation()
  const [detail, setDetail] = useState({})
  const [initialValue, setInitialValue] = useState({
    reason_failure_enum: "",
    pest_type: [],
    other_reason: "",
    document_url: "",
    document_name: "",
    document_size: 0,
    image_url: []
  })
  const stepsTitle = [null]
  const stepsDescription = [null]
  const [btnDisable, setBtnDisable] = useState(true)
  const [showModalConfirmation, setModalConfirmation] = useState(false)
  const [showModalSuccess, setShowModalSuccess] = useState(false)

  const ValidationScheme = [
    Yup.object().shape({
      reason_failure_enum: Yup.string().required(
        getStringErrorMassage("Alasan gagal panen ", FORM_NEED.harusDiIsi)
      ),
      document_url: Yup.string().required(
        getStringErrorMassage("Documen berita acara ", FORM_NEED.harusDiIsi)
      ),
      image_url: Yup.string().required(getStringErrorMassage("Bukti foto ", FORM_NEED.harusDiIsi)),
      other_reason: Yup.string()
        .required(getStringErrorMassage("Alasan gagal panen ", FORM_NEED.harusDiIsi))
        .min(5, "Alasan gagal panen minimal 5 karakter")
        .matches("^.*$", "Tidak Boleh di Enter")
    })
  ]

  useEffect(() => {
    dispatch(getDetail(id))
    subHeader.setButton(null)
    return () => {
      dispatch(clearDataDetail())
    }
  }, [])

  useEffect(() => {
    if (Area.data) {
      setDetail(Area.data)
    }
  }, [Area.data])

  useEffect(() => {
    if (detail) {
      subHeader.setBreadcrumbs([
        {
          pathname: RoutesConstants.AREA.LIST_AREA_TEREGISTRASI(AREA_REGISTERED_ENUM.ALL),
          title: t("label.area_name")
        },
        {
          pathname: RoutesConstants.AREA.LIST_AREA_TEREGISTRASI(AREA_REGISTERED_ENUM.ALL),
          title: t("label.registered")
        },
        {
          pathname: RoutesConstants.AREA.DETAIL_AREA(AREA_REGISTERED_ENUM.ACTIVE, id),
          title: detail.name
        },
        {
          title: t("label.failed_harvest")
        }
      ])
    }
  }, [detail])

  useEffect(() => {
    if (General.isFinishUpdateOrDelete) {
      if (General.actionInitType === AREA.CREATE_FAILED_HARVEST) {
        setShowModalSuccess(true)
      }
    }
    return () => {
      if (General.actionInitType === GENERAL.UPLOAD) {
        dispatch(ResetIsFinish())
      }
    }
  }, [General])

  const handleCancel = () => {
    history.push(RoutesConstants.AREA.DETAIL_AREA(AREA_REGISTERED_ENUM.ACTIVE, id))
  }

  const handleSubmitForm = () => {
    setModalConfirmation(false)
    const body = {
      reason_failure_enum: formRef.current.values.reason_failure_enum,
      pest_type:
        formRef.current.values.reason_failure_enum === REASON_FAILURE_ENUM.PEST_ATTACK
          ? formRef.current.values.pest_type
          : null,
      other_reason:
        formRef.current.values.reason_failure_enum === REASON_FAILURE_ENUM.OTHER
          ? formRef.current.values.other_reason
          : null,
      document_url: formRef.current.values.document_url,
      document_name: formRef.current.values.document_name,
      document_size: formRef.current.values.document_size,
      image_url: formRef.current.values.image_url
    }
    dispatch(createFailedHarvest(id, body))
  }

  const createFailedFlow = [
    <FormViewFailed
      key={0}
      data={detail}
      isReadOnly={false}
      setFormValues={(e) => {
        setInitialValue(e)
      }}
      edit={id}
      setBtnDisable={setBtnDisable}
    />
  ]

  const modalConfirmation = (
    <PopupQuestion
      show={showModalConfirmation}
      title={t("label.failed_harvest").toUpperCase()}
      bodyCustom={
        <div className={"text-center"}>
          Dengan menyatakan gagal panen, status tanam lahan akan dinyatakan
          <span className="font-weight-bold text-dark"> Inactive</span> dan semua kegiatan akan
          dihentikan. Pastikan semua data yang diinput telah benar.
        </div>
      }
      textOk={t("button.next")}
      textCancel={t("button.cancel")}
      onCancel={() => {
        setModalConfirmation(false)
      }}
      onOk={() => handleSubmitForm()}
    />
  )

  const modalSuccess = (
    <PopupQuestion
      show={showModalSuccess}
      bodyCustom={
        <div className={"d-flex flex-column align-items-center"}>
          <SVG src={toAbsoluteUrl(ILUSTRATION_CONSTANS.SUCCESS_FAILED_HARVEST)} />
          <span className={"mt-4"}>
            {t("messages.response.successfully_declared_a_crop_failure")}
          </span>
        </div>
      }
      textOk={t("button.oke")}
      textCancel={t("button.see_detail")}
      onCancel={() =>
        history.push(
          RoutesConstants.AREA.DETAIL_ACTIVITY(
            Area.responsePost.areaId,
            "harvest_failure",
            Area.responsePost.activityId
          )
        )
      }
      onOk={() =>
        history.push(RoutesConstants.AREA.DETAIL_AREA(AREA_REGISTERED_ENUM.ACTIVE, Area.responsePost.areaId))
      }
    />
  )

  return (
    <>
      {modalConfirmation}
      {modalSuccess}
      <TabsInputComponent
        isEditTabs={false}
        steps={stepsTitle}
        stepDescription={stepsDescription}
        formInitialValues={initialValue}
        formId="createFailedHarvest"
        innerRef={formRef}
        componentTab={createFailedFlow}
        validationSchema={ValidationScheme}
      />
      <div className="d-flex justify-content-end align-items-center">
        <Button
          className="font-weight-bold btn-block my-0 mr-2 fit-content"
          onClick={handleCancel}
          type="reset"
          variant={"secondary-info"}>
          Cancel
        </Button>
        <Button
          className="font-weight-bold btn-block my-0 ml-2 fit-content"
          type={"submit"}
          variant={"primary"}
          disabled={btnDisable}
          onClick={() => setModalConfirmation(true)}>
          Submit
        </Button>
      </div>
    </>
  )
}
