import React from "react"
import TableView, { formatterRowComponent } from "../../../../../component/atoms/Tabel/index.jsx"
import { Card, CardBody } from "../../../../../_metronic/_partials/controls/index.js"
import { linkWithCtrl } from "../../../../helpers/Utils.js"
import { toThousandConvert } from "../../../../helpers/TextHelper.js"
import { EmptyCard, TableSkeleton } from "../../../../../component/index.jsx"
import { useTranslation } from "react-i18next"
import { RoutesConstants } from "../../../../../constants/RoutesConstants.js"

export const AreaList = ({ data, general, changePage, history, isFilter, isActiveSearch }) => {
  const { t } = useTranslation()
  const uiCode = (e) => {
    return (
      <>
        <p className="text-dark font-weight-bolder mb-1 font-size-lg">{e.area_code ?? "-"}</p>
      </>
    )
  }
  const uiSubmittedBy = (e) => {
    return (
      <>
        <p className="text-dark font-weight-bolder mb-1 font-size-lg">{e.submitted_by ?? "-"}</p>
      </>
    )
  }

  const headerLuas = (
    <>
      {t("table_header.land_area_only")} (<span className={"text-lowercase"}>m</span>
      <sup>2</sup>)
    </>
  )

  const uiLuas = (e) => {
    return (
      <>
        <span>{toThousandConvert(e.land_area) ?? "-"}</span>
      </>
    )
  }

  const columns = [
    {
      dataField: "area_code",
      text: t("table_header.area_name"),
      formatter: formatterRowComponent,
      formatExtraData: {
        externalStyle: uiCode
      }
    },
    {
      dataField: "land_area",
      text: headerLuas,
      formatter: formatterRowComponent,
      formatExtraData: {
        externalStyle: uiLuas
      }
    },
    {
      dataField: "full_address",
      text: t("table_header.area_address"),
      formatter: formatterRowComponent
    },
    {
      dataField: "submitted_by",
      text: t("table_header.submitted_by_area"),
      formatter: formatterRowComponent,
      formatExtraData: {
        externalStyle: uiSubmittedBy
      }
    },
    {
      dataField: "submitted_date",
      text: t("table_header.submitted_date"),
      formatter: formatterRowComponent
    }
  ]

  return (
    <>
      <Card className="card-shadowless">
        <CardBody>
          {general.loading ? (
            <TableSkeleton column={7} row={10} />
          ) : data?.pageData?.length == 0 ? (
            <EmptyCard
              body={
                isFilter || isActiveSearch
                  ? t("messages.response.data_not_found")
                  : t("messages.response.empty_state")
              }
            />
          ) : (
            ""
          )}
          <TableView
            callbackAfterPageChange={changePage}
            columnProperties={columns}
            dataTable={data.pageData}
            currentPage={data.page.currentPage}
            currentElement={data.page.currentElement}
            totalCount={data.page.totalCount}
            loadingGetData={general.loading}
            ignoreSinglePage
            rowClasses={() => {
              return "cursor-pointer"
            }}
            rowEvents={{
              onClick: (e, row) => {
                const url = RoutesConstants.AREA.DETAIL_AREA_LEAD(row.area_lead_id)
                if (e.ctrlKey) {
                  linkWithCtrl(url)
                } else {
                  history.push(url)
                }
              }
            }}
          />
        </CardBody>
      </Card>
    </>
  )
}
