import React, { useState } from "react"
import { Form } from "react-bootstrap"

export const FarmerUnassignReasons = ({ items = [], onChange, name }) => {
  const [reasonOtherEnabled, setReasonOtherEnabled] = useState(false)
  const [reasonData, setReasonData] = useState({ enum_name: "", reason: "" })

  const onLocalChange = item => {
    setReasonData({ enum_name: item.enum_name, reason: "" })
    item.enum_name === "LAINNYA" ? setReasonOtherEnabled(true) : setReasonOtherEnabled(false)
    onChange({ enum_name: item.enum_name, reason: "" })
  }

  const onOtherReasonTextChange = e => {
    setReasonData({ ...reasonData, reason: e.target.value })
    onChange({ ...reasonData, reason: e.target.value })
  }

  return (
    <>
      {items.map((e, i) => (
        <Form.Check
          key={e?.enum_name}
          id={e?.enum_name}
          className={
            (i === 0 ? "mb-2" : i === items.length - 1 ? "mt-2" : "my-2") + " cursor-pointer"
          }
          type={"radio"}
          name={name}
          label={e?.enum_value && e.enum_name === "LAINNYA" ? e.enum_value + ":" : e.enum_value}
          onChange={() => onLocalChange(e)}
        />
      ))}
      {
        <Form.Control
          as="textarea"
          id="LAINNYA"
          className="mt-2 my-2 "
          name="LAINNYA"
          placeholder={t("placeholder.reason_statement")}
          disabled={!reasonOtherEnabled}
          rows="3"
          value={reasonData.reason}
          onChange={onOtherReasonTextChange}
        />
      }
    </>
  )
}
