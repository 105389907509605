import { FormikProvider } from "formik"
import React, { useEffect, useState } from "react"
import { Button, Card } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { CardBody } from "../../../../../../_metronic/_partials/controls/index.js"
import { RowModule } from "../../../../../../component/atoms/RowModule/RowModule.jsx"
import {
  SelectInput,
  Text2Row,
  TextAreaInput,
  TextInput
} from "../../../../../../component/index.jsx"
import { SelectMultipleChip } from "../../../../../../component/molecule/SelectMultipleChip/SelectMultipleChip.jsx"
import { getUrlParsing } from "../../../../../../config/EndpointCollection.js"
import { ICON_CONSTANS } from "../../../../../../constants/ImageConstants.js"
import { DATE_TYPE_ENUM, REASON } from "../../../../../../constants/StringConstant.js"
import {
  getListChildReason,
  getListPest,
  getListReasonReportBa
} from "../../../../../../redux/actions/ReportActivityAction.jsx"
import { timeStampToDate } from "../../../../../helpers/DateHelper.js"
import { convertM2ToHectareParseFloat } from "../../../../../helpers/TextHelper.js"
import OverviewArea from "../components/OverviewArea.jsx"

export const FillAreaDetail = ({ activeStep, setActiveStep, formik, dispatch, dataInit }) => {
  const { t } = useTranslation()
  const [stateReason, setStateReason] = useState([])
  const [stateChildReason, setStateChildReason] = useState([])
  const [statePest, setStatePest] = useState([])
  const [buttonDisabled, setButtonDisabled] = useState(false)
  const { ReportActivity } = useSelector((state) => state)

  useEffect(() => {
    dispatch(getListReasonReportBa())
    checkDisable()
  }, [])

  useEffect(() => {
    setStateReason(
      ReportActivity.listReason.map((value) => ({
        value: value.id,
        label: value.name
      }))
    )
  }, [ReportActivity.listReason])

  useEffect(() => {
    if (ReportActivity.listChildReason.length) {
      const stateChildReason = ReportActivity.listChildReason.map((value) => {
        if (Array.isArray(value) && value.length > 0) {
          return value.map((item) => ({
            value: item.id,
            label: item.name
          }))
        }
        return []
      })
      setStateChildReason(stateChildReason)
    }
  }, [ReportActivity.listChildReason])

  useEffect(() => {
    checkDisable()
  }, [formik.values.area])

  useEffect(() => {
    if (ReportActivity?.listPest) {
      const statePest = ReportActivity?.listPest.map((value) => {
        if (Array.isArray(value) && value.length > 0) {
          return value.map((item) => ({
            value: item.id,
            label: item.name,
            checked: false
          }))
        }
        return []
      })
      setStatePest(statePest)
    }
  }, [ReportActivity.listPest])

  function handleSetPestType(e, index) {
    if (e) {
      formik.values.area[index].checkValue = e.length > 0
    } else {
      formik.values.area[index].checkValue = false
    }
    checkDisable()
  }

  const checkDisable = () => {
    const data = formik.values.area
    const hasInvalidActivityDate = data.some((e) => !e.activity_date)
    const hasInvalidReasonId = data.some((e) => !e.reason_id)
    const hasCheckValue = data.some((e) => !e.checkValue)
    if (!hasInvalidActivityDate && !hasInvalidReasonId) {
      return setButtonDisabled(hasCheckValue)
    } else {
      return setButtonDisabled(true)
    }
  }

  const listFormArea = (
    <div className={"mt-10"}>
      <h4 className={"mb-10"}>Daftar Lahan Terpilih</h4>
      {formik?.values?.area &&
        formik?.values?.area.map((item, index) => {
          return (
            <Card className={"mb-4"} key={index}>
              <CardBody>
                <h4 className={"mb-8"}>{item.area_name}</h4>
                <div className={"row ml-0 mb-8"}>
                  <Text2Row title={t("label.block_name")} description={item.block_code} />
                  <Text2Row title={t("label.farmer_name")} description={item.farmer_name} />
                  <Text2Row
                    title={t("label.area")}
                    description={`${convertM2ToHectareParseFloat(item.planting_area)} Ha`}
                  />
                  <Text2Row title={t("label.plant_varieties")} description={item.variety_name} />
                  <Text2Row
                    title={t("label.planting_date")}
                    description={`${timeStampToDate(
                      item.planting_date,
                      DATE_TYPE_ENUM.ISO_DATE_STRAP
                    )} (${item.hst} HST)`}
                  />
                </div>
                <RowModule customSeparator={"mb-8"}>
                  <TextInput
                    required
                    editable={true}
                    label={t("label.land_clearing_activity_date")}
                    placeholder={t("placeholder.select_date")}
                    name={`area.${index}.activity_date`}
                    type={"date"}
                    value={formik.values.area[index]?.activity_date}
                  />
                </RowModule>
                <RowModule customSeparator={"mb-8"}>
                  <SelectInput
                    name={`area.${index}.reason_id`}
                    required
                    editable={true}
                    label={t("label.land_clearing_reasong")}
                    placeholder={t("placeholder.select_one")}
                    options={stateReason}
                    value={formik.values.area[index]?.reason_id}
                    onChange={(values) => {
                      formik.setFieldValue(`area.${index}.pest_id`, null)
                      formik.setFieldValue(`area.${index}.reason_string`, "")
                      formik.setFieldValue(`area.${index}.reason_id_child`, null)
                      formik.setFieldValue(`area.${index}.reason_id`, values).then(() => {
                        if (values === REASON.DISASTER) {
                          let param = ""
                          param = getUrlParsing(param, "parent_id", values)
                          formik.setFieldValue(`area.${index}.checkValue`, false)
                          dispatch(getListChildReason(param, index))
                        } else if (values === REASON.OTHER_PARENT) {
                          formik.setFieldValue(`area.${index}.checkValue`, false)
                        } else if (values === REASON.PEST) {
                          formik.setFieldValue(`area.${index}.checkValue`, false)
                          dispatch(getListPest(formik.values.area[index].plant_type_id, index))
                        } else {
                          if (values) {
                            formik.setFieldValue(`area.${index}.checkValue`, true)
                          } else {
                            formik.setFieldValue(`area.${index}.checkValue`, false)
                          }
                        }
                      })
                    }}
                  />
                </RowModule>
                {formik.values.area[index]?.reason_id === REASON.DISASTER && (
                  <RowModule>
                    <SelectInput
                      name={`area.${index}.reason_id_child`}
                      required
                      onChange={(e) => {
                        if (e === REASON.OTHER_DISASTER) {
                          formik.setFieldValue(`area.${index}.checkValue`, false)
                        } else {
                          if (e) {
                            formik.setFieldValue(`area.${index}.checkValue`, true)
                            formik.setFieldValue(`area.${index}.reason_string`, "")
                          } else {
                            formik.setFieldValue(`area.${index}.checkValue`, false)
                          }
                        }
                      }}
                      editable={true}
                      label={t("placeholder.disaster_type")}
                      placeholder={t("placeholder.select_one")}
                      options={stateChildReason[index]}
                      value={formik.values.area[index]?.reason_id_child}
                    />
                  </RowModule>
                )}
                {formik.values.area[index]?.reason_id === REASON.PEST && (
                  <RowModule customColumnCss="mb-5 col-lg-12 col-sm-12 col-md-12">
                    <SelectMultipleChip
                      name={`area.${index}.pest_id`}
                      label={t("label.pest")}
                      labelButton={t("button.add_pest_type")}
                      titleModal={t("label.choose_pest_type")}
                      required={true}
                      formValue={formik.values.area[index].pest_id}
                      editable={true}
                      options={statePest[index]}
                      indexComponent={index}
                      setPestType={(e) => handleSetPestType(e, index)}
                      onClickAdd={() => checkDisable()}
                      setOnClose={(e) => {
                        formik.setFieldValue(`area.${index}.pest_id`, e)
                      }}
                      onCheckClose={(e) => {
                        handleSetPestType(e, index)
                      }}
                    />
                  </RowModule>
                )}
                {(formik.values.area[index]?.reason_id === REASON.OTHER_PARENT ||
                  formik.values.area[index]?.reason_id_child === REASON.OTHER_DISASTER) && (
                  <RowModule>
                    <TextAreaInput
                      editable={false}
                      name={`area.${index}.reason_string`}
                      placeholder={t("placeholder.input_description")}
                      type={"text"}
                      value={formik.values.area[index]?.reason_string}
                      onKeyUp={(e) => {
                        const value = e.target.value
                        formik.setFieldValue(`area.${index}.checkValue`, !!value)
                      }}
                    />
                  </RowModule>
                )}
              </CardBody>
            </Card>
          )
        })}
    </div>
  )

  return (
    <div className={"d-flex justify-content-center w-full py-6"}>
      <div className={"w-80vw"}>
        {dataInit && <OverviewArea dataInit={dataInit} />}
        <FormikProvider value={formik}>{listFormArea}</FormikProvider>
        <div className={"d-flex justify-content-end align-items-center"}>
          <Button
            variant={"secondary"}
            onClick={() => {
              setActiveStep(activeStep - 1)
            }}
            className={"d-flex align-items-center color-primary mr-8"}>
            {t("button.previous")}
          </Button>
          <Button
            variant={"primary"}
            disabled={buttonDisabled}
            onClick={() => setActiveStep(activeStep + 1)}
            className={"d-flex align-items-center color-primary"}>
            {t("button.upload_ba")}
            <img alt={""} src={ICON_CONSTANS.IC_ARROW_RIGHT_WHITE} className={"ml-2"} />
          </Button>
        </div>
      </div>
    </div>
  )
}
