import React, { useEffect, useState } from "react"
import SVG from "react-inlinesvg"
import { useDispatch, useSelector } from "react-redux"
import { useFormikContext } from "formik"
import { Skeleton } from "@material-ui/lab"
import { SelectInput } from "../../../../../../../../component/index.jsx"
import { RowModule } from "../../../../../../../../component/atoms/RowModule/RowModule.jsx"
import { getDroneTeam } from "../../../../../../../../redux/actions/OrderAction.jsx"
import { renderLabelRequired } from "../../../../../../../helpers/FormikHelper.js"
import { toAbsoluteUrl } from "../../../../../../../../_metronic/_helpers/index.js"
import { ICON_CONSTANS } from "../../../../../../../../constants/ImageConstants.js"
import { useTranslation } from "react-i18next"

export const ApprovalTeamDrone = ({ title, teamSelected, setTeamSelected }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { values: formValues } = useFormikContext()
  const { General, Order } = useSelector((state) => state)
  const [team, setTeam] = useState([])

  useEffect(() => {
    dispatch(getDroneTeam())
  }, [])

  useEffect(() => {
    if (Order.droneTeam) {
      setTeam(
        Order.droneTeam.map((value) => ({
          label: value.name + " - " + value.member,
          value: value.id
        }))
      )
    }
  }, [Order.droneTeam])

  useEffect(() => {
    let newStateTeam = team
    newStateTeam.map((item) => {
      if (teamSelected.includes(item.value)) {
        item.isDisabled = true
      } else {
        item.isDisabled = false
      }
    })
    setTeam(newStateTeam)
  }, [formValues["team_id"], team])

  const onChangeTeam = (e, index) => {
    let newTeam = teamSelected
    newTeam[index] = e
    setTeamSelected(newTeam)
  }

  const onRemoveTeam = (index) => {
    let newTeam = teamSelected
    newTeam = newTeam.filter((item, i) => i !== index)
    setTeamSelected(newTeam)
    formValues["team_id"] = newTeam
  }

  return (
    <>
      <h4 className="mb-6">
        {General.loading ? (
          <Skeleton animation="wave" variant={"rect"} height={32} width={250} />
        ) : (
          title
        )}
      </h4>
      <RowModule>
        <label>
          {General.loading ? (
            <Skeleton animation="wave" variant={"text"} width={200} className={"mb-4"} />
          ) : (
            renderLabelRequired(t("label.team_name"))
          )}
        </label>
      </RowModule>
      {teamSelected.map((item, index) => {
        return (
          <div key={index} className={"row justify-content-between flex-nowrap"}>
            <div className={"mb-2 col"}>
              <SelectInput
                name={`team_id[${index}]`}
                onChange={(e) => onChangeTeam(e, index)}
                placeholder={t("placeholder.choose_drone_pilot_team")}
                options={team}
                withoutFeedback={false}
                clearNow={true}
                value={teamSelected[index]}
                isLoading={General.loading}
              />
            </div>
            {teamSelected.length > 1 ? (
              <span
                onClick={() => {
                  onRemoveTeam(index)
                }}
                className="svg-icon px-6 svg-icon-xl pt-2 text-center svg-icon-primary"
                style={{ cursor: "pointer" }}>
                <SVG src={toAbsoluteUrl(ICON_CONSTANS.IC_REMOVE)} />
              </span>
            ) : null}
          </div>
        )
      })}
      {teamSelected?.length < 5 && (
        <div
          className="d-flex justify-content-end align-items-center cursor-pointer mt-2"
          onClick={() => setTeamSelected([...teamSelected, undefined])}>
          <SVG
            width={13}
            height={13}
            src={toAbsoluteUrl(ICON_CONSTANS.IC_PLUS)}
            className="ml-2 mr-3"
          />
          <span className={"text-info font-size-14 underline"}>Tambah Tim Drone</span>
        </div>
      )}
    </>
  )
}