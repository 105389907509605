import React, { useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { TabsInputComponent } from "../../../../../component/index.jsx"
import * as Yup from "yup"
import { FORM_NEED, getStringErrorMassage } from "../../../../helpers/StringCollection.js"
import swal from "sweetalert"
import { createVideo, getDetail, updateVideo } from "../../../../../redux/actions/VideoAction.jsx"
import { DetailInputVideo } from "./step/DetailInputVideo.jsx"
import { useSubheader } from "../../../../../_metronic/layout/index.js"
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers/index.js"
import { RoutesConstants } from "../../../../../constants/RoutesConstants.js"
import { useTranslation } from "react-i18next"

export const CreateEditVideo = ({
  history,
  match: {
    params: { id, actionDetail }
  }
}) => {
  const [isSubmit] = useState(false)
  const [stepsTitle] = useState([null])
  const { Video, General } = useSelector((state) => state)
  const subHeader = useSubheader()
  const {t} = useTranslation()
  const dispatch = useDispatch()
  const formRef = useRef()
  const [initialValue, setInitialValue] = useState({
    url: "",
    title: "",
    category: ""
  })
  const [swalMessage, setSwalMessage] = useState("")

  const [categoryId, setCategoryId] = useState(null)

  const addFarmerFlow = [
    <DetailInputVideo
      key={1}
      isReadOnly={false}
      categoryId={categoryId}
      changeCategory={(value) => setCategoryId(value)}
      actionDetail={actionDetail}
      linkEdit={Video?.data?.url ?? null}
    />
  ]

  const stepsDescription = [null]

  useEffect(() => {
    if (actionDetail !== "create" && actionDetail !== "edit") {
      return history.push("/error/opppsss")
    }
    let _title = id ? "" : "Tambah video"
    if (id) {
      dispatch(getDetail(id))
    } else {
      setInitialValue((prevState) => ({
        ...prevState
      }))
    }
    subHeader.setTitle(_title)
    subHeader.setBreadcrumbs([
      {
        pathname: RoutesConstants.VIDEO.LIST(),
        title: "list video"
      },
      {
        title: actionDetail
      }
    ])
    subHeader.setButton(buttonSubmited)
  }, [id, actionDetail])

  useEffect(() => {
    if (General.isFinishUpdateOrDelete) {
      swal({
        text: swalMessage,
        icon: toAbsoluteUrl("/media/svg/icons/Detail/ic_success.svg")
      })
      history.push(RoutesConstants.VIDEO.LIST())
    }
  }, [General.isFinishUpdateOrDelete])

  const buttonSubmited = (
    <button
      type="button"
      disabled={isSubmit}
      className="btn btn-primary"
      onClick={() => {
        if (formRef.current) {
          formRef.current.handleSubmit()
        }
      }}>
      {actionDetail === "edit" ? "Update" : actionDetail === "create" ? t("button.submit") : null}
    </button>
  )

  useEffect(() => {
    const video = Video?.data
    if (video !== undefined && id) {
      subHeader.setTitle(<h4>{t("sub_header.edit_video_with_title", {title: video?.title})}</h4>)
      setInitialValue((prevState) => ({
        ...prevState,
        title: Video?.data?.title,
        url: Video?.data?.url,
        category: Video?.data?.category
      }))
      if (Video?.data?.category) {
        setCategoryId(Video?.data?.category)
      }
    }
  }, [Video.data])

  const FarmerAddScheme = [
    Yup.object().shape({
      url: Yup.string()
        .required(getStringErrorMassage("Youtube video Link ", FORM_NEED.harusDiIsi))
        .matches(
          /^(?:https?:\/\/)?(?:www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/,
          "Link yang diinput error"
        ),
      title: Yup.string()
        .required(getStringErrorMassage("Judul video ", FORM_NEED.harusDiIsi))
        .max(255, "Judul video Maksimal 255 Karakter"),
      category: Yup.string().required(
        getStringErrorMassage("Kategori video ", FORM_NEED.harusDiIsi)
      )
    })
  ]

  function onSubmitForm(e) {
    const { values, submited } = e

    if (submited) {
      const data = {
        title: values.title,
        url: values.url,
        category: values.category
      }

      onClickSendValue(data)
    }
  }

  function onClickSendValue(data) {
    if (actionDetail === "edit") {
      setSwalMessage(t("messages.response.edit_video_tutorial_success"))
      dispatch(updateVideo(id, data))
    } else if (actionDetail === "create") {
      setSwalMessage(t("messages.response.add_video_tutorial_success"))
      dispatch(createVideo(data))
    }
  }

  return (
    <>
      <TabsInputComponent
        isEditTabs={false}
        steps={stepsTitle}
        stepDescription={stepsDescription}
        formInitialValues={initialValue}
        formId="videoInput"
        innerRef={formRef}
        componentTab={addFarmerFlow}
        validationSchema={FarmerAddScheme}
        onSubmitForm={onSubmitForm}
      />
    </>
  )
}