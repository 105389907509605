import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { TabsComponent } from "../../../../../component/index.jsx"
import { DetailVideo } from "./component/DetailVideo.jsx"
import {
  clearDataDetail,
  getDetail,
  removeVideo
} from "../../../../../redux/actions/VideoAction.jsx"
import { useSubheader } from "../../../../../_metronic/layout/index.js"
import { Button } from "react-bootstrap"
import { RowModule } from "../../../../../component/atoms/RowModule/RowModule.jsx"
import { PopupQuestion } from "../../../../../component/atoms/Popup/index.jsx"
import swal from "sweetalert"
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers/index.js"
import { loadUserLogin } from "../../../../service/userManagementAction.js"
import { PRIVILEGE_ENUM } from "../../../../../constants/PrivilegeConstants.js"
import { useTranslation } from "react-i18next"
import { RoutesConstants } from "../../../../../constants/RoutesConstants.js"
import { ILUSTRATION_CONSTANS } from "../../../../../constants/ImageConstants.js"

export const ListVideoDetail = ({
  history,
  match: {
    params: { id }
  }
}) => {
  const privileges = loadUserLogin().privilege
  const subHeader = useSubheader()
  const dispatch = useDispatch()
  const {t} = useTranslation()
  const [showPopupRemove, setShowPopupRemove] = useState(false)
  const { Video, General } = useSelector((state) => state)
  const [detail, setDetail] = useState({})
  useEffect(() => {
    return () => {
      dispatch(clearDataDetail())
    }
  }, [])

  useEffect(() => {
    subHeader.setBreadcrumbs([
      {
        pathname: RoutesConstants.VIDEO.LIST(),
        title: t("sub_header.setting")
      },
      {
        pathname: RoutesConstants.VIDEO.LIST(),
        title: t("sub_header.manage_video_tutorial")
      },
      {
        title: detail?.title
      }
    ])
    subHeader.setTitle(t("sub_header.video_tutorial"))
  }, [detail?.title])

  useEffect(() => {
    if (General.isFinishUpdateOrDelete) {
      swal({
        text: t("messages.response.tutorial_video_deleted"),
        icon: toAbsoluteUrl(ILUSTRATION_CONSTANS.FAIL)
      })
      history.push(RoutesConstants.VIDEO.LIST())
    }
  }, [General.isFinishUpdateOrDelete])

  useEffect(() => {
    if (id) {
      dispatch(getDetail(id))
    }
  }, [dispatch])

  useEffect(() => {
    if (Video?.data) {
      setDetail(Video?.data)
    }
  }, [Video?.data])

  useEffect(() => {
    subHeader.setButton(
      <RowModule>
        <>
          {privileges.indexOf(PRIVILEGE_ENUM.DELETE_VIDEO) > -1 && (
            <Button variant="danger" onClick={() => setShowPopupRemove(true)}>
              {t("button.delete")}
            </Button>
          )}
          {privileges.indexOf(PRIVILEGE_ENUM.UPDATE_VIDEO) > -1 && (
            <Button
              variant="outline-primary"
              className="ml-2 btn-primary"
              style={{ backgroundColor: "#fff", color: "green" }}
              onClick={() => history.push(RoutesConstants.VIDEO.EDIT(detail?.id))}>
              Edit
            </Button>
          )}
        </>
      </RowModule>
    )
  }, [detail])

  const getTabContents = () => {
    const labelList = ["DETAIL VIDEO"]

    const componentList = [<DetailVideo key={1} data={detail} />]

    return {
      labels: labelList,
      contents: componentList
    }
  }

  const videoRemove = async (id) => {
    return dispatch(removeVideo(id))
  }

  return (
    <>
      <PopupQuestion
        show={showPopupRemove}
        title={t("messages.title.delete_video")}
        bodyCustom={
          <span className={"text-center"}>
            {t("messages.confirmation.delete_tutorial_video_confirmation")}
          </span>
        }
        textOk={t("button.delete")}
        onCancel={() => {
          setShowPopupRemove(false)
        }}
        onOk={() => {
          videoRemove(id)
          setShowPopupRemove(false)
        }}
      />
      <TabsComponent labelTab={getTabContents().labels} componentTab={getTabContents().contents} />
    </>
  )
}