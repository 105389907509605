import React, { useCallback, useEffect, useRef, useState } from "react"
import { RowModule } from "../../../../../../../component/atoms/RowModule/RowModule.jsx"
import {
  Card,
  CardBody,
  CardHeader
} from "../../../../../../../_metronic/_partials/controls/index.js"
import TableView, {
  formatterRowComponent,
  initialFilterTable
} from "../../../../../../../component/atoms/Tabel/index.jsx"
import { sortCaret } from "../../../../../../../_metronic/_helpers/index.js"
import { useDispatch, useSelector } from "react-redux"
import { useSubheader } from "../../../../../../../_metronic/layout/index.js"
import {
  EmptyCard,
  FilterCard,
  SelectInput,
  TableSkeleton
} from "../../../../../../../component/index.jsx"
import {
  getCategoryItemListMachine,
  getMachineType,
  getOwnerList
} from "../../../../../../../redux/actions/MasterDataAction.jsx"
import { useTranslation } from "react-i18next"
import { RoutesConstants } from "../../../../../../../constants/RoutesConstants.js"

export const DetailMachine = () => {
  const subHeader = useSubheader()
  const { t } = useTranslation()
  const { General, MasterData } = useSelector((state) => state)
  const dispatch = useDispatch()
  const [search, setSearch] = useState({
    string_filter: ""
  })
  const [initialValues, setInitialValues] = useState({
    machine_owner: "",
    machine_type: ""
  })
  const [showFilter, setShowFilter] = useState(false)
  const [filterOn, setFilterOn] = useState(false)

  const formRef = useRef()
  const [statusValue] = useState([])
  const [owner, setOwner] = useState("")
  const [type, setType] = useState("")
  const isReadOnly = false
  const [stateOwner, setStateOwner] = useState("")
  const [stateType, setStateType] = useState("")

  const changePage = useCallback(
    (param) => {
      let newParam

      if (param.sortField) {
        newParam = {
          ...initialValues,
          ...search,
          ...param,
          sort: param.sortField + "," + param.sortOrder
        }
      } else {
        newParam = {
          ...initialValues,
          ...search,
          ...param
        }
      }

      dispatch(getCategoryItemListMachine({ pageReq: newParam }))
    },
    [dispatch, search, statusValue, initialValues]
  )

  useEffect(() => {
    dispatch(getCategoryItemListMachine({ pageReq: initialFilterTable }))
    dispatch(getOwnerList())
    dispatch(getMachineType())
  }, [])

  useEffect(() => {
    subHeader.setBreadcrumbs([
      {
        pathname: RoutesConstants.MASTER_DATA.CATEGORY_ITEM.PAGE(),
        title: t("sub_header.category_item")
      },
      {
        title: t("sub_header.machine")
      }
    ])
    subHeader.setTitle(t("sub_header.machine"))
    subHeader.setButton(null)
  }, [])

  useEffect(() => {
    if (MasterData.ownerList) {
      setStateOwner(MasterData.ownerList.map((value) => ({ label: value.name, value: value.id })))
    }
  }, [MasterData.ownerList])

  useEffect(() => {
    if (MasterData.machineType) {
      setStateType(
        MasterData.machineType.map((value) => ({ label: value.enum_value, value: value.enum_name }))
      )
    }
  }, [MasterData.machineType])

  const uiDate = (e) => {
    let date = 0
    let time = 1
    return (
      <>
        <div className="mb-1">{e?.created_date?.split(" ")[date] ?? "-"}</div>
        <div>{e?.created_date?.split(" ")[time] ?? "-"}</div>
      </>
    )
  }

  const uiOwner = (e) => {
    return (
      <>
        <div className="text-dark font-weight-bolder mb-1 font-size-lg">
          {e.owner_type_name ?? "-"}
        </div>
        {e.owner_type_name != "Maxxi Tani" && (
          <div className="text-dark mb-1 font-size-lg text-capitalize">{e.owner_name ?? "-"}</div>
        )}
      </>
    )
  }

  const uiNumber = (e) => {
    return <>{e.engine_number ?? "-"}</>
  }

  const columns = [
    {
      dataField: "name",
      text: t("table_header.machine_name").toUpperCase(),
      formatter: formatterRowComponent
    },
    {
      dataField: "ownerName",
      text: t("table_header.machine_owner").toUpperCase(),
      formatter: formatterRowComponent,
      formatExtraData: {
        externalStyle: uiOwner
      },
      sortCaret: sortCaret,
      sort: true,
      onSort: (field, order) => {
        let param = {
          ...initialFilterTable,
          sort: field + "," + order
        }
        dispatch(getCategoryItemListMachine({ pageReq: param }))
      }
    },
    {
      dataField: "machine_type",
      text: t("table_header.machine_type").toUpperCase(),
      formatter: formatterRowComponent
    },
    {
      dataField: "engine_number",
      text: t("table_header.engine_number").toUpperCase(),
      formatter: formatterRowComponent,
      formatExtraData: {
        externalStyle: uiNumber
      }
    },
    {
      dataField: "frame_number",
      text: t("table_header.frame_number").toUpperCase(),
      formatter: formatterRowComponent
    },
    {
      dataField: "createdDate",
      text: t("table_header.submitted_date"),
      formatter: formatterRowComponent,
      formatExtraData: {
        externalStyle: uiDate
      },
      sortCaret: sortCaret,
      sort: true,
      onSort: (field, order) => {
        let param = {
          ...initialFilterTable,
          sort: field + "," + order
        }
        dispatch(getCategoryItemListMachine({ pageReq: param }))
      }
    }
  ]

  const onSubmitForm = (values) => {
    let param = {
      ...initialFilterTable,
      string_filter: values.string_filter,
      machine_owner: owner,
      machine_type: type
    }
    setSearch({
      string_filter: values.string_filter
    })

    dispatch(getCategoryItemListMachine({ pageReq: param }))
  }

  const trigerReset = () => {
    setSearch({
      string_filter: ""
    })
    let param = {
      ...initialFilterTable,
      string_filter: "",
      machine_owner: owner,
      machine_type: type
    }
    dispatch(getCategoryItemListMachine({ pageReq: param }))
  }

  const onChangeOwner = (e) => {
    setOwner(e)
  }

  const onChangeType = (e) => {
    setType(e)
  }

  const filterHandleSubmit = (e) => {
    const { values, submited } = e

    if (submited === true) {
      let param = {
        ...initialFilterTable,
        string_filter: search.string_filter,
        machine_owner: values.machine_owner,
        machine_type: values.machine_type
      }

      if (param) {
        setInitialValues(param)
        dispatch(getCategoryItemListMachine({ pageReq: param }))
        setShowFilter(false)
        setFilterOn(true)
      }
    }
  }

  const filterOnReset = () => {
    setType("")
    setOwner("")
    setShowFilter(false)
    setFilterOn(false)
    setInitialValues({
      machine_owner: "",
      machine_type: ""
    })
    let param = {
      ...initialFilterTable,
      string_filter: search.string_filter,
      machine_owner: "",
      machine_type: ""
    }
    dispatch(getCategoryItemListMachine({ pageReq: param }))
  }

  const handleOpenCloseFilter = (value) => {
    setShowFilter(value)
  }

  const filterComponent = (
    <>
      <Card className="card-custom card-shadowless">
        <CardHeader title="Mitra" className="px-0" />
        <CardBody className="px-0">
          <RowModule>
            <SelectInput
              name="machine_owner"
              onChange={onChangeOwner}
              editable={!isReadOnly}
              label={t("label.mesin_owner")}
              placeholder={t("placeholder.select_machine_owner")}
              options={stateOwner}
              withoutFeedback={true}
              clearNow={true}
              value={owner}
            />
          </RowModule>
        </CardBody>
      </Card>
      <Card className="card-custom card-shadowless">
        <CardHeader title="Mitra" className="px-0" />
        <CardBody className="px-0">
          <RowModule>
            <SelectInput
              name="machine_type"
              onChange={onChangeType}
              editable={!isReadOnly}
              label={t("label.machine_type")}
              placeholder={t("placeholder.select_machine_type")}
              options={stateType}
              withoutFeedback={true}
              clearNow={true}
              value={type}
            />
          </RowModule>
        </CardBody>
      </Card>
    </>
  )

  return (
    <>
      <FilterCard
        placeholder={`${t("label.machine_name")}/${t("label.frame_number")}/${t("label.engine_number")}`}
        onSubmitForm={onSubmitForm}
        initialValues={search}
        name={"string_filter"}
        innerRef={formRef}
        trigerReset={trigerReset}
        filterInnerRef={formRef}
        filterHandleSubmit={filterHandleSubmit}
        filterFormInitialValues={initialValues}
        filterComponent={filterComponent}
        filterOnReset={filterOnReset}
        showFilter={showFilter}
        handleOpenCloseFilter={handleOpenCloseFilter}
        filterOn={filterOn}
      />
      <Card>
        <RowModule>
          <Card className="card-shadowless">
            <CardBody>
              {General.loading ? (
                <TableSkeleton column={6} row={10} />
              ) : MasterData?.pageData?.length === 0 ? (
                <EmptyCard
                  body={
                    filterOn || search?.string_filter?.length > 0
                      ? t("messages.response.data_not_found")
                      : t("messages.response.empty_state")
                  }
                />
              ) : (
                ""
              )}
              <TableView
                callbackAfterPageChange={changePage}
                columnProperties={columns}
                dataTable={MasterData.pageData}
                currentPage={MasterData.page.currentPage}
                currentElement={MasterData.page.currentElement}
                totalCount={MasterData.page.totalCount}
                loadingGetData={General.loading}
                rowClasses={() => {
                  return "cursor-default"
                }}
              />
            </CardBody>
          </Card>
        </RowModule>
      </Card>
    </>
  )
}