import React, { useCallback, useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { getAreaLeadList } from "../../../../redux/actions/AreaAction.jsx"
import { useSubheader } from "../../../../_metronic/layout/index.js"
import { Card } from "../../../../_metronic/_partials/controls/index.js"
import { RowModule } from "../../../../component/atoms/RowModule/RowModule.jsx"
import { AreaList } from "./FormViewLead/AreaList.jsx"
import { FilterCard } from "../../../../component/molecule/index.jsx"
import { LocationMolecule } from "../../../../component/index.jsx"
import { initialFilterTable } from "../../../../component/atoms/Tabel/index.jsx"
import { currentUrl } from "../../../helpers/UrlParamHelper.js"
import {
  resetUrlParam,
  setDataValueAction,
  SetFilterAction,
  setPageStringActions,
  setSearchAction
} from "../../../../redux/actions/FilterAction.js"
import { useTranslation } from "react-i18next"

export const ListAreaLead = ({ history }) => {
  const subHeader = useSubheader()
  const dispatch = useDispatch()
  const { General, Area, UrlParam } = useSelector((state) => state)
  const formRef = useRef()
  const { t } = useTranslation()

  const [search, setSearch] = useState({
    string_filter: ""
  })
  const [showFilter, setShowFilter] = useState(false)
  const [filterOn, setFilterOn] = useState(false)
  const [initialValue, setInitialValue] = useState({
    province_id: "",
    regency_id: "",
    district_id: "",
    sub_district_id: ""
  })
  const isReadOnly = false
  const [isFilter, setIsFilter] = useState(false)
  const [reset, setReset] = useState(false)
  const [btnFilterDisable, setBtnFilterDisable] = useState(true)
  const [isActiveSearch, setIsActiveSearch] = useState(false)
  const urlCurrent = currentUrl()
  const filterValue = UrlParam.data

  useEffect(() => {
    if (UrlParam.pageString !== urlCurrent) {
      dispatch(resetUrlParam())
    }
  }, [])

  useEffect(() => {
    if (UrlParam.search) {
      setIsActiveSearch(true)
    } else {
      setIsActiveSearch(false)
    }
  }, [UrlParam])

  useEffect(() => {
    if (UrlParam.search) {
      setSearch({
        string_filter: UrlParam.search
      })
    } else {
      setSearch({
        string_filter: ""
      })
    }
  }, [UrlParam.search, useCallback])

  useEffect(() => {
    const dataPage = {
      ...initialFilterTable
    }
    dispatch(setPageStringActions(urlCurrent))
    if (filterValue !== null && UrlParam.pageString === urlCurrent) {
      const data = {
        ...initialFilterTable,
        ...UrlParam.dataValue,
        string_filter: UrlParam.search,
        province_id: filterValue.province_id,
        regency_id: filterValue.regency_id,
        district_id: filterValue.district_id,
        sub_district_id: filterValue.sub_district_id
      }
      if (data) {
        setFilterOn(true)
        setInitialValue(data)
        dispatch(getAreaLeadList({ pageReq: data }))
        setShowFilter(false)
      }
    }
    if (UrlParam.pageString === urlCurrent) {
      if (UrlParam.dataValue && !UrlParam.search) {
        setSearch({
          string_filter: UrlParam.search
        })

        let param = {
          ...UrlParam.dataValue,
          string_filter: UrlParam.search
        }
        dispatch(getAreaLeadList({ pageReq: param }))
      }
    } else {
      dispatch(getAreaLeadList({ pageReq: dataPage }))
    }
  }, [UrlParam, dispatch])

  useEffect(() => {
    if (formRef.current.values.province_id) setBtnFilterDisable(false)
    else setBtnFilterDisable(true)
  }, [formRef.current])

  useEffect(() => {
    subHeader.setButton(false)
    subHeader.setTitle(t("sub_header.area_lead"))
  }, [])

  const changePage = useCallback(
    (param) => {
      setSearch({
        string_filter: UrlParam.search
      })
      let newParam = {
        ...initialValue,
        ...search,
        ...param
      }
      if (param.sortField) {
        newParam = {
          ...param,
          ...newParam,
          ...search,
          sort: param.sortField + "," + param.sortOrder
        }
      } else if (UrlParam.search) {
        newParam = {
          ...initialValue,
          ...search,
          ...param
        }
      } else if (!UrlParam.search) {
        newParam = {
          ...initialValue,
          ...search,
          ...param
        }
      }
      dispatch(getAreaLeadList({ pageReq: newParam }))
      dispatch(setDataValueAction(newParam))
    },
    [dispatch, initialValue, search]
  )

  useEffect(() => {
    subHeader.setButton(false)
  }, [])

  const filterOnSubmitForm = (e) => {
    setReset(false)
    const { values, submited } = e
    setIsFilter(true)
    if (submited === true) {
      let param = {
        ...initialFilterTable,
        province_id: values.province_id,
        regency_id: values.regency_id,
        district_id: values.district_id,
        sub_district_id: values.sub_district_id,
        string_filter: search.string_filter?.toUpperCase()
      }

      if (param) {
        setInitialValue(param)
        dispatch(SetFilterAction(param))
        dispatch(getAreaLeadList({ pageReq: param }))
        setShowFilter(false)
        setFilterOn(true)
      }
    }
  }

  const onReset = () => {
    dispatch(SetFilterAction())
    setReset(true)
    setShowFilter(false)
    setIsFilter(false)
    setFilterOn(false)
    setInitialValue({
      province_id: "",
      regency_id: "",
      district_id: "",
      sub_district_id: ""
    })
    let param = {
      ...initialFilterTable,
      province_id: "",
      regency_id: "",
      district_id: "",
      sub_district_id: "",
      string_filter: search.string_filter?.toUpperCase()
    }
    dispatch(getAreaLeadList({ pageReq: param }))
  }

  const seacrhFunc = (values) => {
    let param = {
      ...initialFilterTable,
      ...initialValue,
      string_filter: values.string_filter?.toUpperCase()
    }
    setSearch({
      string_filter: values.string_filter
    })
    dispatch(getAreaLeadList({ pageReq: param }))
    dispatch(setSearchAction(values?.string_filter))
    setIsFilter(true)
  }

  const handleReset = () => {
    dispatch(setSearchAction())
    setIsFilter(false)
    setSearch({
      string_filter: ""
    })
    let param = {
      ...initialFilterTable,
      ...initialValue,
      string_filter: ""
    }
    dispatch(getAreaLeadList({ pageReq: param }))
  }

  const handleOpenCloseFilter = (value) => {
    setShowFilter(value)
  }

  const filterComponent = (
    <>
      <LocationMolecule
        resetLocation={reset}
        name="filter"
        withoutFeedback={true}
        isReadOnly={isReadOnly}
        addressTitle={t("label.location")}
        removeHorizontalPadding={true}
      />
    </>
  )

  return (
    <>
      <FilterCard
        // seacrh
        isActiveSearch={isActiveSearch}
        placeholder={t("placeholder.insert_area_name")}
        onSubmitForm={seacrhFunc}
        initialValues={search}
        name={"string_filter"}
        innerRef={formRef}
        trigerReset={handleReset}
        // filter
        filterInnerRef={formRef}
        filterHandleSubmit={filterOnSubmitForm}
        filterFormInitialValues={initialValue}
        filterComponent={filterComponent}
        filterOnReset={onReset}
        showFilter={showFilter}
        handleOpenCloseFilter={handleOpenCloseFilter}
        filterOn={filterOn}
        btnDisable={btnFilterDisable}></FilterCard>

      <Card>
        <RowModule>
          <AreaList
            data={Area}
            general={General}
            changePage={changePage}
            history={history}
            isActiveSearch={isActiveSearch}
            isFilter={isFilter}
          />
        </RowModule>
      </Card>
    </>
  )
}
