import React from "react"
import { Card } from "react-bootstrap"
import { CardBody } from "../../../../../_metronic/_partials/controls/index.js"
import { RowModule } from "../../../../../component/atoms/RowModule/RowModule.jsx"
import { DetailText } from "../../../../../component/atoms/DetailText/index.jsx"
import { loadUserLogin } from "../../../../service/userManagementAction.js"
import { Skeleton } from "@material-ui/lab"
import { useSelector } from "react-redux"
import { PRIVILEGE_ENUM } from "../../../../../constants/PrivilegeConstants.js"
import { MITRA_DOCUMENT, MITRA_TYPE } from "../../../../../constants/StringConstant.js"
import { useTranslation } from "react-i18next"

export function CardDetailUsaha({
  mitraType,
  npwp,
  npwpImage,
  ktpImage,
  swafoto,
  suratTugas,
  kkImage,
  documentPerusahaan,
  structurOrganisasi,
  setStatusPopup,
  ktp,
  nama
}) {
  const privileges = loadUserLogin().privilege
  const { General } = useSelector((state) => state)
  const {t} = useTranslation()
  return (
    <>
      <RowModule>
        <Card className={"card-custom"}>
          {General.loading ? (
            <CardBody>
              <div className={"row"}>
                {Array.from({ length: 3 }).map((item, index) => {
                  return (
                    <div className={"col-md-3"} key={index}>
                      <Skeleton animation="wave" variant={"text"} width={100} />
                      <Skeleton animation="wave" variant={"text"} width={100} />
                    </div>
                  )
                })}
              </div>
            </CardBody>
          ) : (
            <CardBody>
              <div className={"row"}>
                {mitraType === MITRA_TYPE.INDIVIDUAL && (
                  <div className={"col-md-3"}>
                    <div className={"color-gray space-1"}>{t("label.name_as_per_id")}</div>
                    <div className={"text-dark font-weight-bolder mt-1 font-size-lg"}>
                      {nama ? nama : "-"}
                    </div>
                  </div>
                )}
                {privileges.indexOf(PRIVILEGE_ENUM.MITRA_KTP_NUMBER) > -1 &&
                  mitraType === MITRA_TYPE.INDIVIDUAL && (
                    <div className={"col-md-3"}>
                      <div className={"color-gray space-1"}>{t("label.ktp_number")}</div>
                      <div className={"text-dark font-weight-bolder mt-1 font-size-lg"}>
                        {ktp ? ktp : "-"}
                      </div>
                    </div>
                  )}
                <div className={"col-md-3"}>
                  <div className={"color-gray space-1"}>{t("label.npwp_number")}</div>
                  <div className={"text-dark font-weight-bolder mt-1 font-size-lg"}>{npwp}</div>
                </div>
              </div>
            </CardBody>
          )}
        </Card>
      </RowModule>

      {mitraType === MITRA_TYPE.INDIVIDUAL && (
        <>
          <RowModule withoutSeparator={true} customColumnCss={"col-md-6"}>
            {privileges.indexOf(PRIVILEGE_ENUM.MITRA_KTP_PICTURE) > -1 && (
              <div onClick={() => setStatusPopup(MITRA_DOCUMENT.KTP)}>
                <DetailText
                  title={t("label.ktp")}
                  menu={MITRA_DOCUMENT.KTP}
                  value={ktpImage}
                  type="image"
                  withoutSeparator={true}
                />
              </div>
            )}
            {privileges.indexOf(PRIVILEGE_ENUM.MITRA_KTP_SELFIE_PICTURE) > -1 && (
              <div onClick={() => setStatusPopup(MITRA_DOCUMENT.SWAFOTO)}>
                <DetailText
                  title={t("label.swafoto")}
                  menu={MITRA_DOCUMENT.SWAFOTO}
                  value={swafoto}
                  type="image"
                  withoutSeparator={true}
                />
              </div>
            )}
            <div onClick={() => setStatusPopup(MITRA_DOCUMENT.BUSINESS_DOCUMENT)}>
              <DetailText
                title="KK"
                menu={MITRA_DOCUMENT.BUSINESS_DOCUMENT}
                value={kkImage}
                type="image"
                withoutSeparator={true}
              />
            </div>
            <div onClick={() => setStatusPopup(MITRA_DOCUMENT.OTHER_IMAGE)}>
              <DetailText
                title="NPWP"
                menu={MITRA_DOCUMENT.OTHER_IMAGE}
                value={npwpImage}
                type="image"
                withoutSeparator={true}
              />
            </div>
          </RowModule>
        </>
      )}
      {mitraType === MITRA_TYPE.GOVERNMENT_AGENCIES && (
        <>
          <RowModule withoutSeparator={true} customColumnCss={"col-md-6"}>
            <div onClick={() => setStatusPopup(MITRA_DOCUMENT.SWAFOTO)}>
              <DetailText
                title={t("label.work_assignment_letter")}
                menu={MITRA_DOCUMENT.SWAFOTO}
                value={suratTugas}
                type="image"
                withoutSeparator={true}
              />
            </div>
            <div onClick={() => setStatusPopup(MITRA_DOCUMENT.OTHER_IMAGE)}>
              <DetailText
                title="NPWP"
                menu={MITRA_DOCUMENT.OTHER_IMAGE}
                value={npwpImage}
                type="image"
                withoutSeparator={true}
              />
            </div>
            <div onClick={() => setStatusPopup(MITRA_DOCUMENT.BUSINESS_DOCUMENT)}>
              <DetailText
                title={t("label.organizational_structure").toUpperCase()}
                menu={MITRA_DOCUMENT.BUSINESS_DOCUMENT}
                value={structurOrganisasi}
                type="image"
                withoutSeparator={true}
              />
            </div>
            {privileges.indexOf(PRIVILEGE_ENUM.MITRA_KTP_PICTURE) > -1 && (
              <div onClick={() => setStatusPopup(MITRA_DOCUMENT.KTP)}>
                <DetailText
                  title={t("label.ktp_of_the_person_in_charge")}
                  menu={MITRA_DOCUMENT.KTP}
                  value={ktpImage}
                  type="image"
                  withoutSeparator={true}
                />
              </div>
            )}
          </RowModule>
        </>
      )}
      {mitraType === MITRA_TYPE.PRIVATE_COMPANIES && (
        <>
          <RowModule withoutSeparator={true} customColumnCss={"col-md-6"}>
            <div onClick={() => setStatusPopup(MITRA_DOCUMENT.BUSINESS_DOCUMENT)}>
              <DetailText
                title={t("label.deed_of_company_establishment").toUpperCase()}
                menu={MITRA_DOCUMENT.BUSINESS_DOCUMENT}
                value={documentPerusahaan}
                type="image"
                withoutSeparator={true}
              />
            </div>
            <div onClick={() => setStatusPopup(MITRA_DOCUMENT.OTHER_IMAGE)}>
              <DetailText
                title="NPWP"
                menu={MITRA_DOCUMENT.OTHER_IMAGE}
                value={npwpImage}
                type="image"
                withoutSeparator={true}
              />
            </div>
            {privileges.indexOf(PRIVILEGE_ENUM.MITRA_KTP_PICTURE) > -1 && (
              <div onClick={() => setStatusPopup(MITRA_DOCUMENT.KTP)}>
                <DetailText
                  title={t("label.ktp_of_the_person_in_charge")}
                  menu={MITRA_DOCUMENT.KTP}
                  value={ktpImage}
                  type="image"
                  withoutSeparator={true}
                />
              </div>
            )}
          </RowModule>
        </>
      )}
      {mitraType === MITRA_TYPE.EDUCATIONAL_INSTITUTIONS && (
        <>
          <RowModule withoutSeparator={true} customColumnCss={"col-md-6"}>
            <div onClick={() => setStatusPopup(MITRA_DOCUMENT.BUSINESS_DOCUMENT)}>
              <DetailText
                title={t("label.organizational_structure").toUpperCase()}
                menu={MITRA_DOCUMENT.BUSINESS_DOCUMENT}
                value={structurOrganisasi}
                type="image"
                withoutSeparator={true}
              />
            </div>
            <div onClick={() => setStatusPopup(MITRA_DOCUMENT.OTHER_IMAGE)}>
              <DetailText
                title="NPWP"
                menu={MITRA_DOCUMENT.OTHER_IMAGE}
                value={npwpImage}
                type="image"
                withoutSeparator={true}
              />
            </div>
            {privileges.indexOf(PRIVILEGE_ENUM.MITRA_KTP_PICTURE) > -1 && (
              <div onClick={() => setStatusPopup(MITRA_DOCUMENT.KTP)}>
                <DetailText
                  title={t("label.ktp_of_the_person_in_charge")}
                  menu={MITRA_DOCUMENT.KTP}
                  value={ktpImage}
                  type="image"
                  withoutSeparator={true}
                />
              </div>
            )}
          </RowModule>
        </>
      )}
    </>
  )
}