import { Card, CardBody, CardHeader } from "../../../_metronic/_partials/controls"
import { RowModule } from "../../atoms/RowModule/RowModule.jsx"
import { useDispatch, useSelector } from "react-redux"
import React, { useEffect, useState } from "react"
import {
  fetchDistrictByRegencyId,
  fetchProvince,
  fetchRegencyByProvinceId,
  fetchSubDistrictByDistrictId,
  resetZones
} from "../../../redux/actions/MasterDataAction"
import { SelectInput } from "../../atoms/SelectInput"
import { useFormikContext } from "formik"
import { useTranslation } from "react-i18next"

export function LocationMolecule({
  name,
  addressTitle,
  isReadOnly,
  resetLocation,
  removeHorizontalPadding,
  withoutFeedback,
  regionalId
}) {
  const { values: formValues } = useFormikContext()

  const dispatch = useDispatch()
  const { MasterData } = useSelector((state) => state)
  const [provinceList, setProvinceList] = useState([])
  const [regencyList, setRegencyList] = useState([])
  const [districtList, setDistrictList] = useState([])
  const [subDistrictList, setSubDistrictList] = useState([])
  const [masterSubDistrictList, setMasterSubDistrictList] = useState([])
  const [province, setProvince] = useState("")
  const [regency, setRegency] = useState("")
  const [district, setDistrict] = useState("")
  const [subDistrict, setSubDistrict] = useState("")

  const [isChangeProvince, setIsChangeProvince] = useState(false)
  const [isRegency, setIsRegency] = useState(false)
  const [isDistrict, setIsDistrict] = useState(false)
  const {t} = useTranslation()
  useEffect(() => {
    if (resetLocation) {
      dispatch(resetZones())
      setProvince("")
      setRegency("")
      setDistrict("")
      setSubDistrict("")
    }
  }, [resetLocation])
  useEffect(() => {
    if (formValues.hasOwnProperty("province_id")) {
      setProvince(formValues["province_id"])
      if (province) dispatch(fetchRegencyByProvinceId(province))
    }
    if (formValues.hasOwnProperty("regency_id") && formValues.regency_id !== undefined) {
      setRegency(formValues["regency_id"])
      if (regency) dispatch(fetchDistrictByRegencyId(regency))
    }
    if (formValues.hasOwnProperty("district_id") && formValues.district_id !== undefined) {
      setDistrict(formValues["district_id"])
      if (district) dispatch(fetchSubDistrictByDistrictId(district))
    }
    if (formValues.hasOwnProperty("sub_district_id") && formValues.sub_district_id !== undefined) {
      setSubDistrict(formValues["sub_district_id"])
    }
  }, [formValues, name, dispatch, province, regency, district])
  useEffect(() => {
    if (regionalId) {
      dispatch(fetchProvince(regionalId))
    } else {
      dispatch(fetchProvince())
    }
  }, [name, regionalId])

  useEffect(() => {
    setProvinceList(
      MasterData.provinceList.map((value) => ({
        label: value.name,
        value: value.id
      }))
    )
    if (isChangeProvince) {
      setProvince("")
      setDistrict("")
      setRegency("")
      setSubDistrict("")
      setRegencyList([])
      setDistrictList([])
      setSubDistrictList([])
      setIsChangeProvince(false)
    }
  }, [MasterData.provinceList])

  useEffect(() => {
    setRegencyList(
      MasterData.regencyList.map((value) => ({
        label: value.name,
        value: value.id
      }))
    )
    if (isChangeProvince) {
      setRegency("")
      setDistrictList([])
      setDistrict("")
      setSubDistrictList([])
      setSubDistrict("")
      setIsChangeProvince(false)
    }
  }, [MasterData.regencyList])

  useEffect(() => {
    setDistrictList(
      MasterData.districtList.map((value) => ({
        label: value.name,
        value: value.id
      }))
    )
    if (isRegency) {
      setDistrict("")
      setSubDistrictList([])
      setSubDistrict("")
      setIsRegency(false)
    }
  }, [MasterData.districtList])

  useEffect(() => {
    setMasterSubDistrictList(MasterData.subDistrictList)
    setSubDistrictList(
      MasterData.subDistrictList.map((value) => ({
        label: value.name,
        value: value.id
      }))
    )
    if (isDistrict) {
      setSubDistrict("")
      setIsDistrict(false)
    }
  }, [MasterData.subDistrictList])

  function onChangeProvince(e) {
    if (e) {
      dispatch(fetchRegencyByProvinceId(e))
      setProvince(e)
    }
    setIsChangeProvince(true)
    setRegency("")
    setDistrict("")
    setSubDistrict("")
  }

  function onChangeRegency(e) {
    if (e) {
      dispatch(fetchDistrictByRegencyId(e))
      setRegency(e)
    }
    setIsRegency(true)
    setDistrict("")
    setSubDistrict("")
  }

  function onChangeDistrict(e) {
    if (e) {
      dispatch(fetchSubDistrictByDistrictId(e))
      setDistrict(e)
    }
    setIsDistrict(true)
    setSubDistrict("")
  }

  function onChangeSubDistrict(e) {
    if (e) {
      const selectSubdistrict = masterSubDistrictList.find((el) => el.id === e)
      if (selectSubdistrict) {
        setSubDistrict(e)
      }
    }
  }

  const isRegencyEnabled = () => {
    if (!isReadOnly) {
      return regencyList.length !== 0
    }
    return !isReadOnly
  }
  const isDistrictEnabled = () => {
    if (!isReadOnly) {
      return districtList.length !== 0
    }
    return !isReadOnly
  }
  const isSubDistrictEnabled = () => {
    if (!isReadOnly) {
      return subDistrictList.length !== 0
    }
    return !isReadOnly
  }

  return (
    <>
      <Card className="card-custom card-shadowless">
        <CardHeader title={addressTitle} className={removeHorizontalPadding ? "px-0" : ""} />
        <CardBody className={removeHorizontalPadding ? "px-0" : ""}>
          <RowModule>
            <SelectInput
              name={"province_id"}
              onChange={onChangeProvince}
              label={t("label.province")}
              placeholder={t("placeholder.choose_province")}
              value={province}
              editable={!isReadOnly}
              options={provinceList}
              withoutFeedback={withoutFeedback}
            />
          </RowModule>
          <RowModule>
            <SelectInput
              name={"regency_id"}
              onChange={onChangeRegency}
              label={t("label.city")}
              editable={isRegencyEnabled()}
              placeholder={t("placeholder.choose_city")}
              value={regency}
              options={regencyList}
              withoutFeedback={withoutFeedback}
            />
          </RowModule>
          <RowModule>
            <SelectInput
              name={"district_id"}
              onChange={onChangeDistrict}
              editable={isDistrictEnabled()}
              label={t("label.district")}
              placeholder={t("placeholder.choose_district")}
              value={district}
              options={districtList}
              withoutFeedback={withoutFeedback}
            />
          </RowModule>
          <RowModule>
            <SelectInput
              name={"sub_district_id"}
              onChange={onChangeSubDistrict}
              editable={isSubDistrictEnabled()}
              label={t("label.subdistrict")}
              placeholder={t("placeholder.choose_subdistrict")}
              value={subDistrict}
              options={subDistrictList}
              withoutFeedback={withoutFeedback}
            />
          </RowModule>
        </CardBody>
      </Card>
    </>
  )
}
