import { useDispatch, useSelector } from "react-redux"
import React, { useCallback, useEffect, useRef, useState } from "react"
import { clearDataFO, getPageData } from "../../../../redux/actions/FieldOfficerAction.jsx"
import TableView, { formatterRowComponent } from "../../../../component/atoms/Tabel/index.jsx"
import { sortCaret } from "../../../../_metronic/_helpers/index.js"
import { Card, CardBody } from "../../../../_metronic/_partials/controls/index.js"
import { useSubheader } from "../../../../_metronic/layout/index.js"
import { DetailSvgIcon, EditSvgIcon } from "../../../../component/atoms/Icons/SVG/index.jsx"
import { Badge, OverlayTrigger, Tooltip } from "react-bootstrap"
import { EmptyCard, Search, TableSkeleton } from "../../../../component/index.jsx"
import { Popup } from "../../../../component/atoms/Popup/index.jsx"
import { loadUserLogin } from "../../../service/userManagementAction.js"
import { linkWithCtrl } from "../../../helpers/Utils.js"
import { RoutesConstants } from "../../../../constants/RoutesConstants.js"
import { useLocation } from "react-router"
import { getUrlParsingPageFilter } from "../../../../config/EndpointCollection.js"
import { useUrlParam } from "../../../hooks/useUrlParams.jsx"
import { FIELD_OFFICER_ROLE_ENUM, ROUTING_STATUS } from "../../../../constants/StringConstant.js"
import { useTranslation } from "react-i18next"
import { PRIVILEGE_ENUM } from "../../../../constants/PrivilegeConstants.js"

export const ListFieldOfficer = ({ history }) => {
  const privileges = loadUserLogin().privilege
  const subHeader = useSubheader()
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { General, FieldOfficer } = useSelector((state) => state)
  const formRef = useRef()
  const location = useLocation()

  const [search, setSearch] = useState({
    string_filter: ""
  })
  const [isFilter, setIsFilter] = useState(false)
  const [showPopupError, setShowPopupError] = useState(false)
  const [isActiveSearch, setIsActiveSearch] = useState(false)
  const [queryStringObj, setQueryStringObj] = useState(undefined)

  const urlParam = useUrlParam()

  useEffect(() => {
    dispatch(clearDataFO())
  }, [])

  useEffect(() => {
    subHeader.setButton(false)
    subHeader.setTitle(t("sub_header.fo"))
  }, [dispatch])

  const changePage = useCallback(
    (param) => {
      let newParam
      if (param.sortField != null) {
        newParam = getUrlParsingPageFilter({
          ...search,
          ...param,
          sort: param.sortField + "," + param.sortOrder
        })
      } else {
        newParam = getUrlParsingPageFilter({
          ...search,
          ...param
        })
      }
      history.push({ pathname: location.pathname, search: newParam })
    },
    [search]
  )

  const handleResetSearch = () => {
    urlParam.resetStringFilter()
    setSearch({ string_filter: "" })
    setIsFilter(false)
  }

  useEffect(() => {
    const result = location?.search
    fetchData(result)
  }, [location.search])
  useEffect(() => {
    if (queryStringObj?.string_filter) {
      setSearch({ string_filter: queryStringObj?.string_filter })
      setIsActiveSearch(true)
    }
  }, [queryStringObj])
  useEffect(() => {
    const result = location?.search
    setQueryStringObj(urlParam.getUrlParamsObj)
    fetchData(result)
  }, [location.search, urlParam.getUrlParamsObj])
  useEffect(() => {
    if (isFilter) {
      if (FieldOfficer.pageData.length === 0) {
        setShowPopupError(true)
      }
    }
  }, [FieldOfficer.pageData])

  const uiName = (e) => {
    return (
      <>
        <span className="text-dark font-weight-bolder mb-1 font-size-lg">{e?.name ?? "-"}</span>
        <span className="text-muted font-weight-bold d-block">{e?.phone ?? "-"}</span>
      </>
    )
  }

  const uiStatus = (e) => {
    return (
      <Badge variant={e?.status_enum === ROUTING_STATUS.STATUS_ENUM.ACTIVE ? "primary" : "secondary"}>
        {e.status ? e.status : "-"}
      </Badge>
    )
  }

  const uiCode = (e) => {
    return (
      <>
        <span className="text-dark font-weight-bolder mb-1 font-size-lg">{e?.code ?? "-"}</span>
      </>
    )
  }

  const uiCreateDate = (e) => {
    return (
      <>
        {e?.create_date}
        <br />
        {e?.create_time}
      </>
    )
  }

  const uiAction = (e) => {
    return (
      <div>
        {privileges.indexOf(PRIVILEGE_ENUM.DETAIL_FIELD_OFFICER) > -1 && (
          <OverlayTrigger overlay={<Tooltip id="products-edit-tooltip">Detail</Tooltip>}>
            <div
              className="btn btn-icon btn-detail btn-hover-secondary btn-sm mx-3"
              onClick={() =>
                history.push(
                  RoutesConstants.FIELD_OFFICER.DETAIL_FO(
                    FIELD_OFFICER_ROLE_ENUM.FIELD_OFFICER,
                    e.id
                  )
                )
              }>
              <DetailSvgIcon />
            </div>
          </OverlayTrigger>
        )}
        {privileges.indexOf(PRIVILEGE_ENUM.EDIT_FIELD_OFFICER) > -1 && (
          <OverlayTrigger overlay={<Tooltip id="products-edit-tooltip">Edit</Tooltip>}>
            <div
              className="btn btn-icon btn-detail btn-hover-secondary btn-sm mx-3"
              onClick={() => history.push(RoutesConstants.FIELD_OFFICER.EDIT_FO(e.id))}>
              <EditSvgIcon />
            </div>
          </OverlayTrigger>
        )}
      </div>
    )
  }

  const columns = [
    {
      dataField: "code",
      text: "ID FO",
      sort: false,
      formatter: formatterRowComponent,
      formatExtraData: {
        externalStyle: uiCode
      },
      headerStyle: () => {
        return { width: "10%" }
      },
      sortCaret: sortCaret
    },
    {
      dataField: "name",
      text: t("table_header.name").toUpperCase(),
      sort: true,
      formatter: formatterRowComponent,
      formatExtraData: {
        externalStyle: uiName
      },
      sortCaret: sortCaret
    },
    {
      dataField: "full_address",
      text: t("table_header.address").toUpperCase(),
      formatter: formatterRowComponent,
      formatExtraData: {
        externalStyle: (e) => <span>{e?.full_address ?? "-"}</span>
      },
      headerStyle: () => {
        return { width: "35%" }
      }
    },
    {
      dataField: "status",
      text: "Status",
      sort: false,
      formatter: formatterRowComponent,
      formatExtraData: {
        externalStyle: uiStatus
      }
    },
    {
      dataField: "mitra_total",
      text: t("table_header.mitra_total").toUpperCase(),
      sort: false,
      formatter: formatterRowComponent,
      formatExtraData: {
        externalStyle: (e) => <span>{e?.mitra_total + " mitra" ?? 0}</span>
      }
    },
    {
      dataField: "createdDate",
      text: t("table_header.created_date").toUpperCase(),
      sort: true,
      formatter: formatterRowComponent,
      formatExtraData: {
        externalStyle: uiCreateDate
      },
      sortCaret: sortCaret,
      headerStyle: () => {
        return { width: "10%" }
      }
    },
    {
      dataField: "action",
      text: "Action",
      formatter: formatterRowComponent,
      formatExtraData: {
        externalStyle: uiAction
      },
      headerStyle: () => {
        return { width: "10%" }
      }
    }
  ]

  useEffect(() => {
    const result = location?.search
    fetchData(result)
  }, [location.search])

  function fetchData(param) {
    return dispatch(getPageData(param))
  }

  function onSearch(value) {
    setSearch({ string_filter: value.string_filter })
    setIsActiveSearch(true)
    let param = {
      string_filter: value.string_filter
    }
    const url = urlParam.stringifyObj(param)
    history.push({ pathname: location.pathname, search: url })
  }

  return (
    <>
      <Popup
        type={"empty"}
        body={<span>{t("placeholder.empty_data")}</span>}
        centered={true}
        persistent={true}
        show={showPopupError}
        onClick={() => {
          setShowPopupError(false)
        }}
      />
      <div className="filter-container">
        <div className="d-flex flex-wrap justify-content-between align-items-center mb-10">
          <Search
            // seacrh
            isActiveSearch={isActiveSearch}
            placeholder={t("placeholder.enter_fo_name")}
            onSubmitForm={onSearch}
            initialValues={search}
            name={"string_filter"}
            innerRef={formRef}
            trigerReset={handleResetSearch}
          />
        </div>
      </div>
      <Card>
        <CardBody>
          {General.loading ? (
            <TableSkeleton column={6} row={10} />
          ) : FieldOfficer?.pageData?.length == 0 ? (
            <EmptyCard
              body={
                isActiveSearch
                  ? t("messages.response.data_not_found")
                  : t("messages.response.empty_state")
              }
            />
          ) : (
            ""
          )}
          <TableView
            callbackAfterPageChange={changePage}
            columnProperties={columns}
            dataTable={FieldOfficer?.pageData ?? []}
            currentPage={FieldOfficer?.page?.currentPage ?? 1}
            currentElement={FieldOfficer?.page?.currentElement ?? 10}
            totalCount={FieldOfficer?.page?.totalCount ?? 0}
            loadingGetData={General.loading}
            rowClasses={() => {
              return "cursor-pointer"
            }}
            rowEvents={{
              onClick: (e, row) => {
                if (privileges.indexOf(PRIVILEGE_ENUM.DETAIL_FIELD_OFFICER) > -1) {
                  if (e.target.cellIndex < 6) {
                    const url = RoutesConstants.FIELD_OFFICER.DETAIL_FO(
                      FIELD_OFFICER_ROLE_ENUM.FIELD_OFFICER,
                      row.id
                    )
                    if (e.ctrlKey) {
                      linkWithCtrl(url)
                    } else {
                      history.push(url)
                    }
                  }
                }
              }
            }}
          />
        </CardBody>
      </Card>
    </>
  )
}