import { useFormikContext } from "formik"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { Card, CardBody } from "../../../../../../../_metronic/_partials/controls/index.js"
import { SelectInput, TextAreaInput, TextInput } from "../../../../../../../component/index.jsx"
import {
  getListRegionalSelected,
  getPlantType
} from "../../../../../../../redux/actions/MasterDataAction.jsx"
import { RowModule } from "../../../../../../../component/atoms/RowModule/RowModule.jsx"
import { handleSpace } from "../../../../../../helpers/TextHelper.js"

export const Create = ({ isReadOnly, setIsValid, detail }) => {
  const dispatch = useDispatch()
  const { values: formValues } = useFormikContext()
  const { values } = useFormikContext()
  const { t } = useTranslation()

  const [type, setType] = useState(formValues["type"])
  const [selectedRegional, setSelectedRegional] = useState()
  const [statePlantType, setStatePlantType] = useState([])
  const [listRegional, setListRegional] = useState([])
  const { MasterData } = useSelector((state) => state)

  useEffect(() => {
    if (
      values?.package_name &&
      values?.description &&
      values?.plant_type_id &&
      values?.regional_id
    ) {
      setIsValid(false)
    } else {
      setIsValid(true)
    }
  }, [values])

  useEffect(() => {
    dispatch(getPlantType())
    dispatch(getListRegionalSelected())
  }, [])

  useEffect(() => {
    if (MasterData?.listRegionalSelected) {
      setListRegional(
        MasterData.listRegionalSelected.map((item) => {
          return {
            label: item.name + " (" + item.code + ")",
            value: item.id
          }
        })
      )
    }
  }, [MasterData])

  useEffect(() => {
    if (detail) {
      setType(detail?.plant_type_id)
      setSelectedRegional(detail?.regional_id || "")
    }
  }, [detail])

  useEffect(() => {
    if (MasterData.plantType) {
      setStatePlantType(
        MasterData.plantType.map((value) => ({ label: value.name, value: value.id }))
      )
    }
  }, [MasterData.plantType])

  function onChangeRegional(e) {
    setSelectedRegional(e)
  }

  function onChangeType(e) {
    setType(e)
  }

  return (
    <>
      <Card className="card-custom card-shadowless">
        <CardBody>
          <h4 className="card-label mb-8">DETAIL PAKET</h4>
          <RowModule customColumnCss="mb-5 col-lg-12 col-sm-12 col-md-12">
            <SelectInput
              required={true}
              name="regional_id"
              onChange={onChangeRegional}
              editable={!isReadOnly}
              label={t("label.regional")}
              placeholder={t("placeholder.select_regional")}
              options={listRegional}
              withoutFeedback={false}
              clearNow={true}
              value={selectedRegional}
            />
          </RowModule>

          <RowModule customColumnCss="mb-5 col-lg-12 col-sm-12 col-md-12">
            <TextInput
              onKeyDown={handleSpace}
              editable={!isReadOnly}
              required={true}
              label={t("label.package_name")}
              name="package_name"
              placeholder={t("placeholder.input_package_name")}
              type={"text"}
            />
          </RowModule>
          <RowModule customColumnCss="mb-5 col-lg-12 col-sm-12 col-md-12">
            <SelectInput
              name="plant_type_id"
              onChange={onChangeType}
              editable={!isReadOnly}
              required={true}
              label={t("label.plant_type")}
              placeholder={t("placeholder.choose_one")}
              options={statePlantType}
              withoutFeedback={false}
              clearNow={true}
              value={type}
            />
          </RowModule>

          <RowModule customColumnCss="mb-5 col-lg-12 col-sm-12 col-md-12">
            <TextAreaInput
              required={true}
              editable={isReadOnly}
              label={t("label.description")}
              name="description"
              placeholder={t("placeholder.input_package_desc")}
              type={"text"}
            />
          </RowModule>
        </CardBody>
      </Card>
    </>
  )
}